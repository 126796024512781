import useQueryParams from './UseQueryParams';

enum UtmParameter {
    affiliate_id,
    blaid,
    sscid,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    origin,
}

export type UtmParameters = Partial<Record<keyof typeof UtmParameter, string>>;

/**
 * @returns {UtmParameters} An object that consists of all UTM parameters
 *                          that exist in the current URL's query string.
 */
const useUtmParams = (): UtmParameters => {
    const localQueryParams = useQueryParams(undefined, true);
    const returnUrlQueryParams = useQueryParams(localQueryParams.returnurl as string, true);
    const res: UtmParameters = {};

    Object.keys({ ...returnUrlQueryParams, ...localQueryParams })
        .filter(x => Object.values(UtmParameter).includes(x)) //filter out non UTM
        .forEach(x => {
            let value = localQueryParams[x] || returnUrlQueryParams[x];
            if (value) value = decodeURIComponent(value?.toString());
            else return;

            let utmKey = x as unknown as keyof typeof UtmParameter;
            res[utmKey] = encodeURIComponent(value);
        });

    return res as UtmParameters;
}

export default useUtmParams;
