import { AlertType } from '../../../components/AlertToast/types';

export type RequestPayload = Record<string, unknown>;

export enum ServerError {
    CodeIssueFailure = 'CodeIssueFailure',
    CodeVerificationRejected = 'CodeVerificationRejected',
    InvalidPhoneNumber = 'InvalidPhoneNumber',
    InvalidToken = 'InvalidToken',
    IncorrectCredentials = 'IncorrectCredentials',
    IncorrectEmail = 'IncorrectEmail',
    LoginNotAllowed = 'LoginNotAllowed',
    CredentialLoginNotAllowed = 'CredentialLoginNotAllowed',
    SocialLoginNotAllowed = 'SocialLoginNotAllowed',
    SsoLoginNotAllowed = 'SsoLoginNotAllowed',
    OnlySsoIsAllowed = 'OnlySsoIsAllowed',
    NotAllowedViaIFrame = 'NotAllowedViaIFrame',
    NewPasswordNotDifferent = 'NewPasswordNotDifferent',
    InvalidExternalLoginRequest = 'InvalidExternalLoginRequest',
    InvalidSupportRequest = 'InvalidSupportRequest',
    UserLocked = 'UserLocked',
    PhoneNumberUpdateNotAllowed = 'PhoneNumberUpdateNotAllowed'
}

export enum HttpMethod {
    GET,
    POST,
    PUT,
    DELETE
}

export interface IIdentityResult<TResult> {
    success: boolean;
    error?: ServerError;
    data?: TResult;
    errorMsg?: string;
    targetUrl?: string;
}

export interface IOptions extends Record<string, unknown>{
    headers?: Record<string, string>;
    signal?: AbortSignal | null;
}

export interface IServerErrorHandler {
    error: ServerError;
    message: string;
    alertType?: AlertType;
}

export interface IServerResponse<TResult> {
    status: number;
    result: IIdentityResult<TResult>;
}

export interface IAPISuccessResponse<TResult> {
    status: number;
    data: TResult;
}

export interface IAPIErrorResponse<TResult> {
    message: string;
    forceRefresh: boolean;
    mappedHandler?: IServerErrorHandler;
    data: TResult;
}

export interface IAPIRedirectionResponse<TResult> {
    targetUrl: string;
    data: TResult;
}

export interface IAPIResponseOptions<T> {
    errorsMap?: (params: any) => Array<IServerErrorHandler>;
    onSuccess?: (response: IAPISuccessResponse<T>) => void;
    onError?: (response: IAPIErrorResponse<T>) => void;
    onRedirection?: (response: IAPIRedirectionResponse<T>) => void;
    onAbort?: () => void;
}

export const refreshBindingErrors = [
    'antiforgery'
];
