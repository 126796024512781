export enum SatisfactionState {
    Satisfied,
    InProgress,
    Dissatisfied
}

export interface IPolicyHintRow {
    hidden: boolean;
    text: string;
    checkSatisfaction: (value: string) => Promise<boolean>;
    debounce?: boolean;
}