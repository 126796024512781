interface ICookie {
    name: string;
    value: string;
}

export interface ICookieOptions {
    domain?: string;
    path?: string;
    expiration?: string;
}

/**
 * @returns {Array<ICookie>} An array containing all of the user's cookies.
 */
export const getAllCookies = (): Array<ICookie> => {
    let cookies = document.cookie;
    return cookies?.split('; ').map(x => x.split('=')).map(x => ({
        name: x[0],
        value: x[1]
    })) || [];
}

/**
 * @param {RegExp} filter - A regex filter for the cookie's name
 * @returns {string} The value of a cookie that matches the filter, or an empty string if is doesn't exist.
 */
export const getCookieValue = (filter: RegExp): string => {
    let match = getAllCookies().filter(x => x.name.match(filter));
    return match?.pop()?.value || '';
};

/**
 * @param {string} name - The cookie's name
 * @param {string} value - The cookie's new value
 * @param {ICookieOptions | undefined} options - Optional cookie sections
 */
export const setCookieValue = (name: string, value: string, options?: ICookieOptions): void => {
    let finalDomain = options?.domain || `.${window.location.host.split('.').splice(1).join('.')}`;
    let domainSection = (finalDomain !== '.') ? `; domain=${finalDomain}` : '';
    let pathSection = `; path=${options?.path || '/'}`;
    let expirationSection = options?.expiration ? `; expiration${options?.expiration}` : '';
    document.cookie = `${name}=${value}${domainSection}${pathSection}${expirationSection}`;
}