import styled from 'styled-components';
import { mobileMaxScreenWidth } from '../../common/config/ThemeConfig';

export const PasswordField = styled.div`
    position: relative;
`;

export const ForgotPasswordLabelContainer = styled.div`
    --bottom: -20px;
  
    & > div {
        justify-content: flex-end;
        position: absolute;
        bottom: var(--bottom);
        right: 0;
        left: 0;
        width: 70%;
        margin: auto;
        transition: .2s;
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        --bottom: -25px;
    }

    @media screen and (max-width: 220px) {
        --bottom: -40px;
    }
`;

export const SocialSectionContainer = styled.div`
    margin: 30px auto 65px;
`;