import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';
import {IInternalTwoFactorIssueResponse, IInternalTwoFactorVerifyResponse} from './types';

const apiPrefix = '/account/2fa/internal';

export const issueTwoFactor = async (
    provider: string,
    factorType: string,
    vendor?: string,
    abort? : AbortSignal | null
): Promise<IServerResponse<IInternalTwoFactorIssueResponse>> =>
    fetchService.post<IInternalTwoFactorIssueResponse>(
        `${apiPrefix}/issue/${provider}/${factorType}/${vendor}`,
        undefined,
        { signal: abort }
    );

export const verifyTwoFactor = async (
    provider: string,
    factorType: string,
    vendor: string,
    transactionId?: string,
    secret?: string,
    abort? : AbortSignal | null
): Promise<IServerResponse<IInternalTwoFactorVerifyResponse>> =>
    fetchService.post<IInternalTwoFactorVerifyResponse>(
        `${apiPrefix}/verify/${provider}/${factorType}/${vendor}`,
        { transactionId, secret },
        { signal: abort }
    );