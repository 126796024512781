import useServerData from './UseServerData';

interface IUserServerData {
    IsAuthenticated: boolean;
    UserName: string | undefined;
}

export interface IUser {
    userName: string;
    isAuthenticated: boolean;
}

const useUser = (): IUser => {
    const { User } = useServerData<{ User: IUserServerData }>();

    return {
        isAuthenticated: !!User?.IsAuthenticated,
        userName: User?.UserName || ''
    };
}

export default useUser;