import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.li<{
    selected: boolean;
}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    cursor: pointer;
    background-color: ${colorsPalettes.carbon[0]};
    transition: .2s;

    ${props => props.selected ? `
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    ` : `
        &:hover {
            background-color: ${colorsPalettes.navigation['ACTIVE_TILE_BACKGROUND']};
        }
    `}
`;

export const Flag = styled.img`
    height: 24px;
    margin: auto 10px auto 10%;
`;

export const LabelContainer = styled.div`
    margin: auto 0 auto 5px;
`;

export const Label = styled.p`
    margin: 0;
    font-size: .875rem;
`;

export const Arrow = styled.img<{
    visible: boolean;
}>`
    width: 10px;
    margin: auto;
    opacity: ${props => props.visible ? 1 : 0};
    transition: .4s;
`;