import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useServerData from '../UseServerData';
import useQueryParams from './UseQueryParams';

interface IIdentityParams<TState extends Record<string, unknown>> {
    queryParams: TState;
    routerParams: TState;
    serverParams: TState;
    allParams: TState;
}

/**
 * WARNING: In order to properly access the objects' properties,
 * the given TState interface must only include lowercase key names.
 *
 * @template TState
 * @returns {IIdentityParams<TState>} {
 *     {TState} queryParams - Page parameters passed via the URL's query,
 *     {TState} routerParams - Page parameters passed via the application's router,
 *     {TState} serverParams - Page parameters passed from the server,
 *     {TState} allParams - All page parameters combined
 * }
 */
const useIdentityParams = <TState extends Record<string, unknown>>(): IIdentityParams<TState> => {
    const location = useLocation();
    const routerParams = location.state as Record<string, unknown> ?? {};
    const serverParams = useServerData<{ ResponseModel: Record<string, unknown> }>();
    const queryParams = useQueryParams();

    /**
     * Convert an object's keys to lowercase.
     *
     * @template TState
     * @param {Record<string, unknown>} obj - The object to modify
     * @returns {TState} The new modified object.
     */
    const toLowerCaseProps = (obj: Record<string, unknown>): TState => {
        let res: Record<string, unknown> = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (value) res[key.toLowerCase()] = value;
        });

        return res as TState;
    }
    const typedRouterParams = useMemo<TState>(() => {
        return toLowerCaseProps(routerParams);
    }, [routerParams]);

    const typedServerParams = useMemo<TState>(() => {
        return toLowerCaseProps(serverParams.ResponseModel || {});
    }, [serverParams.ResponseModel]);

    const typedQueryParams = useMemo<TState>(() => {
        return toLowerCaseProps(queryParams);
    }, [queryParams]);

    const allParams = useMemo<TState>(() => ({
        ...typedQueryParams,
        ...typedRouterParams,
        ...typedServerParams
    }), [typedServerParams, typedRouterParams, typedQueryParams]);

    return {
        queryParams: typedQueryParams,
        routerParams: typedRouterParams,
        serverParams: typedServerParams,
        allParams
    };
}

export default useIdentityParams;
