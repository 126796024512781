import { asLiteralString, I18nKey } from '../utilities/Localization';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export interface ILocalization {
    translate: (key: I18nKey, params?: Record<string, string | number | boolean>) => string;
    changeLanguage: (lang: string, callback?: () => void) => void;
}

/**
 * @returns {ILocalization} {
 *      {(key: I18nKey, params?: Record<string, string | number | boolean>) => string} translate - Translate an i18n key,
 *      {(lang: string, callback?: () => void)} changeLanguage - Change the current language of the application
 * }
 */
const useLocalization = (): ILocalization => {
    const { t, i18n } = useTranslation();

    /**
     * Insert parameters to an i18n value.
     * A parameter is defined within two '%' characters (e.g. '%paramName%').
     *
     * @param {string} value - The i18n value string
     * @param {Record<string, string | number | boolean>} params - A list of parameters to inject
     * @returns {string} The i18n value with all parameters injected
     */
    const injectValueParameters = (value: string, params: Record<string, string | number | boolean>): string => {
        Object.entries(params).forEach(([pKey, pVal]) => {
            if (!pKey || !pVal) return;

            pKey = pKey.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
            let regex = new RegExp(`%${pKey}%`, 'gi');
            value = value.replace(regex, pVal?.toString?.());
        });

        return value;
    }

    /**
     * Translate a single i18n key.
     *
     * @param {string} key - The key to translate
     * @param {Record<string, string | number | boolean>} params - Parameters to inject into the retrieved value
     * @returns {string} The corresponding i18n value,
     *                   or an i18n error representation if the translation is missing (i18n[key]).
     */
    const translate = (key: I18nKey, params?: Record<string, string | number | boolean>): string => {
        let literal = asLiteralString(key);
        let rawValue = t(literal);

        return params ? injectValueParameters(rawValue, params) : rawValue;
    }

    return {
        translate,
        changeLanguage: i18n.changeLanguage
    }
}

export default useLocalization;