import { FunctionComponent } from 'react';
import I18n from '../I18n/I18n';
import {
    Container,
    TextContainer,
    Subtitle,
    SubtitleContainer,
    Title,
    SettingsLinkContainer
} from './TwoFactorInternalOptions.style';
import LinkParagraph from '../LinkParagraph/LinkParagraph';
import useLocalization from '../../common/hooks/UseLocalization';
import { asI18nKey } from '../../common/utilities/Localization';

interface ITwoFactorInternalOptions {
    title: string;
    text: string;
    disabled: boolean;
    onClick: () => void;
    settingsLink?: string;
}

const TwoFactorInternalOptions: FunctionComponent<ITwoFactorInternalOptions> = props => {
    const { translate } = useLocalization();

    return (
        <Container
            onClick={() => props.onClick()}
            disabled={props.disabled}
        >
            <TextContainer>
                <Title disabled={props.disabled}>
                    {props.title}
                </Title>
                <SubtitleContainer>
                    <Subtitle>
                        {props.disabled ? (
                            <>
                                <I18n>2fa.disabled.option.title</I18n>
                                {!!props.settingsLink && (
                                    <SettingsLinkContainer>
                                        <LinkParagraph
                                            linkLabel={translate(asI18nKey('2fa.disabled.option.action'))}
                                            suffix={translate(asI18nKey('2fa.disabled.option.suffix'))}
                                            href={props.settingsLink}
                                        />
                                    </SettingsLinkContainer>
                                )}
                            </>
                        ) : (
                            <>{props.text}</>
                        )}
                    </Subtitle>
                </SubtitleContainer>
            </TextContainer>
        </Container>
    );
};

export default TwoFactorInternalOptions;
