import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../Modal/Modal';
import { FieldsRow, Footer, Instructions, Subtitle, Title } from './SupportModal.style';
import TextField from '../Fields/TextField/TextField';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import TextArea from '../Fields/TextArea/TextArea';
import LegalSection from '../LegalSection/LegalSection';
import SubmitButton from '../SubmitButton/SubmitButton';
import Dropdown from '../Fields/Dropdown/Dropdown';
import dropdownOptions from './DropdownOptions';
import countries from '../../common/utilities/Countries';
import I18n from '../I18n/I18n';
import { sendTicket } from './SupportService';
import { ISupportData } from './types';
import I18nContext from '../../common/context/I18nContext';
import SupportFormSuccessModal from './Success/SupportFormSuccessModal';
import useTracking from '../../common/hooks/UseTracking';
import useTrigger from '../../common/hooks/UseTrigger';
import { asI18nKey } from '../../common/utilities/Localization';
import useAPI from '../../common/hooks/UseAPI';
import AlertToast from '../AlertToast/AlertToast';
import { AlertType, IAlert } from '../AlertToast/types';
import { ServerError } from '../../common/utilities/API/types';

interface ISupportModal {
    isOpen: boolean;
    onClose: () => void;
    predefinedMessage?: string;
}

const SupportModal: FunctionComponent<ISupportModal> = props => {
    const { translate } = useContext(I18nContext);
    const fieldFocusTrigger = useTrigger();
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const changed = useMemo<boolean>(() => formInputs?.some(x => !!x.value), [formInputs]);
    const { trackEvent } = useTracking('contact us/default');
    const [serverAlert, setServerAlert] = useState<IAlert | undefined>();
    const api = useAPI();

    useEffect(() => {
        if (props.isOpen) fieldFocusTrigger.fire();
        else setServerAlert(undefined);
    }, [props.isOpen]);

    /**
     * Send a support ticket to the server.
     *
     * @param {Record<string, string>} inputs - The data filled in the form
     */
    const onSubmit = async (inputs: Record<string, string>): Promise<boolean> => {
        let data = (inputs as unknown) as ISupportData;
        data.fullPhoneNumber = data.countryCode + data.phoneLineNumber;

        return await api(() => sendTicket(data), {
            errorsMap: () => [
                {
                    error: ServerError.InvalidPhoneNumber,
                    message: translate(asI18nKey('support.error.invalid_phone'))
                },
                {
                    error: ServerError.InvalidSupportRequest,
                    message: translate(asI18nKey('support.error.general'))
                }
            ],
            onSuccess: () => {
                props.onClose();
                setSuccessModalOpen(true);
            },
            onError: ({ message, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    setServerAlert({ text: message, type: alertType });
                    logSubmissionFailure(message);
                }
            }
        });
    }

    /**
     * Activate when the support modal is closed.
     * This function closes the modal and tracks this action.
     */
    const onSupportClose = (): void => {
        trackEvent('contact us', 'close');
        props.onClose();
    }

    return (
        <>
            <Modal
                { ...props }
                onClose={onSupportClose}
                changesMade={changed}
            >
                <Title>
                    <I18n>support.title</I18n>
                </Title>
                <Subtitle>
                    <I18n>support.subtitle</I18n>
                </Subtitle>
                <AlertToast
                    isVisible={!!serverAlert}
                    alert={serverAlert}
                />
                <Instructions>
                    <I18n>support.subtitle.instruction</I18n>
                </Instructions>
                {props.isOpen && (
                    <>
                        <FieldsRow>
                            <TextField
                                name={'firstName'}
                                type={'Text'}
                                placeholder={translate(asI18nKey('support.field.first_name.placeholder'))}
                                required
                                focusOnPageLoad
                                shouldFocus={fieldFocusTrigger.active}
                                size={{ width: 45 }}
                                verticalMargin={{ top: 15, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                            <TextField
                                name={'lastName'}
                                type={'Text'}
                                placeholder={translate(asI18nKey('support.field.last_name.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 15, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <TextField
                                name={'email'}
                                type={'Email'}
                                placeholder={translate(asI18nKey('support.field.email.placeholder'))}
                                required
                                size={{ width: 95 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <Dropdown
                                name={'countryCode'}
                                placeholder={translate(asI18nKey('support.field.country.placeholder'))}
                                required
                                size={{ width: 30 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                listWidth={160}
                                options={countries.map(x => ({
                                    text: x.name,
                                    value: `+${x.area}`,
                                    image: x.flag
                                }))}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                            />
                            <TextField
                                name={'phoneLineNumber'}
                                type={'Phone'}
                                placeholder={translate(asI18nKey('support.field.phone.placeholder'))}
                                required
                                size={{ width: 60 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <TextField
                                name={'companyName'}
                                type={'Text'}
                                placeholder={translate(asI18nKey('support.field.company.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                            <TextField
                                name={'companyWebsite'}
                                type={'Url'}
                                placeholder={translate(asI18nKey('support.field.website.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <Dropdown
                                name={'employees'}
                                placeholder={translate(asI18nKey('support.field.employees.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                options={dropdownOptions['Employees']}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                            />
                            <Dropdown
                                name={'industry'}
                                placeholder={translate(asI18nKey('support.field.industry.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                options={dropdownOptions['Industry']}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <Dropdown
                                name={'department'}
                                placeholder={translate(asI18nKey('support.field.department.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                options={dropdownOptions['Department']}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                            />
                            <Dropdown
                                name={'role'}
                                placeholder={translate(asI18nKey('support.field.job.placeholder'))}
                                required
                                size={{ width: 45 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                options={dropdownOptions['Role']}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                            />
                        </FieldsRow>
                        <FieldsRow>
                            <TextArea
                                name={'message'}
                                placeholder={translate(asI18nKey('support.field.issue.placeholder'))}
                                required
                                size={{ width: 95, height: 150 }}
                                verticalMargin={{ top: 0, bottom: 15 }}
                                shouldVerify={inputsVerificationTrigger.active}
                                onChange={setInput}
                                onEnterPress={submissionTrigger.fire}
                                initialValue={props.predefinedMessage}
                            />
                        </FieldsRow>
                        <Footer>
                            <LegalSection
                                prefix={translate(asI18nKey('support.legal'))}
                                align={'left'}
                            />
                            <SubmitButton
                                onSuccess={onSubmit}
                                widthPercent={40}
                                formInputs={formInputs}
                                onFailedVerification={inputsVerificationTrigger.fire}
                                shouldSubmit={submissionTrigger.active}
                            >
                                <I18n>support.button</I18n>
                            </SubmitButton>
                        </Footer>
                    </>
                )}
            </Modal>
            <SupportFormSuccessModal
                isOpen={successModalOpen}
                onClose={() => setSuccessModalOpen(false)}
            />
        </>
    );
}

export default SupportModal;
