import {NewFooterContainer, NewFooterContent, NewFooterImage, Title, SubTitle} from "./TipFooter.style";
import UserIcon from './images/UserIcon.svg';
import withAutomationTesting, {AutomationTarget} from "../../../common/HOC/WithAutomationTesting";
import I18n from '../../I18n/I18n';

export interface INewFooter extends AutomationTarget {}
const NewFooter: React.FunctionComponent<INewFooter> = () => {
    return (<NewFooterContainer>
        <NewFooterImage src={UserIcon} />
        <NewFooterContent>
            <Title>
                <I18n>new_footer.title</I18n>
            </Title>
            <SubTitle>
                <I18n>new_footer.sub_title</I18n>
            </SubTitle>
        </NewFooterContent>
    </NewFooterContainer>);
}

export default withAutomationTesting(NewFooter);
