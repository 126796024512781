import { FunctionComponent, useMemo } from 'react';
import { IPageTemplate } from '../types';
import AlertToast from '../../AlertToast/AlertToast';
import FadeTransition from '../../FadeTransition/FadeTransition';
import { Layout, AppLogo, Title, Background, AdditionalOptionsDiv } from './CardPageTemplate.style';
import { useLocation } from 'react-router-dom';
import AppLogoImg from '../images/app_logo.svg';
import useIdentityNavigation from '../../../common/hooks/UseIdentityNavigation';
import LinkParagraph from '../../LinkParagraph/LinkParagraph';
import useLocalization from '../../../common/hooks/UseLocalization';
import { asI18nKey } from '../../../common/utilities/Localization';
import UseReturnUrl from "../../../common/hooks/Parameters/UseReturnUrl";
import { deconstructUrl, fullyDecodeURIComponent } from "../../../common/utilities/URL";

const CardPageTemplate: FunctionComponent<IPageTemplate> = props => {
    const location = useLocation();
    const returnUrl = UseReturnUrl();
    const { translate } = useLocalization();
    const { redirectToReturnUrl } = useIdentityNavigation();
    const domainName = useMemo<string>(() => {
        let decodedReturnUrl = fullyDecodeURIComponent(returnUrl);
        return decodedReturnUrl ? deconstructUrl(decodedReturnUrl).host : 'the previous location';
    }, [returnUrl]);

    return (
        <Background>
            <Layout maxContentWidth={props.maxContentWidth}>
                <FadeTransition
                    msTime={500}
                    dependencies={[ location ]}
                >
                    <AppLogo>
                        <img
                            src={AppLogoImg}
                            alt={''}
                        />
                    </AppLogo>
                    <Title>{props.title}</Title>
                    <AlertToast
                        isVisible={!!props.serverAlert}
                        alert={props.serverAlert}
                    />
                    {props.children}
                </FadeTransition>
            </Layout>
            {props.showFooter && (
                <AdditionalOptionsDiv>
                    <LinkParagraph
                        linkLabel={translate(asI18nKey('card_layout.footer.back'), {
                            domain: domainName
                        })}
                        callback={redirectToReturnUrl}
                    />
                </AdditionalOptionsDiv>
            )}
        </Background>
    );
}

export default CardPageTemplate;
