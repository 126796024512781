
export default function maskPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
        return '';
    }

    if (phoneNumber.length < 6) {
        return `${phoneNumber[0]}****${phoneNumber[phoneNumber.length - 1]}`;
    }

    const length = phoneNumber.length;
    const start = phoneNumber.substring(0, 3);
    const end = phoneNumber.substring(length - 4, length);
    const maskedLength = length - start.length - end.length;
    const maskedSection = '*'.repeat(maskedLength);

    return start + maskedSection + end;
}
