import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';
import {ISendCodeResponse} from "../DeviceVerification/types";

/**
 * Send the device verification code to the user.
 *
 * @param {string | undefined } type - 2fa specific method
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const sendCode = async (type: string | undefined): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/2fa/issue', { type });

/**
 * Verify the user's device verification code.
 *
 * @param {string} code - The user's device verification code
 * @returns {Promise<IServerResponse<ISendCodeResponse>>} The server's response.
 */
export const verifyCode = async (code: string): Promise<IServerResponse<ISendCodeResponse>> =>
    fetchService.post<any>('/account/2fa/verify', { code });
