import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../../common/config/ThemeConfig';

export const InputArea = styled.textarea<{
    filled: boolean;
    disabled: boolean;
    height?: number;
}>`
    width: calc(100% - 30px);
    height: calc(${props => props.height || 100}px - 20px);
    border: .2px ${colorsPalettes.navigation['NAV_BACKGROUND']} solid;
    border-radius: 4px;
    font-family: inherit;
    font-size: .875rem;
    padding: 10px 15px;
    background-color: ${props => props.filled ? colorsPalettes.sky[100] : colorsPalettes.carbon[0]};
    outline: none;
    resize: none;
    transition: .2s;
  
    ${props => props.disabled && `
        color: ${colorsPalettes.carbon[250]};
        background-color: ${colorsPalettes.navigation['NAV_BACKGROUND']};
        cursor: not-allowed; 
    `}
  
    &:hover {
        border-color: ${colorsPalettes.carbon[100]};
    }
  
    &:focus {
        border-color: ${colorsPalettes.sky[500]};
    }
  
    &::placeholder {
        color: ${colorsPalettes.carbon[200]};
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        height: calc(${props => props.height || 100}px * 1.5);
    }
`;