import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Subtitle = styled.h2`
    margin: 0 auto 30px;
    text-align: center;
    font-size: .75rem;
    font-weight: 400;
    color: ${colorsPalettes.carbon[400]};
`;

export const RememberSelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

export const SettingsButtonContainer = styled.div`
    margin-top: 10px;
    text-align: left;
  
    & > * {
        justify-content: left;
        margin: 3px 0;
    }
`;

export const NoOpImg = styled.img`
    display: block;
    margin: 46px auto 16px auto;
    pointer-events: none;
`;

export const NoOpTitle = styled.h1`
    margin: auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    width: 80%;
    color: ${colorsPalettes.carbon[500]};
`;

export const NoOpSubtitle = styled.h2`
    margin: 2px auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${colorsPalettes.midnight[200]};
`;

export const NoOpContent = styled.div`
    height: 70%;
`;