import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';

/**
 * Log the user into the application.
 *
 * @param {string} browserId - The user's unique browser ID
 * @param {string} provider - The name of the selected social login provider
 * @param hashTail
 * @returns {Promise<IAPIResponse<void>>} The server's response.
 */
export const login = async (browserId: string, provider: string, hashTail?: string | null): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/login/social', {
        browserId,
        provider,
        ...(hashTail ? {hashTail} : {})
    });
