import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import LinkParagraph from '../LinkParagraph/LinkParagraph';
import { ResendCooldownInfo, ResendCooldownTimer } from './TimedResendButton.style';
import FadeTransition from '../FadeTransition/FadeTransition';

interface ITimeLimitedButton {
    onSendCode: () => void;
    cooldownTime: number;
    cancelCooldown?: boolean;
    confirmationMessage?: string;
    buttonLabel: string;
    buttonPrefix?: string;
    buttonSuffix?: string;
    timerPrefix?: string;
    timerSuffix?: string;
}

const TimedResendButton: FunctionComponent<ITimeLimitedButton> = props => {
    const [resendCooldownTime, setResendCooldownTime] = useState<number>(0);
    const [resendCooldownInterval, setResendCooldownInterval] = useState<number>();
    const [showResendAck, setResendAck] = useState<boolean>(false);
    const onCooldown = useMemo<boolean>(() => resendCooldownTime > 0, [resendCooldownTime]);
    const resendAckTime = 2;

    //timeout
    useEffect(() => {
        if (resendCooldownTime <= 0 && resendCooldownInterval)
            clearInterval(resendCooldownInterval);
    }, [resendCooldownTime, resendCooldownInterval]);

    //cancel
    useEffect(() => {
        if (props.cancelCooldown) {
            setResendAck(false);
            setCooldown(0);
        }
    }, [props.cancelCooldown]);

    /**
     * Set the 'Resend' button's cooldown timer,
     * preventing it from being clicked again before the time runs out.
     *
     * @param {number} seconds - Time [s] until the buttons is clickable again
     */
    const setCooldown = (seconds: number): void => {
        resendCooldownInterval && clearInterval(resendCooldownInterval);
        setResendCooldownTime(Math.round(seconds));
        (seconds > 0) && setResendCooldownInterval(window.setInterval(() => {
            setResendCooldownTime(time => time - 1);
        }, 1000));
    }

    /**
     * Activate when the user click the resend button.
     * This function sends the code and activates the timer.
     */
    const onResend = (): void => {
        setResendAck(true);
        setCooldown(props.cooldownTime + resendAckTime);
        setTimeout(() => setResendAck(false), resendAckTime * 1000);
        props.onSendCode();
    }

    return (
        <FadeTransition
            msTime={500}
            dependencies={[
                showResendAck,
                onCooldown
            ]}
        >
            {!onCooldown ? (
                <LinkParagraph
                    prefix={props.buttonPrefix}
                    linkLabel={props.buttonLabel}
                    suffix={props.buttonSuffix}
                    callback={onResend}
                />
            ) : (
                <ResendCooldownInfo>
                    {showResendAck ? props.confirmationMessage : (
                        <>
                            {!!props.timerPrefix && (
                                <>
                                    {props.timerPrefix}
                                    &nbsp;
                                </>
                            )}
                            <ResendCooldownTimer>{resendCooldownTime}</ResendCooldownTimer>
                            {!!props.timerSuffix && (
                                <>
                                    &nbsp;
                                    {props.timerSuffix}
                                </>
                            )}
                        </>
                    )}
                </ResendCooldownInfo>
            )}
        </FadeTransition>
    );
}

export default TimedResendButton;
