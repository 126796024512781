import pagesConfig, { IdentityPage } from '../config/PagesConfig';

/**
 * @param {keyof typeof IdentityPage} page - The requested page
 * @returns {string} The page's path.
 */
export const getPath = (page: keyof typeof IdentityPage): string => {
    let routes = pagesConfig[page].routes;
    return routes?.length ? routes[0] : '/';
}

/**
 * @returns {keyof typeof IdentityPage} The current page, or Error page if the URL is broken.
 */
export const getCurrentPage = (): keyof typeof IdentityPage => {
    let maxMatchLen = 0;
    let lastMatch = null;
    let href = window.location.href;

    for (let [key, value] of Object.entries(pagesConfig)) {
        for (let route of value.routes) {
            if (href.includes(route)) {
                let matchLen = route.length;

                if (matchLen > maxMatchLen) {
                    maxMatchLen = matchLen;
                    lastMatch = key as keyof typeof IdentityPage;
                }
            }
        }
    }

    return lastMatch || 'Error';
}