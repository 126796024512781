import { FunctionComponent, useEffect } from 'react';
import { Subtitle } from '../InternalTwoFactorMethodPage.style';
import { issueTwoFactor, verifyTwoFactor } from '../InternalTwoFactorService';
import useAPI from '../../../common/hooks/UseAPI';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useIdentityForm from '../../../common/hooks/UseIdentityForm';
import TextField from '../../../components/Fields/TextField/TextField';
import I18n from '../../../components/I18n/I18n';
import { asI18nKey } from '../../../common/utilities/Localization';
import useLocalization from '../../../common/hooks/UseLocalization';
import useIdentityParams from "../../../common/hooks/Parameters/UseIdentityParams";
import TimedResendButton from '../../../components/TimedResendButton/TimedResendButton';
import useTrigger from '../../../common/hooks/UseTrigger';
import { ServerError } from '../../../common/utilities/API/types';
import { AlertType } from '../../../components/AlertToast/types';
import {
    IInternalTwoFactorMethod,
    IInternalTwoFactorAuthenticator,
    TwoFactorAuthenticationVerifyResult
} from '../types';

const InternalTwoFactorAuthenticationSmsMethod: FunctionComponent<IInternalTwoFactorMethod> = props => {
    const { translate } = useLocalization();
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const { routerParams } = useIdentityParams<{ authenticator: IInternalTwoFactorAuthenticator; }>();
    const cancelResendCooldown = useTrigger();
    const api = useAPI();

    useEffect(() => { sendCode(); }, []);

    /**
     * Send the user an SMS.
     */
    const sendCode = async (): Promise<void> => {
        await api(async () => await issueTwoFactor(
            routerParams.authenticator.provider,
            routerParams.authenticator.type,
            routerParams.authenticator.vendor,
            props.abortSignal
        ), {
            errorsMap: () => [
                {
                    error: ServerError.CodeIssueFailure,
                    message: translate(asI18nKey('2fa.sms.error.issue_failed'))
                },
            ],
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);

                cancelResendCooldown.fire();
            },
            onAbort: cancelResendCooldown.fire
        });
    }

    /**
     * Authenticate using the code entered by the user.
     *
     * @param {string} transactionId - The issued transaction ID
     * @param {string} code - The code entered by the user
     * @returns {Promise<boolean>} True if the authentication is successful.
     */
    const verify = async ({ transactionId, code }: Record<string, string>): Promise<boolean> => {
        return await api(async () => await verifyTwoFactor(
            routerParams.authenticator.provider,
            routerParams.authenticator.type,
            routerParams.authenticator.vendor,
            transactionId,
            code,
            props.abortSignal
        ), {
            errorsMap: () => [
                {
                    error: ServerError.CodeVerificationRejected,
                    message: translate(asI18nKey('2fa.sms.error.invalid_code'))
                },
            ],
            onSuccess: ({ data }) => {
                let accepted = data?.result === TwoFactorAuthenticationVerifyResult.Accepted;
                if (accepted) props.onVerified();
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            },
            onAbort: cancelResendCooldown.fire
        });
    }

    return (
        <>
            <Subtitle>
                <I18n>2fa.sms.subtitle</I18n>
            </Subtitle>
            <TextField
                name={'code'}
                label={translate(asI18nKey('2fa.sms.field.code.label'))}
                focusOnPageLoad
                required
                onChange={setInput}
                shouldVerify={inputsVerificationTrigger.active}
                onEnterPress={submissionTrigger.fire}
            />
            <SubmitButton
                onSuccess={verify}
                widthPercent={70}
                formInputs={formInputs}
                shouldSubmit={submissionTrigger.active}
                onFailedVerification={inputsVerificationTrigger.fire}
            >
                <I18n>2fa.sms.verify</I18n>
            </SubmitButton>
            <TimedResendButton
                onSendCode={sendCode}
                confirmationMessage={translate(asI18nKey('2fa.no_code.confirmation'))}
                buttonLabel={translate(asI18nKey(`2fa.no_code.action`))}
                buttonPrefix={translate(asI18nKey('2fa.no_code'))}
                timerPrefix={translate(asI18nKey('2fa.no_code.cooldown.prefix'))}
                timerSuffix={translate(asI18nKey('2fa.no_code.cooldown.suffix'))}
                cancelCooldown={cancelResendCooldown.active}
                cooldownTime={30}
            />
        </>
    );
}

export default InternalTwoFactorAuthenticationSmsMethod;