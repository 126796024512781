import styled, { css, keyframes } from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div`
    padding: 0 10px;
    font-family: 'DM Sans', sans-serif;
`;

export const Title = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
`;

export const List = styled.ul`
    margin: 10px 0 0;
    padding: 0;
`;

export const HintRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 27px;
`;

const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
`;

export const SatisfiedIconContainer = styled.img<{
    spin: boolean;
}>`
    width: 14px;
    
    ${props => props.spin && css`
        animation-name: ${spin};
        animation-duration: .5s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    `}
`;

export const HintText = styled.p`
    margin-left: 10px;
    color: ${colorsPalettes.carbon[400]};
`;