/**
 * Convert a string to a literal that can be inserted
 * inside a regular expression without affecting it.
 *
 * @param {string} phrase - The phrase to convert
 * @returns {string} A safe string literal with all special characters escaped.
 */
export const escape = (phrase: string): string =>
    phrase.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&');

/**
 * Build a regular expression that forbids any occurrence of a certain phrase.
 *
 * @param {string} phrase - The phrase to negate
 * @param {string | undefined} regexFlags - Additional regex flags
 * @returns {RegExp} A regular expression that forbids any occurrence of the given phrase.
 */
export const negate = (phrase: string, regexFlags?: string): RegExp =>
    new RegExp(`^((?!(${phrase})).)*$`, regexFlags);