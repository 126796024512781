import { FunctionComponent, useState } from 'react';
import { Container, IconContainer, Icon, Label, LabelContainer, Loader } from './SocialAuthButton.style';
import useTracking from '../../../common/hooks/UseTracking';
import { getBrowserId } from '../../../common/utilities/Identification';
import { login } from '../SocialAuthService';
import useAPI from '../../../common/hooks/UseAPI';
import withAutomationTesting, { AutomationTarget } from '../../../common/HOC/WithAutomationTesting';
import { asI18nKey } from '../../../common/utilities/Localization';
import { AlertType } from '../../AlertToast/types';
import useLocalization from '../../../common/hooks/UseLocalization';
import { ServerError } from '../../../common/utilities/API/types';

interface ISocialAuthButton extends AutomationTarget {
    providerName: string;
    schemeValue: string;
    icon: string;
    trackingCategory?: string;
    onServerAlert?: (message: string, type?: AlertType) => void;
    logSubmissionFailure?: (message: string) => void;
    onUnexpectedError?: (modalPersistent?: boolean) => void;
    onSuccessfulRedirection?: () => void;
}

const SocialAuthButton: FunctionComponent<ISocialAuthButton> = props => {
    const { trackEvent } = useTracking(props.trackingCategory);
    const { translate } = useLocalization();
    const [loading, setLoading] = useState<boolean>(false);
    const api = useAPI();

    /**
     * Activate when the user clicks the button.
     * This function logs the user into the application via the selected social provider.
     */
    const onClick = async (): Promise<void> => {
        setLoading(true);
        trackEvent('social login', 'click');

        let browserId = await getBrowserId();

        await api(() => login(browserId, props.schemeValue, window.location.hash?.substring(1)), {
            errorsMap: () => [
                {
                    error: ServerError.InvalidExternalLoginRequest,
                    message: translate(asI18nKey('external_login.error.invalid_request'))
                }
            ],
            onRedirection: () => props.onSuccessfulRedirection?.(),
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert?.(message, alertType);
                    props.logSubmissionFailure?.(message);
                }
                else props.onUnexpectedError?.(forceRefresh);
            }
        });

        setLoading(false);
    }

    return (
        <Container
            className={'accessible'}
            isLoading={loading}
            disabled={loading}
            onClick={onClick}
            { ...props.automationElementAttr }
        >
            <Loader isLoading={loading} />
            <IconContainer>
                <Icon
                    src={props.icon}
                    alt={props.providerName}
                />
            </IconContainer>
            <LabelContainer>
                <Label>
                    Sign in with {props.providerName}
                </Label>
            </LabelContainer>
        </Container>
    );
}

export default withAutomationTesting(SocialAuthButton);
