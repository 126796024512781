import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    displayed: boolean;
}>`
    position: relative;
    display: ${props => props.displayed ? 'contents' : 'inherit'};
    pointer-events: ${props => props.displayed ? 'all' : 'none'};
    opacity: ${props => props.displayed ? 1 : 0};
`;

export const Tooltip = styled.div`
    display: inline-table;
    position: absolute;
    left: calc(100% + 30px);
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 10px;
    border: 1px ${colorsPalettes.carbon[50]} solid;
    border-radius: 4px;
    background-color: ${colorsPalettes.carbon[0]};
    white-space: nowrap;
    opacity: .9;
    -webkit-box-shadow: 10px 10px 26px -26px ${colorsPalettes.black[0]}bf;
    -moz-box-shadow: 10px 10px 26px -26px ${colorsPalettes.black[0]}bf;
    box-shadow: 0 4px 9px #bbbbbb3f;
    transition: .3s;
`;

export const TooltipTail = styled.div`
    --tail-border: 1px ${colorsPalettes.carbon[50]} solid;
  
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(100% + 20px);
    width: 20px;
    height: 20px;
    margin: auto;
    border-left: var(--tail-border);
    border-bottom: var(--tail-border);
    border-radius: 0 100px 0 0;
    background-color: ${colorsPalettes.carbon[0]};
    box-shadow: 0 4px 5px #bbbbbb3f;
    transform: rotate(45deg);
`;

export const Content = styled.div`
    margin: 0;
    font-size: .825rem;
    font-weight: 400;
    text-align: left;
`;