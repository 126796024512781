import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px auto;
    font-size: .825rem;
    
    & > p {
        margin: 0 2px;
    }
`;

export const UnclickableLabel = styled.p`
    color: ${colorsPalettes.carbon[400]};
`;

export const ClickableLabel = styled.p`
    color: ${colorsPalettes.sky[500]};
    cursor: pointer;
    outline: none;
    border: none;
    transition: .3s;
  
    &:hover {
        color: ${colorsPalettes.blue[500]};
    }
`;

export const AbsoluteLinkAnchor = styled.a`
    display: none;
`;