import fetchService from './API/FetchService';

export type LogLevel = 'Error' | 'Critical' | 'Warning' | 'Information';

const IgnoredLogs: Array<RegExp> = [
    /s\.go-mpulse\.net\/boomerang/
];

/**
 * @param {string} log - The log message to check
 * @returns {boolean} True if the given log message should be ignored by the app's filter.
 */
export const shouldLogBeIgnored = (log: string): boolean =>
    IgnoredLogs.some(regex => regex.test(log));

/**
 * Log a client error in the server.
 *
 * @param {string} message - The log's message
 * @param {LogLevel} logLevel - The severity level of the log
 * @param {Record<string, unknown>} data - Any additional error data
 * @param {boolean} logToConsole - True to log the error to the browser's console
 */
export const logClientMessage = (message: string, logLevel: LogLevel = 'Error', data: Record<string, unknown> = {}, logToConsole: boolean = false): void => {
    logToConsole && console.error(message, { errorData: data }, 'This error has been logged to the server for performance monitoring.');
    fetchService.post<void>('/error/client', {
        message: message,
        logLevel: logLevel,
        exceptionMessage: data?.exception,
        exceptionCallStack: data?.callstack,
        errorData: data,
        metadata: {
            language: navigator.language
        }
    });
}
