import { FunctionComponent, useRef } from 'react';
import { Container, UnclickableLabel, ClickableLabel, AbsoluteLinkAnchor } from './LinkParagraph.style';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { IdentityPage } from '../../common/config/PagesConfig';
import withAutomationTesting, { AutomationTarget } from '../../common/HOC/WithAutomationTesting';

interface ILinkParagraph extends AutomationTarget {
    linkLabel: string;
    targetPage?: keyof typeof IdentityPage;
    href?: string;
    callback?: () => void;
    prefix?: string;
    suffix?: string;
}

const LinkParagraph: FunctionComponent<ILinkParagraph> = props => {
    const { navigate } = useIdentityNavigation();
    const anchorRef = useRef<HTMLAnchorElement>(null);

    /**
     * Activate the passed callback function if it exists and navigate to the linked page.
     */
    const onLinkClick = (): void => {
      props.callback?.();
      if (!!props.targetPage)
          navigate(props.targetPage);
      else if (!!props.href)
          anchorRef?.current?.click();
    }

    return (
        <Container>
            {props.prefix && (
                <UnclickableLabel>
                    {props.prefix}
                </UnclickableLabel>
            )}
            <ClickableLabel
                className={'accessible'}
                tabIndex={0}
                onClick={onLinkClick}
                onKeyDown={ev => ev.key === 'Enter' && onLinkClick()}
                { ...props.automationElementAttr }
            >
                {props.linkLabel}
            </ClickableLabel>
            {props.suffix && (
                <UnclickableLabel>
                    {props.suffix}
                </UnclickableLabel>
            )}
            {!!props.href && (
                <AbsoluteLinkAnchor
                    ref={anchorRef}
                    href={props.href}
                    target={'_blank'}
                />
            )}
        </Container>
    );
}

export default withAutomationTesting(LinkParagraph);
