import { FunctionComponent } from 'react';
import socialAuthConfig from '../../common/config/SocialAuthConfig';
import { Container } from './SocialAuthSection.style';
import SocialAuthButton from './Button/SocialAuthButton';
import { AlertType } from '../AlertToast/types';

interface ISocialAuthSection {
    trackingCategory?: string;
    onServerAlert?: (message: string, type?: AlertType) => void;
    logSubmissionFailure?: (message: string) => void;
    onUnexpectedError?: (modalPersistent?: boolean) => void;
    onSuccessfulRedirection?: () => void;
}

const SocialAuthSection: FunctionComponent<ISocialAuthSection> = props => {
    return (
        <Container>
            {socialAuthConfig.map(x => (
                <SocialAuthButton
                    {...props}
                    key={x.provider}
                    providerName={x.provider}
                    automationName={`social-${x.provider.toLowerCase()}-button`}
                    schemeValue={`SocialLogin.${x.provider}`}
                    icon={x.icon}
                />
            ))}
        </Container>
    );
}

export default SocialAuthSection;
