import {IServerResponse} from "../../../common/utilities/API/types";
import fetchService from "../../../common/utilities/API/FetchService";

/**
 * Redirect to the registration page.
 *
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const redirectToRegistration = async (): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/register');
