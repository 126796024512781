import styled from 'styled-components';

export const Subtitle = styled.p`
    font-size: .75rem;
`;

export const PushIllustrationContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 0;
    margin: -80px auto auto;
`;

export const PushAlert = styled.p`
    margin: -100px auto 50px;
    font-size: 1.2rem;
`;