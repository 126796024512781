import { FunctionComponent, useEffect, useRef, useMemo, KeyboardEvent, useContext } from 'react';
import { Background, CloseButton, Container, Content, OuterContainer } from './Modal.style';
import CloseIco from './images/close.svg';
import I18nContext from '../../common/context/I18nContext'
import { asI18nKey } from '../../common/utilities/Localization';

interface IModal {
    isOpen: boolean;
    onClose: () => void;
    width?: number;
    height?: number;
    changesMade?: boolean;
    persistent?: boolean;
}

const Modal: FunctionComponent<IModal> = props => {
    const containerRef = useRef<HTMLDivElement>(null);
    const width = useMemo(() => props.width || 500, [props.width]);
    const height = useMemo(() => props.height || 750, [props.height]);
    const { translate } = useContext(I18nContext);

    //focus
    useEffect(() => {
        props.isOpen && containerRef?.current?.focus();
    }, [containerRef, props.isOpen]);

    //fix scrolling
    useEffect(() => {
        document.body.style.overflowY = props.isOpen ? 'hidden' : 'unset';

        if (props.isOpen) {
            let container = containerRef?.current;
            if (container) container.scrollTop = 0;
        }
    }, [props.isOpen]);

    /**
     * Activate when the user closes the modal.
     * This function makes sure the user doesn't lose any unsaved changes.
     */
    const onClose = (): void => {
        let warning = translate(asI18nKey('modal.close_warning'));

        if (!props.changesMade || window.confirm(warning))
            props.onClose();
    }

    /**
     * Activate when a key is pressed while the modal is open.
     * This function closes the modal if the pressed key is Escape.
     *
     * @param {KeyboardEvent} ev - Default event object
     */
    const onKeyDown = (ev: KeyboardEvent<HTMLDivElement>): void => {
        if (!props.persistent && ev.key === 'Escape') onClose();
    }

    return (
        <OuterContainer
            tabIndex={0}
            ref={containerRef}
            open={props.isOpen}
            onKeyDown={onKeyDown}
        >
            <Background>
                <Container
                    open={props.isOpen}
                    width={width}
                    height={height}
                >
                    {!props.persistent && (
                        <CloseButton onClick={onClose}>
                            <img
                                src={CloseIco}
                                alt={'close'}
                            />
                        </CloseButton>
                    )}
                    <Content>
                        {props.children}
                    </Content>
                </Container>
            </Background>
        </OuterContainer>
    );
}

export default Modal;