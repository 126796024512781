import { FunctionComponent } from 'react';
import { Container } from './CircularLoader.style';
import LoaderImg from "./images/loader.png";

interface ICircularLoader {
    fullscreen?: boolean;
}

export const CircularLoader: FunctionComponent<ICircularLoader> = props => {
    return (
        <Container fullscreen={!!props.fullscreen}>
            <img
                src={LoaderImg}
                alt={'loading'}
            />
        </Container>
    );
}

export default CircularLoader;