import {FunctionComponent, useMemo, useState} from 'react';
import TextField from '../../components/Fields/TextField/TextField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import I18n from '../../components/I18n/I18n';
import SupportModal from '../../components/SupportModal/SupportModal';
import { IPage } from '../../components/PageWrapper/types';
import { sendCode, verifyCode } from './DeviceVerificationService';
import useIdentityParams from '../../common/hooks/Parameters/UseIdentityParams';
import useTracking from '../../common/hooks/UseTracking';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { asI18nKey } from '../../common/utilities/Localization';
import useAPI from '../../common/hooks/UseAPI';
import usePageAccessGuard from '../../common/hooks/UsePageAccessGuard';
import TimedResendButton from '../../components/TimedResendButton/TimedResendButton';
import { ITwoFactorResponse } from './types';
import useTrigger from '../../common/hooks/UseTrigger';
import { ServerError } from '../../common/utilities/API/types';
import { AlertType } from '../../components/AlertToast/types';
import {
    DeviceAddress,
    Instructions,
    OtherOptionsContainer
} from './DeviceVerificationPage.style';

const DeviceVerificationPage: FunctionComponent<IPage> = props => {
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
    const { navigate, redirectToReturnUrl } = useIdentityNavigation();
    const { trackEvent } = useTracking(props.trackingCategory);
    const cancelResendCooldown = useTrigger();
    const { allParams } = useIdentityParams<ITwoFactorResponse>();
    const [currentProviderData, setCurrentProviderData] = useState<ITwoFactorResponse>(allParams);
    const defaultProvider = useMemo(() => allParams.twofactormethod?.provider, [allParams]);
    const providerName = useMemo(() => currentProviderData.twofactormethod?.provider?.toString()?.toLowerCase(), [currentProviderData]);
    const api = useAPI();
    const otherProviderName = useMemo(() => {
      return currentProviderData.othertwofactormethod?.provider?.toString()?.toLowerCase()
    }, [currentProviderData]);

    usePageAccessGuard(!!allParams.signedin, {
      callback: async () => {
        await redirectToReturnUrl();
        props.disablePage();
      },
      fallbackTarget: null
    });

    usePageAccessGuard(!currentProviderData.twofactormethod?.provider || !currentProviderData.twofactormethod?.deviceAddress);

    /**
     * Send the device verification code to the user.
     */
    const resendCode = async (switchMethod: boolean = false): Promise<void> => {
        trackEvent('resend email/link', 'click');

        let methodToUse = switchMethod ? currentProviderData.othertwofactormethod?.provider : currentProviderData.twofactormethod?.provider;
        await api(() => sendCode(methodToUse !== defaultProvider ? methodToUse : undefined), {
            errorsMap: () => [
                {
                    error: ServerError.CodeIssueFailure,
                    message: props.translate(asI18nKey('device_verification.error.invalid_request'))
                }
            ],
            onSuccess: () => {
                if (switchMethod)
                    setCurrentProviderData({
                        twofactormethod: currentProviderData.othertwofactormethod,
                        othertwofactormethod: currentProviderData.twofactormethod
                    });
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);

                cancelResendCooldown.fire();
            }
        });
    }

    /**
     * Activate when the page's form is submitted.
     * This function verifies the user's code and continues with the login flow.
     *
     * @param {string} code - The user's typed code
     * @returns {Promise<boolean>} True if the submission is successful.
     */
    const onSubmit = async ({ code }: Record<string, string>): Promise<boolean> => {
        return await api(() => verifyCode(code), {
            errorsMap: params => [
                {
                    error: ServerError.CodeVerificationRejected,
                    message: props.translate(asI18nKey('device_verification.error.invalid_code'))
                },
                {
                    error: ServerError.UserLocked,
                    message: props.translate(asI18nKey('device_verification.error.user_locked'), { minutes: params?.minutesLeft || 5 }),
                    alertType: AlertType.Warning
                }
            ],
            onSuccess: async ({ status, data }) => {
                switch (status) {
                    case 200:
                        props.disablePage();
                        await redirectToReturnUrl();
                        break;

                    case 211:
                        navigate('ResetPassword', undefined, data);
                        break;

                    case 212:
                        navigate('Lockout');
                        break;
                }
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            }
        });
    }

    return (
        <>
            <Instructions>
                <I18n>
                    device_verification.subtitle.{providerName}
                </I18n>
                <DeviceAddress>
                    &nbsp;
                    {!!currentProviderData.twofactormethod?.deviceAddress ? (
                      <span>{currentProviderData.twofactormethod?.deviceAddress}</span>
                    ) : (
                        <span>
                            <I18n>device_verification.subtitle.your_device</I18n>
                        </span>
                    )}
                </DeviceAddress>
            </Instructions>
            <TextField
                name={'code'}
                type={'Text'}
                label={props.translate(asI18nKey('device_verification.field.code.label'))}
                required
                focusOnPageLoad
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={props.trackingCategory}
                onChange={setInput}
                onEnterPress={submissionTrigger.fire}
                {...(!!allParams.code ? { initialValue: allParams.code } : {})}
                automationName={'code'}
            />
            <SubmitButton
                onSuccess={onSubmit}
                formInputs={formInputs}
                onFailedVerification={inputsVerificationTrigger.fire}
                trackingCategory={props.trackingCategory}
                trackingComponentName={'continue'}
                shouldSubmit={submissionTrigger.active}
                automationName={'submit-button'}
            >
                {props.translate(asI18nKey('device_verification.button'))}
            </SubmitButton>
            <TimedResendButton
                onSendCode={() => resendCode(false)}
                confirmationMessage={props.translate(asI18nKey('device_verification.no_code.confirmation'))}
                buttonLabel={props.translate(asI18nKey(`device_verification.no_code.action.${providerName}`))}
                buttonPrefix={props.translate(asI18nKey('device_verification.no_code'))}
                timerPrefix={props.translate(asI18nKey('device_verification.no_code.cooldown.prefix'))}
                timerSuffix={props.translate(asI18nKey('device_verification.no_code.cooldown.suffix'))}
                cancelCooldown={cancelResendCooldown.active}
                cooldownTime={30}
            />
            <OtherOptionsContainer>
                {otherProviderName && (
                    <LinkParagraph
                        suffix={props.translate(asI18nKey('device_verification.method.switch'))}
                        linkLabel={props.translate(asI18nKey(`device_verification.method.${otherProviderName}`)) || ''}
                        callback={() => resendCode(true)}
                    />
                )}
              <LinkParagraph
                    prefix={props.translate(asI18nKey(otherProviderName ? 'device_verification.method.switch.support': 'device_verification.support'))}
                    linkLabel={props.translate(asI18nKey('device_verification.support.action'))}
                    callback={() => setSupportModalOpen(true)}
              />
            </OtherOptionsContainer>
            <SupportModal
                isOpen={supportModalOpen}
                onClose={() => setSupportModalOpen(false)}
            />
        </>
    );
}

export default DeviceVerificationPage;
