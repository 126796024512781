import { useContext, useMemo, useState } from 'react';
import { predefinedConfigs } from '../../../common/utilities/InputVerification';
import I18nContext from '../../../common/context/I18nContext';
import { asI18nKey } from '../../../common/utilities/Localization';

interface ITestSpecifications {
    delay?: number;
    errorMsg?: string;
    pattern?: Array<RegExp>;
}

interface IInputVerification {
    testPattern: (value: string, options?: ITestSpecifications, disableSideEffects?: boolean) => boolean;
    error: string;
    clearError: () => void;
}

/**
 * @param {RegExp | undefined} pattern - A regex pattern against which the input's value is tested
 * @param {string | undefined} customError - An error message to display if a test fails
 * @param {number | undefined} debounceDelay - The time [ms] it takes the error message to be displayed after a failed test
 * @returns {IInputVerification} {
 *      {(value: string, delay?: number, testErrorMsg?: string) => boolean} testPattern - A function that tests an input value,
 *      {string} error - An error message that should be displayed (empty if the last test succeeded),
 *      {() => void}} clearError - Clears the input's error message,
 * }
 */
const useInputVerification = (pattern?: Array<RegExp>, customError?: string, debounceDelay: number = 0): IInputVerification => {
    const { translate } = useContext(I18nContext);
    const defConfig = predefinedConfigs['NotEmpty'];
    const fallbackError = translate(defConfig.customError || asI18nKey('general.field.warning'));
    const [displayedMsg, setDisplayedMsg] = useState<string>('');
    const [debounce, setDebounce] = useState<number>();
    const currentErrorMsg = useMemo<string>(() => customError || fallbackError, [customError]);
    const currentPattern = useMemo<Array<RegExp>>(() => pattern || defConfig.pattern, [pattern]);

    /**
     * Consider the field's value as valid.
     */
    const validate = (): void => {
        setDisplayedMsg('');
        clearTimeout(debounce);
        setDebounce(undefined);
    }

    /**
     * Consider the field's value as invalid.
     *
     * @param {string} error - A relevant error message to show the user
     * @param {number} delay - Amount of time [ms] to wait before displaying the error
     */
    const invalidate = (error: string, delay: number): void => {
        clearTimeout(debounce);
        setDebounce(window.setTimeout(() => setDisplayedMsg(error), delay));
    }

    /**
     * Test an input value against the regex pattern
     * and trigger an error if it doesn't match.
     *
     * @param {string} value - An input value
     * @param {ITestSpecifications | undefined} options - Special specifications only for this test
     * @param {boolean | undefined} disableSideEffects - True to disable all manipulations to
     *                                                   the current state of the error message
     * @returns {boolean} True if the given value matches the regex pattern.
     */
    const testPattern = (value: string, options?: ITestSpecifications, disableSideEffects?: boolean): boolean => {
        let match = (options?.pattern || currentPattern).every(x => x.test(value));
        let finalError = options?.errorMsg || currentErrorMsg;
        let finalDelay = options?.delay ?? debounceDelay;

        if (!disableSideEffects) {
            if (match) validate();
            else {
                if (finalDelay > 0) setDisplayedMsg('');
                invalidate(finalError, finalDelay);
            }
        }

        return match;
    }

    return {
        testPattern,
        error: displayedMsg,
        clearError: validate
    };
}

export default useInputVerification;
