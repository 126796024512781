import fetchService from '../../common/utilities/API/FetchService';
import {IServerResponse} from '../../common/utilities/API/types';

/**
 * Log the user into the application using Single-Sign-On (SSO) service.
 *
 * @param {string} browserId - The user's unique browser ID
 * @param {string} email - The user's email
 * @param {string | undefined} hashTail - The URL's hash part
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const login = async (browserId: string, email: string, hashTail?: string | null): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/login/sso', {
        browserId,
        email,
        ...(hashTail ? {hashTail} : {})
    })
