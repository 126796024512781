import { FunctionComponent, useEffect } from 'react';
import { ITrackingTool } from '../types';
import Script from 'react-load-script'

const GTMTrackingTool: FunctionComponent<ITrackingTool> = props => {
    useEffect(() => {
        const w = window as any;
        w.dataLayer ??= [];
        w.dataLayer.push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
        });
    }, []);

    return (
        <Script
            url={`https://www.googletagmanager.com/gtm.js?id=${props.token}`}
            attributes={{ async: true }}
        />
    );
}

export default GTMTrackingTool;