import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const BoxContainer = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    & * {
        cursor: pointer;
    }
`;

export const Box = styled.input`
    --size: 15px;
  
    width: var(--size);
    height: var(--size);
`;

export const Label = styled.span`
    font-size: .8rem;
    margin-top: 2px;
    color: ${colorsPalettes.carbon[300]};
`;