import { ReactElement } from 'react';
import { AutomationTarget } from '../../common/HOC/WithAutomationTesting';
import { IVerificationConfig } from '../../common/utilities/InputVerification';

export type ValidBodyValue = string | number | boolean | null;
export type FieldVerificationConfig = Omit<IVerificationConfig, 'customError'> & { customError: string };

export interface IInput {
    name: string;
    value: ValidBodyValue;
    verified?: boolean;
}

export interface IFormField extends AutomationTarget {
    name: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    focusOnPageLoad?: boolean;
    shouldFocus?: boolean;
    verificationConfig?: FieldVerificationConfig;
    readonly?: boolean;
    initialValue?: string;
    hint?: string | ReactElement;
    size?: { width?: number, height?: number };
    verticalMargin?: { top?: number, bottom?: number };
    trackingCategory?: string;
    shouldVerify?: boolean;
    onChange?: (fieldName: string, value: ValidBodyValue, verified: boolean) => void;
    onEnterPress?: () => void;
    onKeyPress?: (key: string) => void;
    onErrorMessageChange?: (displayedMessage: string) => void;
    forcedErrorMessage?: string;
}

export enum FieldType {
    Text = 'text',
    Email = 'email',
    Password = 'password',
    Url = 'url',
    Phone = 'text'
}