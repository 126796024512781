import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../common/config/ThemeConfig';

export const OuterContainer = styled.div<{
    open: boolean;
}>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: ${props => props.open ? 'auto' : 'none'};
    opacity: ${props => props.open ? 1 : 0};
    z-index: calc(${props => props.open ? 100 : -10});
    transition: all .5s ease-in-out;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        overflow-x: auto;
        transition: none;
    }
`;

export const Background = styled.div`
    --padding: 20px;
  
    display: flex;
    width: 100%;
    min-height: calc(100% - 2 * var(--padding));
    padding: var(--padding);
    pointer-events: none;
    background-color: ${colorsPalettes.midnight[500]}cc;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        --padding: 0px;
    }
`;

export const Container = styled.div<{
    open: boolean;
    width: number;
    height: number;
}>`
    --hor-padding: 30px;
    --ver-padding: 25px;
  
    position: relative;
    width: calc(${props => props.width}px - 2 * var(--hor-padding));
    min-height: calc(${props => props.height}px - 2 * var(--ver-padding));
    margin: auto;
    padding: var(--ver-padding) var(--hor-padding);
    border-radius: 4px;
    background-color: ${colorsPalettes.carbon[0]};
    transform: translateY(-${props => props.open ? 0 : props.height * 1.2}px);
    -webkit-box-shadow: 10px 10px 12px -10px ${colorsPalettes.black[0]};
    -moz-box-shadow: 10px 10px 12px -10px ${colorsPalettes.black[0]};
    box-shadow: 10px 10px 12px -10px ${colorsPalettes.black[0]};
    transition: .5s;
  
    & * {
        pointer-events: all;
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        --mobile-padding: 30px;
      
        width: calc(100% - 2 * var(--mobile-padding));
        height: 100%;
        padding: var(--mobile-padding);
        transform: none;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: .2s;
  
    &:hover {
        filter: brightness(0%);
    }
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
`;