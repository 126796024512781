import { useEffect } from 'react';
import useServerData from './UseServerData';
import { logClientMessage, shouldLogBeIgnored } from '../utilities/Logging';

const useClientErrorLogging = (): void => {
    const { LogAllExceptions } = useServerData<{ LogAllExceptions: boolean }>();

    useEffect(() => {
        window.onerror = onGeneralError;
        window.onunhandledrejection = onGeneralUncaughtException;
    }, []);

    /**
     * Log a general client error to the server.
     * This function is a compatible handler to the event window.onerror.
     *
     * @param {string | Event} message - The error's message
     * @param {string | undefined} url - The URL at which the error occurred
     * @param {number | undefined} lineNumber - The line number at which the error occurred
     */
    const onGeneralError = (message: string | Event, url?: string, lineNumber?: number): void => {
        if (!LogAllExceptions) return;

        try {
            const msg = message?.toString();

            if (!msg && !url && !lineNumber) {
                console.trace('The function \'onGeneralError\' was called without parameters');
                return;
            }

            if (!shouldLogBeIgnored(msg)) {
                logClientMessage('Client exception', 'Error', {
                    exception: message?.toString?.() ?? '',
                    exceptionUrl: url ?? '',
                    exceptionLineNumber: lineNumber ?? -1
                });
            }
        }
        catch (ex) {}
    };

    /**
     * Log a general client error to the server.
     * This function is a compatible handler to the event window.onunhandledrejection.
     *
     * @param {PromiseRejectionEvent} ev - Default fired event
     */
    const onGeneralUncaughtException = (ev: PromiseRejectionEvent): void => {
        if (!LogAllExceptions) return;

        try {
            const {reason: {message, stack}} = ev;

            if (!message && !stack) {
                console.trace('The function \'onGeneralUncaughtException\' was called without parameters');
                return;
            }

            if (!shouldLogBeIgnored(message + stack)) {
                logClientMessage('Client exception', 'Error', {
                    exception: message,
                    callstack: stack
                });
            }
        }
        catch (ex) {}
    };
}

export default useClientErrorLogging;
