import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Label, Loader } from './SubmitButton.style';
import { IInput } from '../Fields/types';
import useTracking from '../../common/hooks/UseTracking';
import withAutomationTesting, { AutomationTarget } from '../../common/HOC/WithAutomationTesting';

interface ISubmitButton extends AutomationTarget {
    onSuccess: (inputs: Record<string, string>) => Promise<boolean | void> | boolean | void;
    trackingCategory?: string;
    trackingComponentName?: string;
    formInputs?: Array<IInput>;
    beforeSubmit?: (inputs: Record<string, string>) => boolean;
    onFailedVerification?: () => void;
    widthPercent?: number;
    shouldSubmit?: boolean;
    disabled?: boolean;
}

const SubmitButton: FunctionComponent<ISubmitButton> = props => {
    const ref = useRef<HTMLButtonElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { trackEvent } = useTracking(props.trackingCategory);

    useEffect(() => {
        props.shouldSubmit && onSubmit();
    }, [props.shouldSubmit]);

    /**
     * @returns {Record<string, string>} An object consisting of the form's inputs.
     */
    const composeInputsObject = (): Record<string, string> => {
        let inputs: Record<string, string> = {};
        props.formInputs?.forEach(x => { inputs[x.name] = (x.value || '').toString(); })
        return inputs;
    }

    /**
     * Activate when the button is clicked to activate its action.
     */
    const onSubmit = async (): Promise<void> => {
        let finalInputs = composeInputsObject();
        let inputsVerified = !props.formInputs || props.formInputs.every(x => !!x.verified);
        let canBeSubmitted = !props.beforeSubmit || props.beforeSubmit(finalInputs);

        setLoading(true);

        if (!inputsVerified || !canBeSubmitted) props.onFailedVerification?.();
        else {
            try {
                let success = await props.onSuccess(finalInputs);
                trackSubmissionEvent((success !== false) ? 'submit-ok' : 'submit-server-error');
            }
            catch (ex) {
                trackSubmissionEvent('submit-client-error');
                console.error(ex);
            }
        }

        if (ref?.current) setLoading(false);
    }

    /**
     * Track the event of clicking the submission button.
     *
     * @param {string} action - The event's action name
     */
    const trackSubmissionEvent = (action: string): void => {
        trackEvent(props.trackingComponentName || '', action);
    }

    return (
        <Button
            ref={ref}
            className={'accessible'}
            onClick={onSubmit}
            isLoading={loading}
            disabled={props.disabled || loading}
            width={props.widthPercent}
            { ...props.automationElementAttr }
        >
            <Loader isLoading={loading} />
            <Label>{props.children}</Label>
        </Button>
    );
}

export default withAutomationTesting(SubmitButton);