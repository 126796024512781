import { FunctionComponent, ReactElement } from 'react';
import { IPage } from '../../components/PageWrapper/types';
import { useParams } from 'react-router-dom';
import usePageAccessGuard from '../../common/hooks/UsePageAccessGuard';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import InternalTwoFactorAuthenticationTotpMethod from './Methods/InternalTwoFactorAuthenticationTotpMethod';
import InternalTwoFactorAuthenticationPushMethod from './Methods/InternalTwoFactorAuthenticationPushMethod';
import InternalTwoFactorAuthenticationSmsMethod from './Methods/InternalTwoFactorAuthenticationSmsMethod';
import useIdentityParams from '../../common/hooks/Parameters/UseIdentityParams';
import { asI18nKey } from '../../common/utilities/Localization';
import useIdentityNavigation from "../../common/hooks/UseIdentityNavigation";
import {
    IInternalTwoFactorAuthenticator,
    InternalTwoFactorType,
} from './types';

const InternalTwoFactorMethodPage: FunctionComponent<IPage> = props => {
    const { navigate } = useIdentityNavigation();
    const { method } = useParams<string>();
    const { redirectToUrl } = useIdentityNavigation();
    const parsedMethod: keyof typeof InternalTwoFactorType = Object.values(InternalTwoFactorType).find(x => {
        let aCaseInsensitive = x.toString().toLowerCase();
        let bCaseInsensitive = method?.toLowerCase() || '';
        return aCaseInsensitive === bCaseInsensitive;
    }) as keyof typeof InternalTwoFactorType;

    const { routerParams, queryParams } = useIdentityParams<{
        authenticator: IInternalTwoFactorAuthenticator;
        callback: string;
    }>();

    const abortController = new AbortController();

    usePageAccessGuard(!parsedMethod || !routerParams.authenticator || !queryParams.callback);

    /**
     * redirect to the callback endpoint given in the URL.
     */
    const redirectToVerificationCallBack = async (): Promise<void> => {
        await redirectToUrl(queryParams.callback);
    }

    /**
     * Navigate back to the method selection screen.
     */
    const goBack = () => {
        abortController?.abort();
        navigate('InternalTwoFactor', { callback: queryParams.callback }, { skipAutoSelection: true });
    };

    const screenProps = { ...props, onVerified: redirectToVerificationCallBack, abortSignal: abortController.signal };
    const screens: Record<keyof typeof InternalTwoFactorType, ReactElement> = {
        'TOTP': <InternalTwoFactorAuthenticationTotpMethod {...screenProps} />,
        'Push': <InternalTwoFactorAuthenticationPushMethod {...screenProps} />,
        'SMS': <InternalTwoFactorAuthenticationSmsMethod {...screenProps} />
    }

    return (
        <>
            {screens?.[parsedMethod] || <></>}
            <LinkParagraph
                linkLabel={props.translate(asI18nKey('2fa.back'))}
                callback={goBack}
            />
        </>
    );
}

export default InternalTwoFactorMethodPage;
