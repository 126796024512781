import styled from "styled-components";

export const Card = styled.div<{
    visible: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background-color: ${props => props.visible ? '#ffffff99' : 'transparent'};
    pointer-events: ${props => props.visible ? 'all' : 'none'};
    cursor: progress;
    z-index: 70;
    transition: .7s;
`;