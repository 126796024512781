import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    open: boolean;
}>`
    --opacity: ${props => props.open ? 1 : 0};
    --border-color: ${props => props.open ? colorsPalettes.navigation['ICON_BAR_BACKGROUND'] : 'transparent'};
    --dropdown-overflow-y: ${props => props.open ? 'auto' : 'hidden'};

    width: 112px;
`;

export const SelectedLangContainer = styled.div`
    display: flex;
    align-items: flex-end;
    height: 50px;
    pointer-events: all;
    border-left: 1px var(--border-color) solid;
    border-right: 1px var(--border-color) solid;
    border-top: 1px var(--border-color) solid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: .2s;
`;

export const DelimiterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 20px;
    border-left: 1px var(--border-color) solid;
    border-right: 1px var(--border-color) solid;
    opacity: var(--opacity);
    transition: .4s;
`;

export const Delimiter = styled.hr`
    position: absolute;
    width: 85%;
    color: gray;
    border-top: 1px var(--border-color) solid;
    border-left: none;
    border-right: none;
    border-bottom: none;
`;

export const DropdownContainer = styled.div<{
    open: boolean;
    amount: number;
    displayedAmount: number;
}>`
    position: absolute;
    width: calc(112px - 2px);
    max-height: 0;
    border-left: 1px var(--border-color) solid;
    border-right: 1px var(--border-color) solid;
    border-bottom: 1px var(--border-color) solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: var(--opacity);
    overflow: hidden;
    overflow-y: ${props => props.open ? 'auto' : 'auto'};
    transition: .4s;
    
    ${props => props.open && `
        height: calc(40px * ${props.amount});
        max-height: calc(40px * ${Math.min(props.displayedAmount, props.amount)});
    `}
`;