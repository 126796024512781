import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Title = styled.p`
    margin-bottom: 5px;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 600;
    color: ${colorsPalettes.carbon[400]};
`;

export const Info = styled.p`
    margin: 0 auto 25px;
    font-size: .875rem;
    font-weight: 400;
    color: ${colorsPalettes.carbon[400]};
`;

export const SuggestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px auto 10px;
    font-size: .825rem;
    
    & > * {
        margin: 5px auto;
    }
`;

export const SupportSuggestionContainer = styled.div`
    margin-top: 25px;
`;