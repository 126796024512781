import AlertToast from '../AlertToast/AlertToast';
import { FunctionComponent, useMemo } from 'react';
import { IAlert } from '../AlertToast/types';
import withAutomationTesting, { AutomationTarget } from '../../common/HOC/WithAutomationTesting';

interface IErrorAlertToast extends AutomationTarget {
    show: boolean;
    alert?: IAlert;
}

const ErrorAlertToast: FunctionComponent<IErrorAlertToast> = props => {
    return (
        <AlertToast
            isVisible={props.show}
            alert={props.alert}
        />
    );
}

export default withAutomationTesting(ErrorAlertToast);