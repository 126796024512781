import GoogleIco from '../../components/SocialAuthSection/images/google.svg';
import LinkedInIco from '../../components/SocialAuthSection/images/linkedin.svg';

interface ISocialAuthConfig {
    provider: string;
    icon: string;
}

const socialAuthConfig: Array<ISocialAuthConfig> = [
    {
        provider: 'Google',
        icon: GoogleIco,
    },
    {
        provider: 'LinkedIn',
        icon: LinkedInIco,
    }
];

export default socialAuthConfig;