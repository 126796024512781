import { FunctionComponent, useState } from 'react';
import { IPage } from '../../components/PageWrapper/types';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import { asI18nKey } from '../../common/utilities/Localization';
import SupportModal from '../../components/SupportModal/SupportModal';
import I18n from '../../components/I18n/I18n';
import { Info, OtherOptions } from './BlockedPage.style';

const LockoutPage: FunctionComponent<IPage> = props => {
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);

    return (
        <>
            <Info>
                <I18n>blocked.subtitle</I18n>
            </Info>
            <OtherOptions>
                <LinkParagraph
                    prefix={props.translate(asI18nKey('blocked.support.prefix'))}
                    linkLabel={props.translate(asI18nKey('blocked.support.action'))}
                    callback={() => setSupportModalOpen(true)}
                />
                <LinkParagraph
                    prefix={props.translate(asI18nKey('blocked.login'))}
                    linkLabel={props.translate(asI18nKey('blocked.login.action'))}
                    targetPage={'Login'}
                />
            </OtherOptions>
            <SupportModal
                isOpen={supportModalOpen}
                onClose={() => setSupportModalOpen(false)}
            />
        </>
    );
}

export default LockoutPage;