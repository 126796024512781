import { FunctionComponent } from 'react';
import { Info, Instructions } from './ForgotPasswordConfirmationPage.style';
import { IPage } from '../../components/PageWrapper/types';
import I18n from '../../components/I18n/I18n';
import useIdentityParams from '../../common/hooks/Parameters/UseIdentityParams';
import usePageAccessGuard from '../../common/hooks/UsePageAccessGuard';

const ForgotPasswordConfirmationPage: FunctionComponent<IPage> = () => {
    const { routerParams } = useIdentityParams<{ email: string }>();

    usePageAccessGuard(!routerParams.email);

    return (
        <>
            <Info>
                <I18n params={{ email: routerParams.email || '' }}>
                    forgot_password.confirm.subtitle
                </I18n>
            </Info>
            <Instructions>
                <I18n>forgot_password.confirm.no_email</I18n>
                <br />
                <I18n>forgot_password.confirm.no_email.tip</I18n>
            </Instructions>
        </>
    );
}

export default ForgotPasswordConfirmationPage;