import { FunctionComponent, useEffect, useRef } from 'react';
import { Card } from './PageMask.style';

interface IPageMask {
    enabled: boolean;
}

const PageMask: FunctionComponent<IPageMask> = props => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => { props.enabled && ref?.current?.focus(); }, [props.enabled, ref]);

    return (
        <Card
            ref={ref}
            visible={props.enabled}
            {...(props.enabled ? { tabIndex: 0 } : {})}
        />
    );
}

export default PageMask;