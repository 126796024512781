import { FieldVerificationConfig, IFormField } from './types';
import useInputVerification from './hooks/UseInputVerification';
import { predefinedConfigs } from '../../common/utilities/InputVerification';
import FieldHint from './FieldHint/FieldHint';
import I18nContext from '../../common/context/I18nContext';
import useTracking from '../../common/hooks/UseTracking';
import { asI18nKey } from '../../common/utilities/Localization';
import withAutomationTesting from '../../common/HOC/WithAutomationTesting';
import {
    Children,
    cloneElement,
    FunctionComponent,
    isValidElement,
    RefObject,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import {
    ChildContainer,
    Container,
    ErrorContainer,
    ErrorLabel,
    FieldContainer,
    Label,
    LabelContainer,
    RequiredAsterisk
} from './FormField.style';

interface ITrackableFormField {
    input: string;
    inputRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
}

const FormField: FunctionComponent<IFormField & ITrackableFormField> = props => {
    const { translate } = useContext(I18nContext);
    const errorContainerRef = useRef<HTMLDivElement>(null);
    const [hintOpen, setHintOpen] = useState<boolean>(false);
    const verConfig = useMemo<FieldVerificationConfig>(() => {
        const predefined = predefinedConfigs[props.required ? 'NotEmpty' : 'Any'];

        return props.verificationConfig || {
            ...predefined,
            customError: translate(predefined.customError || asI18nKey())
        };
    }, [props.verificationConfig]);

    const { testPattern, error, clearError } = useInputVerification(verConfig.pattern, verConfig.customError, 1500);
    const { trackEvent } = useTracking(props.trackingCategory);
    const requiredErrorMessage = translate(predefinedConfigs.NotEmpty.customError || asI18nKey());
    const errorMessage = useMemo<string>(() => props.forcedErrorMessage || error, [error, props.forcedErrorMessage]);

    //handle input change
    useEffect(() => {
        reverifyInput();
        if (!props.input) clearError();
    }, [props.input]);

    //handle pattern change
    useEffect(() => {
        reverifyInput();
    }, [JSON.stringify(verConfig.pattern.map(x => x.toString()))]);

    //force verification
    useEffect(() => {
        if (props.shouldVerify) {
            let message = (props.required && !props.input) ? requiredErrorMessage : '';
            let valid = testPattern(props.input, { delay: 0, errorMsg: message });
            trackValidation(valid);
        }
    }, [props.shouldVerify]);

    useEffect(() => props.onErrorMessageChange?.(errorMessage), [errorMessage]);
    useEffect(() => { props.focusOnPageLoad && focus(); }, [props.inputRef]);
    useEffect(() => { props.shouldFocus && focus(); }, [props.inputRef, props.shouldFocus]);

    const reverifyInput = () => {
        let match = testPattern(props.input, undefined, !props.input);
        props.onChange?.(props.name, props.input, match);
    }

    const onBlur = () => {
        let match = testPattern(props.input, undefined, !props.input);
        if (!!props.input) trackValidation(match);
    }

    /**
     * Focus on the field's input element.
     */
    const focus = (): void => props.inputRef?.current?.focus();

    /**
     * Track a field's validation.
     *
     * @param {boolean} isValid - True if the field is valid
     */
    const trackValidation = (isValid: boolean) => {
        trackEvent(props.name, `value-${isValid ? 'ok' : 'error'}`);
    }

    return (
        <Container
            marginTop={(props.verticalMargin?.top !== undefined) ? props.verticalMargin.top : 40}
            marginBottom={(props.verticalMargin?.bottom !== undefined) ? props.verticalMargin.bottom : 40}
            width={props.size?.width}
            errorDisplayed={!!errorMessage}
            {...(!!props.hint ? {
                onFocus: () => setHintOpen(true),
                onMouseEnter: () => setHintOpen(true),
                onMouseLeave: () => document.activeElement !== props.inputRef?.current && setHintOpen(false),
                onBlur: () => setHintOpen(false)
            } : {
                onBlur: onBlur
            })}
        >
            {!!props.label && (
                <LabelContainer>
                    <Label>{props.label}</Label>
                    {props.required && <RequiredAsterisk />}
                </LabelContainer>
            )}
            <FieldContainer>
                <ErrorContainer
                    ref={errorContainerRef}
                    displayed={!!errorMessage}
                    fieldHeight={props.size?.height || 35}
                >
                    <ErrorLabel>{errorMessage}</ErrorLabel>
                </ErrorContainer>
                <ChildContainer>
                    {Children.map(props.children, child => (
                        isValidElement(child)
                            ? cloneElement(child, { ...props.automationElementAttr })
                            : child
                    ))}
                </ChildContainer>
                <FieldHint
                    open={!!props.hint && hintOpen}
                    content={props.hint ?? ''}
                />
            </FieldContainer>
        </Container>
    );
}

export default withAutomationTesting(FormField);
