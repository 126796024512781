import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    align: string;
}>`
    font-size: .75rem;
    line-height: 1.5;
    color: ${colorsPalettes.carbon[400]};
    text-align: ${props => props.align};
  
    & p {
        margin: 0;
    }
`;

export const LegalLink = styled.a`
    font-weight: 600;
    color: ${colorsPalettes.carbon[400]};
`;