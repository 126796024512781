import {FunctionComponent} from "react";
import {IPage} from "../../components/PageWrapper/types";
import {Link, MailButton, MailButtonContainer, MailButtonWrapper, DeviceVerificationPageContainer, Phrase, SubTitle} from "./DeviceVerificationPage.style";
import I18n from "../../components/I18n/I18n";
import gmail from './images/gmail.svg';
import outlook from './images/outlook.svg';
import useIdentityParams from "../../common/hooks/Parameters/UseIdentityParams";
import {asI18nKey} from "../../common/utilities/Localization";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
const EmailSentPage: FunctionComponent<IPage> = ({translate}) => {
    const { routerParams, allParams } = useIdentityParams<{
        email: string;
    }>();

    usePageAccessGuard(!routerParams.email);

    return <DeviceVerificationPageContainer>
        <SubTitle>
            <I18n params={{ email: allParams.email }}>device_verification.email.sub_title</I18n>
        </SubTitle>
        <MailButtonContainer>
            <MailButton href={'https://gmail.com'} target="_blank">
                <MailButtonWrapper>
                    <img src={gmail} />
                    <span>Open Gmail</span>
                </MailButtonWrapper>
            </MailButton>
            <MailButton href={'https://outlook.com'} target="_blank">
                <MailButtonWrapper>
                    <img src={outlook} />
                    <span>Open Outlook</span>
                </MailButtonWrapper>
            </MailButton>
        </MailButtonContainer>
        <Phrase>
            <I18n>device_verification.email.spam_mail_text</I18n>
        </Phrase>
        <Phrase>
            <I18n>device_verification.general.help_text</I18n>
            <Link href={translate(asI18nKey('device_verification.general.help_link'))}>
                <I18n>device_verification.general.help_link_text</I18n>
            </Link>
        </Phrase>
    </DeviceVerificationPageContainer>
}

export default EmailSentPage;
