import { AlertType } from '../../components/AlertToast/types';

export interface IInternalTwoFactorMethod {
    onServerAlert: (message: string, type?: AlertType) => void;
    onUnexpectedError: (modalPersistent?: boolean) => void;
    onVerified: () => void;
    abortSignal?: AbortSignal | undefined;
}

export enum InternalTwoFactorType {
    SMS,
    TOTP,
    Push
}

export enum TwoFactorAuthenticationIssueResult {
    Unknown,
    Error,
    Unsupported,
    Issued
}

export enum TwoFactorAuthenticationVerifyResult {
    Unknown,
    Error,
    TimedOut,
    Failed,
    Waiting,
    Accepted,
    Rejected
}

export interface IInternalTwoFactorAuthenticator {
    provider: string;
    vendor: string;
    type: string;
    isIssueRequiredBeforeVerify: boolean;
    settingsLink?: string,
    extras?: Record<string, any>
}

export interface IInternalTwoFactorIssueResponse {
    authenticator: IInternalTwoFactorAuthenticator,
    result: TwoFactorAuthenticationIssueResult,
    transactionId: string,
    token?: string,
    extras?: Record<string, any>
}

export interface IInternalTwoFactorVerifyResponse {
    authenticator: IInternalTwoFactorAuthenticator,
    result: TwoFactorAuthenticationVerifyResult,
    transactionId: string,
    token?: string,
    extras?: Record<string, any>
}