import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Info = styled.p`
    font-size: .875rem;
    color: ${colorsPalettes.carbon[400]};
    width: 60%;
    margin: auto;
`;

export const OtherOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    & > div {
        margin-left: 0;
        margin-right: 0;
    }
`;