import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextLocizeBackend from 'i18next-locize-backend';
import { logClientMessage } from '../utilities/Logging';

const w = window as any;
const path = !!w.swData?.I18n && `${w.swData?.I18n}/i18n/`;
const env = w.swData?.DeployEnvironment;
export const fallbackLocale = 'en';

if (path && env) {
    i18next
    .use(initReactI18next)
    .use(I18NextLocizeBackend)
    .init({
        ns: ['translation'],
        debug: false,
        fallbackLng: fallbackLocale,
        fallbackNS: 'translation',
        returnEmptyString: false,
        parseMissingKeyHandler: (key, def) => def ?? `[${key}]`,
        backend: {
            loadPath: `${path}{{projectId}}/{{version}}/{{lng}}/{{ns}}`,
            privatePath: `${path}private/{{projectId}}/{{version}}/{{lng}}/{{ns}}`,
            addPath: `${path}missing/{{projectId}}/{{version}}/{{lng}}/{{ns}}`,
            updatePath: `${path}update/{{projectId}}/{{version}}/{{lng}}/{{ns}}`,
            projectId: 'secure_similarweb',
            referenceLng: fallbackLocale,
            version: env
        },
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false
        }
    });
}
else {
    logClientMessage('i18n error', 'Error', {
        'swData.I18n': w.swData?.I18n,
        'swData.DeployEnvironment': w.swData?.DeployEnvironment,
        path,
        env
    }, true);
}
