import { IErrorConfig } from './types';
import { asI18nKey } from '../../common/utilities/Localization';

export const error404Config: IErrorConfig = {
    errorCode: 'GN0011',
    message: asI18nKey('error_page.error_code_info.404')
}

const errorsConfig: Array<IErrorConfig> = [
    {
        //internal server error
        errorCode: 'GN0001',
        message: asI18nKey('error_page.server_error')
    },
    {
        //invalid request to reset password
        errorCode: 'PS0001',
        message: asI18nKey('reset_password.error.invalid_request')
    },
    {
        //missing external login email (SSO/social callback)
        errorCode: 'LG0001',
        message: asI18nKey('login_external.error.email_missing')
    },
    {
        //unknown SSO scheme (SSO callback)
        errorCode: 'LG0011',
        message: asI18nKey('login_sso.error.unknown_scheme')
    },
    {
        //invalid SSO email (SSO callback)
        errorCode: 'LG0021',
        message: asI18nKey('login_sso.error.email_mismatch')
    },
    {
        //IP not registered in restrictions
        errorCode: 'LG0030',
        message: asI18nKey('login.error.ip_not_allowed.title'),
        info: asI18nKey('login.error.ip_not_allowed.info')
    },
    {
        //social login is not allowed
        errorCode: 'LG0031',
        message: asI18nKey('login.error.social_login_method_not_allowed')
    },
    {
        //only SSO login method is allowed
        errorCode: 'LG0032',
        message: asI18nKey('login.error.only_sso_login_method_is_allowed')
    },
    {
        //access denied to external login
        errorCode: 'LG0041',
        message: asI18nKey('external_login.error.invalid_request')
    },
    {
        //invalid SSO provider
        errorCode: 'LG0051',
        message: asI18nKey('login_sso.error.invalid_provider')
    },
    {
        //user may not use SSO
        errorCode: 'LG0061',
        message: asI18nKey('login_sso.error.no_sso_user')
    },
    {
        //auto-provisioning disabled
        errorCode: 'LG0081',
        message: asI18nKey('login_sso.error.auto_provisioning_disabled')
    }
];

export default errorsConfig;
