import fetchService from "./API/FetchService";
import { IUrlVerificationResponse } from "./types";

interface IUrlData {
    isAbsolute: boolean;
    isValid: boolean;
    protocol: string;
    host: string;
    path: string;
    query: string;
    fragment: string
}

/**
 * Verifies returnUrl.
 *
 * @param {string | undefined} url - url to verify
 * @returns {string | undefined} decoded url.
 */
export const decodeUrlIfNecessary = (url: string | undefined): string | undefined => {
    if (!url)
        return undefined;

    const queryIndex = url.indexOf('?');
    return queryIndex == -1
        ? decodeURIComponent(url)
        : url;
}

/**
 * Fully decode a URI component, no matter how many times it had been encoded before.
 *
 * @param {string} uriComponent - The URI component to decode
 * @returns {string} A fully decoded URI component.
 */
export const fullyDecodeURIComponent = (uriComponent: string | undefined): string | undefined => {
    if (!uriComponent)
        return undefined;

    let prevVal = '';
    let newVal = uriComponent;

    try {
        do {
            prevVal = newVal;
            newVal = decodeURIComponent(prevVal);
        }
        while (newVal !== prevVal);
    }
    catch (ex) {
    }

    return newVal;
}

/**
 * Clean all empty parameters from a URL's query string.
 *
 * @param {string} uriComponent - The URL to clean
 * @returns {string} A URL with no empty parameters.
 */
export const cleanEmptyParams = (uriComponent: string | undefined): string | undefined => {
    if (!uriComponent)
        return undefined;

    let urlParts = uriComponent?.split?.('?');
    let query = urlParts?.[1];
    if (!query)
        return uriComponent;
    if (query.indexOf('=?') == -1)
        return uriComponent;

    let oldParams = new URLSearchParams(query);
    let newParams = new URLSearchParams();

    for (let [key, value] of oldParams) {
        if (!!value) newParams.append(key, value);
    }

    return `${urlParts[0]}?${newParams.toString()}`;
}

/**
 * Clean any XSS (Cross-Site-Scripting) triggers from a string.
 *
 * @param {string} uriComponent - The string to clean
 * @returns {string} A XSS-clean string.
 */
export const containsXSSTriggers = (uriComponent: string | undefined): boolean => {
    if (!uriComponent)
        return false;

    //build a regex that will help remove all forbidden values from the string
    const forbiddenUrlStrings = ['javascript:'];
    let forbiddenStringsSection = forbiddenUrlStrings.reduce((acc, curr) => {
        let prefix = acc ? '|' : '';
        return `${acc}${prefix}(${curr})`;
    }, '');

    let forbiddenUrlRegex = new RegExp(`(${forbiddenStringsSection})[^&]*`, 'ig');
    let decodedValue = fullyDecodeURIComponent(uriComponent);
    return decodedValue ? forbiddenUrlRegex.test(decodedValue) : false;
}

/**
 * Clean any XSS (Cross-Site-Scripting) triggers from a URL's query parameters.
 *
 * @param {URLSearchParams} query - The query parameters to clean
 * @returns {URLSearchParams} A XSS-clean query parameters.
 */
export const containsQueryXSSTriggers = (query: URLSearchParams): boolean => {
    for (let [_, value] of query) {
        if (containsXSSTriggers(value))
            return true;
    }

    return false;
}

/**
 * Construct a URL string based on specifications.
 *
 * @param {IUrlData} urlData - The URL specifications
 * @param {boolean} forceAsAbsolute - True to force an absolute URL as the result
 * @returns {string} A complete URL string, or a basic '/' if the given specifications are not valid.
 */
export const constructUrl = (urlData: IUrlData, forceAsAbsolute: boolean = false): string => {
    if (!urlData.isValid)
        return '/';
    let {protocol, host, path, query, isAbsolute, fragment} = urlData;
    let absolutePrefix = (forceAsAbsolute || isAbsolute) ? `${protocol}//${host}` : '';
    let pathPrefix = (urlData.path?.[0] === '/') ? '' : '/';
    return `${absolutePrefix}${pathPrefix}${path}${query}${fragment}`;
}

/**
 * @param {string} url - A URL to deconstruct
 * @returns {IUrlData} Data regarding the different parts of the URL.
 */
export const deconstructUrl = (url: string): IUrlData => {
    let isAbsolute = /^(?:[a-zA-Z]+:)?\/\//.test(url);
    let isRelative = /^(\/).+$/.test(url);
    let res: IUrlData = {
        isAbsolute,
        isValid: isAbsolute || isRelative,
        protocol: '',
        host: '',
        path: '',
        query: '',
        fragment: ''
    };

    try {
        if (isAbsolute) {
            let urlObj = new URL(url);
            res.protocol = urlObj.protocol;
            res.host = urlObj.host;
            res.path = urlObj.pathname;
            res.query = urlObj.search;
            res.fragment = urlObj.hash;
        }
        else if (isRelative) {
            let querySplit = url.split('?');
            res.protocol = window.location.protocol;
            res.host = window.location.host;
            res.path = querySplit[0];
            res.query = querySplit?.[1] ? `?${querySplit[1]}` : '';
            const fragmentIndex = res.query?.indexOf('#') ?? -1;
            if (fragmentIndex > -1) {
                res.fragment = res.query.substring(fragmentIndex);
                res.query = res.query.substring(0, fragmentIndex);
            }
        }
    }
    catch (_) {
        res.isValid = false;
    }

    return res;
}

/**
 * Create a query string, made out of the current location's search string and additional optional parameters.
 *
 * @param {Record<string, string | number | boolean> | undefined} params - Any parameters to compose into the query string
 * @param {URLSearchParams | undefined} initialQuery - An initial query string into which to append the parameters
 * @param {boolean} skipEmptyString
 * @returns {string} A query string, consisting of the current URL's query and any given additional parameters.
 */
export const composeQueryString = (params?: Record<string, string | number | boolean>, initialQuery?: URLSearchParams, skipEmptyString: boolean = false): string => {
    const resParams = initialQuery || new URLSearchParams();

    // append all additional parameters to the initial query
    params && Object.entries(params).forEach(([key, value]) => {
        const stringValue = value?.toString?.();
        if (!stringValue && skipEmptyString) {
            if (resParams.has(key))
                resParams.delete(key);
        } else {
            resParams.set(key, stringValue);
        }
    });

    return containsQueryXSSTriggers(resParams)
        ? ''
        : `?${resParams.toString?.()}`;
}

/**
 * Append multiple URLSearchParams objects.
 * If two or more object contain the same key - the latter is applied.
 *
 * @param {Array<URLSearchParams>} params - An array of URLSearchParams to append
 * @returns {URLSearchParams} A single URLSearchParams object consisting of all search data in the given array.
 */
export const appendQueryParams = (params: Array<URLSearchParams>): URLSearchParams => {
    let res = new URLSearchParams();

    params.reverse().forEach(searchParams => {
        for (let [key, value] of searchParams.entries()) {
            if (!res.has(key)) res.append(key, value);
        }
    });

    return res;
}

/**
 * Verify that a returnUrl value is an internal Similarweb URL.
 *
 * @param {string} url - The URL to verify
 * @returns {Promise<boolean>} True if the given returnUrl is an internal Similarweb URL.
 */
export const verifyReturnUrl = async (url: string | undefined): Promise<boolean> => {
    const res = await fetchService.post<IUrlVerificationResponse>('/utilities/url/verify', {
        url: decodeUrlIfNecessary(url)
    });

    return !!res?.result.data?.verified;
}

