type Literal = string;
export type I18nKey = `[${Literal}]`;

/**
 *
 * @param literal - An i18n key template (e.g. 'example_section.example_sub_section.value')
 * @returns {I18nKey} A valid i18n key.
 */
export const asI18nKey = (literal?: string): I18nKey => {
    literal ??= '';
    let isKey = /^\[.*]$/.test(literal);
    return isKey ? literal as I18nKey : `[${literal}]`;
}

/**
 * @param {I18nKey} key - A valid i18n key
 * @returns {string} An i18n key template (e.g. 'example_section.example_sub_section.value').
 */
export const asLiteralString = (key: I18nKey): string =>
    key.replace(/[\[\]]/g, '');
