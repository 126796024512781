import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const SearchContainer = styled.div`
    position: relative;
    display: flex;
    height: 30px;
`;

export const MagnifyingGlassIcon = styled.img`
    position: absolute;
    top: calc(50% + 2px);
    left: 7px;
    width: 12px;
    transform: translateY(-50%);
    pointer-events: none;
`;

export const ClearIcon = styled.img`
    position: absolute;
    top: calc(50% + 2px);
    right: 7px;
    width: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: .2s;
  
    &:hover {
        filter: brightness(0%);
    }
`;

export const SearchBar = styled.input`
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 10px;
    outline: none;
    border: 1px solid ${colorsPalettes.sky[500]};

    &::placeholder {
      color: ${colorsPalettes.carbon[250]};
    }
`;