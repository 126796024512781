import { FunctionComponent, ReactElement } from 'react';
import { Content, Tooltip, Container, TooltipTail } from './FieldHint.style';

interface IFieldHint {
    open: boolean;
    content: string | ReactElement;
}

const FieldHint: FunctionComponent<IFieldHint> = props => {
    return (
        <Container displayed={props.open}>
            <Tooltip>
                <Content>{props.content}</Content>
            </Tooltip>
            <TooltipTail />
        </Container>
    );
}

export default FieldHint;