import { createElement, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './PageWrapper.style';
import { IPageConfig } from '../../common/config/PagesConfig';
import ErrorModal from '../ErrorModal/ErrorModal';
import I18nContext from '../../common/context/I18nContext';
import useTracking from '../../common/hooks/UseTracking';
import useCookie from '../../common/hooks/UseCookie';
import useClientErrorLogging from '../../common/hooks/UseClientErrorLogging';
import { AlertType, IAlert } from '../AlertToast/types';
import { asI18nKey } from '../../common/utilities/Localization';
import { pageTemplates } from '../PageTemplates/types';
import useUser from "../../common/hooks/UseUser";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
import useIdentityNavigation from "../../common/hooks/UseIdentityNavigation";

interface IPageWrapper {
    page: IPageConfig;
    props?: any;
}

const PageWrapper: FunctionComponent<IPageWrapper> = props => {
    const { translate } = useContext(I18nContext);
    const [serverAlert, setServerAlert] = useState<IAlert | undefined>();
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
    const [errorModalPersistent, setErrorModalPersistent] = useState<boolean>(true);
    const [pageDisabled, setPageDisabled] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(translate(asI18nKey(props.page.title)));
    const { trackPageView } = useTracking();
    const [localeCookie] = useCookie('locale', 'en-us');
    const { redirectToReturnUrl } = useIdentityNavigation();
    const { isAuthenticated } = useUser();
    const guardPageAccess = usePageAccessGuard(false, { useManually: true });
    const tabTitle = useMemo<string>(() => {
        let currTitle = document.title;
        const separator = ' - ';
        let titleInitiated = currTitle.includes(separator);
        let titleBase = titleInitiated ? currTitle.split(separator)[1] : currTitle;
        return `${title}${separator}${titleBase}`;
    }, [title, localeCookie]);

    useClientErrorLogging();
    useEffect(() => { document.title = tabTitle; }, [tabTitle]);
    useEffect(() => {
        setServerAlert(undefined);
        setTitle(translate(asI18nKey(props.page.title)));
        trackPageView(props.page.trackingPageName);

        //guard page access
        let failedUnauthenticatedState = props.page.authenticationState === 'Unauthenticated' && isAuthenticated;
        let failedAuthenticatedState = props.page.authenticationState === 'Authenticated' && !isAuthenticated;

        if (failedAuthenticatedState) guardPageAccess();
        else if (failedUnauthenticatedState) guardPageAccess({
            callback: () => {
                redirectToReturnUrl();
                disablePage();
            },
            fallbackTarget: null
        });
    }, [props.page]);

    /**
     * Pop the general error modal.
     *
     * @param {boolean} persistent - True to prevent the user from dismissing the modal
     *                               (either by pressing Escape nor by clicking any button)
     */
    const openErrorModal = (persistent: boolean = false): void => {
        setErrorModalOpen(true);
        setErrorModalPersistent(persistent);
    }

    /**
     * Display an alert to the user.
     *
     * @param {string} message - An informative error message
     * @param {keyof typeof AlertType} type - The alert's type
     */
    const displayServerAlert = (message: string, type: AlertType = AlertType.Error): void => {
        setServerAlert({ type, text: message });
    }

    /**
     * Disable the page's functionality.
     *
     * @param {boolean} disable - True to disable the page or false to enable it once again.
     */
    const disablePage = (disable: boolean = true): void => setPageDisabled(disable);

    return (
        <Wrapper tabIndex={0}>
            {createElement(
                pageTemplates[props.page.template],
                {
                    title: title,
                    showFooter: !!props.page.showFooter,
                    headerImg: props.page.headerImage,
                    pageDisabled,
                    serverAlert,
                    footer: props.page.footer,
                    maxContentWidth: props.page.maxContentWidth,
                    headerImageHeight: props.page.headerImageHeight,
                },
                <>
                    {createElement(props.page.component, {
                        trackingCategory: props.page.trackingCategory,
                        onServerAlert: displayServerAlert,
                        onUnexpectedError: openErrorModal,
                        translate,
                        setTitle,
                        disablePage,
                        ...props.props
                    })}
                    <ErrorModal
                        isOpen={errorModalOpen}
                        onClose={() => setErrorModalOpen(false)}
                        persistent={errorModalPersistent}
                    />
                </>
            )}
        </Wrapper>
    );
}

export default PageWrapper;
