import { useNavigate } from 'react-router-dom';
import { getPath } from '../utilities/Navigation';
import { homepage, IdentityPage } from '../config/PagesConfig';
import useStickyQuery from './Parameters/UseStickyQuery';
import useReturnUrl from './Parameters/UseReturnUrl';
import { containsXSSTriggers, composeQueryString, verifyReturnUrl, decodeUrlIfNecessary } from '../utilities/URL';

type Navigate = (
    path?: string | keyof typeof IdentityPage,
    query?: Record<string, string | number | boolean>,
    state?: unknown,
    pathDirectories?: Record<string, string>
) => void;

interface IIdentityNavigation {
    navigate: Navigate;
    redirectToUrl: (url: string) => Promise<void>;
    redirectToReturnUrl: () => Promise<void>;
}

/**
 * @returns {IIdentityNavigation} {
 *     {Navigate} navigate - Navigate to an Identity page,
 *     {redirectToReturnUrl} - Redirect the user to his returnUrl address
 * }
 */
const useIdentityNavigation = (): IIdentityNavigation => {
    const returnUrl = useReturnUrl();
    const { stickyParameters } = useStickyQuery();
    const reactNavigate = useNavigate();

    /**
     * Navigate to a different webpage.
     *
     * @param {string | keyof typeof IdentityPage | undefined} path - The specific page URL or the name of the Identity page.
     *                                                                Leave empty to redirect to the home page.
     * @param {Record<string, string>} query - Values to insert as part of the query string
     * @param {Record<string, string | number | boolean>} state - Values to pass to the next page's state (if it's an internal page)
     * @param {Record<string, string>} pathDirectories - The path's optional directories (e.g. /myPath/:section/:code)
     */
    const navigate = (
        path?: string | keyof typeof IdentityPage,
        query?: Record<string, string | number | boolean>,
        state?: unknown,
        pathDirectories?: Record<string, string>
    ): void => {
        path ??= homepage;
        let resolvedPath;

        if (path in IdentityPage) {
            resolvedPath = getPath(path as keyof typeof IdentityPage);
        }
        else {
            let prefix = path?.[0] !== '/' ? '/' : '';
            resolvedPath = prefix + path;
        }

        for (let [dirKey, dirVal] of Object.entries(pathDirectories || {}))
            resolvedPath = resolvedPath?.replace(`:${dirKey}`, dirVal);

        let queryString = composeQueryString({ ...(query || {}), ...stickyParameters, returnUrl });
        let fullPath = resolvedPath + queryString;

        reactNavigate(fullPath, { state });
    };

    /**
     * @param {string} url - The URL into which to redirect
     */
    const redirectToUrl = async (url: string): Promise<void> => {
        let redirectTo = '/';

        if (url !== redirectTo) {
            let verified = await verifyReturnUrl(url);

            if (verified && !containsXSSTriggers(url))
                redirectTo = decodeUrlIfNecessary(url)!;
            else
                console.error(`illegal redirect url: ${url}`);
        }

        window.location.href = redirectTo;
    }

    /**
     * Redirect to the last URL the user had tried to visit
     * before having to authenticate to the application.
     */
    const redirectToReturnUrl = async (): Promise<void> => {
        await redirectToUrl(returnUrl);
    }

    return {
        navigate,
        redirectToUrl,
        redirectToReturnUrl
    }
}

export default useIdentityNavigation;
