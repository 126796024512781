import { useMemo } from 'react';
import useIdentityParams from './UseIdentityParams';
import useStickyQuery from './UseStickyQuery';
import { containsXSSTriggers } from '../../utilities/URL';

const defaultReturnUrl = '/';

/**
 * @returns {string} The URL to which the user should return after the authentication flow completes.
 */
const useReturnUrl = (): string => {
    const { queryParams } = useIdentityParams<Record<string, string> & { returnurl: string }>();
    const { appendStickyParameters } = useStickyQuery();
    const hash = window.location.hash;
    const constructedReturnUrl = useMemo<string>(() => {
        const returnUrl = appendStickyParameters(queryParams.returnurl || defaultReturnUrl);
        return containsXSSTriggers(queryParams.returnurl) ? defaultReturnUrl : returnUrl;
    }, []);

    return constructedReturnUrl + hash;
}

export default useReturnUrl;
