import {FunctionComponent, useEffect, useState} from 'react';
import { ITrackingTool } from '../types';
import useCookie from "../../../hooks/UseCookie";

const MatomoTrackingTool: FunctionComponent<ITrackingTool> = props => {
    const [isMatomoInitLoaded,setIsMatomoInitLoaded] = useState<boolean>(false);
    const [sgIdValue] = useCookie('sgID');

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//matomo.cloudfront.similarweb.io/sw-matomo-init.js";
        script.async = true;
        script.onload = () => {
            setIsMatomoInitLoaded(true);
        }
        document.body.appendChild(script);
    }, [props.token]);

    useEffect(() => {
        if (isMatomoInitLoaded) {
            const w = window as any;
            w.initMatomo(props.token);

            // temporary for BI data validation
            // will be removed when switching to Matomo
            w._matomoPaq.push(["setCustomDimension", 11, sgIdValue])
        }
    }, [props.token, isMatomoInitLoaded, sgIdValue]);

    return (
        <>
            <noscript>
                <img
                    src={`https://matomo.similarweb.io/matomo.php?idsite=${props.token}&amp;rec=1`}
                    style={{ border: 0 }}
                    alt={''}
                />
            </noscript>
        </>
    );
}

export default MatomoTrackingTool;
