import fetchService from '../../common/utilities/API/FetchService';
import { ISupportData } from './types';
import { IServerResponse } from '../../common/utilities/API/types';

/**
 * Send a support ticket to Zendesk.
 *
 * @param {ISupportData} data - The support form's filled data
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const sendTicket = async (data: ISupportData): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/support', {
        ...data,
    });
