import { FunctionComponent } from 'react';
import { BoxContainer, Box, Label } from './Checkbox.style';

interface ICheckbox {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
}

const Checkbox: FunctionComponent<ICheckbox> = props => {
    return (
        <div>
            <BoxContainer>
                <Box
                    type={'checkbox'}
                    checked={props.checked}
                    onChange={() => props.onChange(!props.checked)}
                    disabled={!!props.disabled}
                />
                &nbsp;
                {props.label && (
                    <Label>{props.label}</Label>
                )}
            </BoxContainer>
        </div>
    );
}

export default Checkbox;