import { Suspense, useEffect } from 'react';
import './App.css';
import AppRouter from './components/AppRouter/AppRouter';
import I18nProvider from './common/providers/I18nProvider';
import TrackingProvider from './common/providers/TrackingProvider';
import CircularLoader from './components/CircularLoader/CircularLoader';
import useCookie from "./common/hooks/UseCookie";
import {v4 as uuidv4} from "uuid";

function App() {
    const [sgIdValue, setCookie] = useCookie('sgID');

    useEffect(() => onStartup(), []);

    /**
     * Activate when the application starts.
     */
    const onStartup = (): void => {
        window.onbeforeunload = () => window.scrollTo(0, 0);

        if (!sgIdValue)
        {
            setCookie(uuidv4());
        }
    }

    return (
        <Suspense fallback={<CircularLoader fullscreen />}>
            <div className="App">
                <TrackingProvider />
                <I18nProvider>
                    <AppRouter />
                </I18nProvider>
            </div>
        </Suspense>
    );
}

export default App;
