import styled, { css, keyframes } from "styled-components";

const pageEntryKeyframes = keyframes`
    0% { opacity: 0; }
    20% { opacity: 0; }
    100% { opacity: 1; }
`;

const pageEntryAnimation = css`
    animation-name: ${pageEntryKeyframes};
    animation-fill-mode: forwards;
`;

export const Container = styled.div<{
    changing: boolean;
    transitionTime: number;
}>`
    opacity: ${props => props.changing ? 0 : 1};
    animation-duration: ${props => props.transitionTime}ms;
    ${props => props.changing && pageEntryAnimation}
`;