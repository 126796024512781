import { FunctionComponent, useContext } from 'react';
import I18nContext from '../../../common/context/I18nContext';
import SuccessImg from '../images/success.svg';
import Modal from '../../Modal/Modal';
import I18n from '../../I18n/I18n';
import { IllustrationContainer, Subtitle, Title } from './SupportFormSuccessModal.style';
import SubmitButton from '../../SubmitButton/SubmitButton';
import { asI18nKey } from '../../../common/utilities/Localization';

interface ISupportFormSuccessModal {
    isOpen: boolean;
    onClose: () => void;
}

const SupportFormSuccessModal: FunctionComponent<ISupportFormSuccessModal> = props => {
    const { translate } = useContext(I18nContext);

    return (
        <Modal
            {...props}
            width={560}
            height={450}
        >
            <IllustrationContainer>
                <img
                    src={SuccessImg}
                    alt={''}
                />
            </IllustrationContainer>
            <Title>
                <I18n>support.success.title</I18n>
            </Title>
            <Subtitle>
                <I18n>support.success.subtitle</I18n>
            </Subtitle>
            <SubmitButton
                onSuccess={props.onClose}
                widthPercent={25}
            >
                <I18n>support.success.button</I18n>
            </SubmitButton>
        </Modal>
    );
}

export default SupportFormSuccessModal;