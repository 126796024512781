import { FunctionComponent, useEffect  } from 'react';
import I18nContext from '../context/I18nContext';
import useLocalization from '../hooks/UseLocalization';
import '../config/LocalizationConfig';
import useCookie from '../hooks/UseCookie';
import { useTranslation } from 'react-i18next';
import { fallbackLocale } from '../config/LocalizationConfig';

const I18nProvider: FunctionComponent = props => {
    const { translate, changeLanguage } = useLocalization();
    const [localeCookie] = useCookie('locale', fallbackLocale);

    //detect current application's language
    useEffect(() => {
        let currentLang = localeCookie.split('-')?.[0];
        changeLanguage(currentLang);
    }, []);

    return (
        <I18nContext.Provider value={{
            translate,
            changeLanguage
        }}>
            {props.children}
        </I18nContext.Provider>
    );
}

export default I18nProvider;