import { createElement, FunctionComponent, useEffect, useMemo } from 'react';
import Matomo from '../utilities/Tracking/tools/MatomoTrackingTool';
import GoogleTagManager from '../utilities/Tracking/tools/GTMTrackingTool';
import GoogleAnalytics from '../utilities/Tracking/tools/GATrackingTool';
import { ITrackingTool, TrackingTool } from '../utilities/Tracking/types';
import { initTrackers } from '../utilities/Tracking/Tracking';
import useServerData from '../hooks/UseServerData';

interface ITrackingServerData {
    PiwikSiteId: string;
    GoogleAnalyticsToken: string;
    GoogleTagManagerToken: string;
    MixpanelToken: string;
}

interface ITrackingToolInitializer extends ITrackingTool {
    enabled: boolean;
    component?: FunctionComponent<ITrackingTool>;
}

const TrackingProvider: FunctionComponent = () => {
    const {
        Tracking: {
            PiwikSiteId,
            GoogleAnalyticsToken,
            GoogleTagManagerToken,
            MixpanelToken
        }
    } = useServerData<{ Tracking: ITrackingServerData }>();

    const trackingTools = useMemo<Record<keyof typeof TrackingTool, ITrackingToolInitializer>>(() => ({
        'Matomo': {
            enabled: !!PiwikSiteId,
            token: PiwikSiteId,
            component: Matomo,
        },
        'Mixpanel': {
            enabled: !!MixpanelToken,
            token: MixpanelToken
        },
        'GoogleTagManager': {
            enabled: !!GoogleTagManagerToken,
            component: GoogleTagManager,
            token: GoogleTagManagerToken
        },
        'GoogleAnalytics': {
            enabled: !!GoogleAnalyticsToken,
            component: GoogleAnalytics,
            token: GoogleAnalyticsToken
        }
    }), []);

    //initialize tracking tools
    useEffect(() => {
        let availableTools = Object.keys(trackingTools)
            .map(x => x as keyof typeof TrackingTool)
            .filter(x => trackingTools[x].enabled)

        initTrackers(availableTools);
    }, [trackingTools]);

    return (
        <div style={{ display: 'none' }}>
            {Object.values(trackingTools).map(x => x.token && x.component && (
                createElement(x.component, {
                    key: x.component.name,
                    token: x.token
                })
            ))}
        </div>
    );
}

export default TrackingProvider;
