import styled from "styled-components";
import { colorsPalettes } from '@similarweb/styles';

export const NewFooterContainer = styled.div`
    background: #F5F8FF;
    display: flex;
    align-items: center;
    gap: 12px;
    width: auto;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 24px;
`

export const NewFooterImage = styled.img`
    height: 24px;
`

export const NewFooterContent = styled.div`
    display: grid;
    gap: 4px;
`

export const Title = styled.span`
    color: ${colorsPalettes.blue[500]};
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
`

export const SubTitle = styled.span`
    color: ${colorsPalettes.blue[400]};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`
