import { FunctionComponent } from 'react';
import TextField from '../../components/Fields/TextField/TextField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import { ForgotPasswordLabelContainer, PasswordField, SocialSectionContainer } from './LoginPage.style';
import SocialAuthSection from '../../components/SocialAuthSection/SocialAuthSection';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import { IPage } from '../../components/PageWrapper/types';
import { login } from './LoginService';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { asI18nKey } from '../../common/utilities/Localization';
import useAPI from '../../common/hooks/UseAPI';
import {ServerError} from '../../common/utilities/API/types';
import { AlertType } from '../../components/AlertToast/types';
import { StatusCodes } from '../../common/enums/statusCodes';


const LoginPage: FunctionComponent<IPage> = props => {
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const { navigate, redirectToReturnUrl } = useIdentityNavigation();
    const withinIframe = window.self !== window.top;
    const api = useAPI();

    /**
     * Activate when the page's form is submitted.
     * This function checks if the given credentials exist and continues with the login flow.
     *
     * @param {string} email - The user's email
     * @param {string} password - The user's password
     * @returns {Promise<boolean>} True if the submission is successful.
     */
    const onSubmit = async ({ email, password }: Record<string, string>): Promise<boolean> => {
        return await api(() => login(email, password), {
            errorsMap: params => [
                {
                    error: ServerError.CredentialLoginNotAllowed,
                    message: props.translate(asI18nKey('login.error.basic_login_method_not_allowed'))
                },
                {
                    error: ServerError.OnlySsoIsAllowed,
                    message: props.translate(asI18nKey('login.error.only_sso_login_method_is_allowed')),
                    alertType: AlertType.Warning
                },
                {
                    error: ServerError.IncorrectCredentials,
                    message: props.translate(asI18nKey('login.error.wrong_credentials'))
                },
                {
                    error: ServerError.LoginNotAllowed,
                    message: props.translate(asI18nKey('login.error.login_not_allowed')),
                    alertType: AlertType.Warning
                },
                {
                    error: ServerError.UserLocked,
                    message: props.translate(asI18nKey('login.error.user_locked'), { minutes: params?.minutesLeft || 5 }),
                    alertType: AlertType.Warning
                }
            ],
            onSuccess: async ({ status, data }) => {
                switch (status) {
                    case StatusCodes.OK:
                        props.disablePage();
                        await redirectToReturnUrl();
                        break;

                    case StatusCodes.DEVICE_VERIFICATION:
                        navigate('DeviceVerification', undefined, data);
                        break;

                    case StatusCodes.RESET_PASSWORD:
                        navigate('ResetPassword', undefined, data);
                        break;

                    case StatusCodes.TWO_FACTOR:
                        navigate('TwoFactor', undefined, data);
                        break;

                    case StatusCodes.DEVICE_VERIFICATION_NT:
                        navigate('DeviceVerificationPrompt', undefined , { ...data, email });
                        break;

                    case StatusCodes.DEVICE_VERIFICATION_EMAIL_SENT:
                        navigate('DeviceVerificationEmailSent', undefined , data);
                        break;
                }
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            }
        });
    }

    /**
     * Activate when the 'forgot password' link is clicked.
     * This function redirects the user to the relevant page.
     */
    const onForgotPasswordClick = (): void => navigate('ForgotPassword');

    return (
        <>
            <TextField
                name={'email'}
                automationName={'email'}
                type={'Email'}
                label={props.translate(asI18nKey('login.field.email.label'))}
                required
                focusOnPageLoad
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={props.trackingCategory}
                onChange={setInput}
                onEnterPress={submissionTrigger.fire}
            />
            <PasswordField>
                <TextField
                    name={'password'}
                    automationName={'password'}
                    type={'Password'}
                    label={props.translate(asI18nKey('login.field.password.label'))}
                    required
                    shouldVerify={inputsVerificationTrigger.active}
                    trackingCategory={props.trackingCategory}
                    onChange={setInput}
                    onEnterPress={submissionTrigger.fire}
                />
                <ForgotPasswordLabelContainer>
                    <LinkParagraph
                        linkLabel={props.translate(asI18nKey('login.forgot_password'))}
                        callback={onForgotPasswordClick}
                        automationName={'forgot-password'}
                    />
                </ForgotPasswordLabelContainer>
            </PasswordField>
            <SubmitButton
                automationName={'submit-button'}
                onSuccess={onSubmit}
                formInputs={formInputs}
                onFailedVerification={inputsVerificationTrigger.fire}
                trackingCategory={props.trackingCategory}
                trackingComponentName={'log in'}
                shouldSubmit={submissionTrigger.active}
            >
                {props.translate(asI18nKey('login.button'))}
            </SubmitButton>
            <LinkParagraph
                automationName={'sso-link'}
                linkLabel={props.translate(asI18nKey('login.sso_login.action'))}
                targetPage={'Sso'}
            />
            {!withinIframe && (
                <SocialSectionContainer>
                    <SocialAuthSection
                        trackingCategory={props.trackingCategory}
                        onServerAlert={props.onServerAlert}
                        onUnexpectedError={props.onUnexpectedError}
                        logSubmissionFailure={logSubmissionFailure}
                        onSuccessfulRedirection={props.disablePage}
                    />
                </SocialSectionContainer>
            )}
        </>
    );
}

export default LoginPage;
