import { FunctionComponent } from 'react';
import { IPage } from '../../components/PageWrapper/types';
import I18n from '../../components/I18n/I18n';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import { asI18nKey } from '../../common/utilities/Localization';
import { Info } from './LockoutPage.style';

const LockoutPage: FunctionComponent<IPage> = props => {
    return (
        <>
            <Info>
                <I18n>lockout.subtitle</I18n>
            </Info>
            <LinkParagraph
                prefix={props.translate(asI18nKey('lockout.login'))}
                linkLabel={props.translate(asI18nKey('lockout.login.action'))}
                targetPage={'Login'}
            />
        </>
    );
}

export default LockoutPage;