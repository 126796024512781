import styled, { css, keyframes } from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { AlertType } from './types';

const trembleKeyframes = keyframes`
    0% { transform: translateX(0); }
    33% { transform: translate(10px); }
    66% { transform: translateX(-10px,); }
    100% { transform: translateX(0); }
`;

const trembleAnimation = css`
    animation-name: ${trembleKeyframes};
    animation-duration: .5s;
    animation-fill-mode: forwards;
`;

export const Stripe = styled.div<{
    visible: boolean;
    type: AlertType;
}>`
    ${props => {
        switch (props.type) {
            case AlertType.Info:
                return `
                    --background: ${colorsPalettes.green[100]};
                    --foreground: ${colorsPalettes.green.s100};
                `;
                
            case AlertType.Warning:
                return `
                    --background: ${colorsPalettes.yellow[100]};
                    --foreground: ${colorsPalettes.yellow[500]};
                `;
                
            case AlertType.Error:
                return `
                    --background: ${colorsPalettes.red[100]};
                    --foreground: ${colorsPalettes.red.s100};
                `;
        }
        
        return '';
    }}
  
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: ${props => props.visible ? 120 : 0}px;
    margin-bottom: 30px;
    opacity: ${props => props.visible ? 1 : 0};
    transform: scaleX(${props => props.visible ? 1 : 0});
    background-color: var(--background);
    transition: .2s;
  
    & > * {
        transition: .2s;
    }
`;

export const Text = styled.p<{
    tremble: boolean;
}>`
    font-size: .875rem;
    font-weight: 500;
    color: var(--foreground);
    margin: 0;
    padding: 10px 35px 10px 10px;

    ${props => props.tremble && trembleAnimation};
`;

export const CloseButton = styled.button`
    display: flex;
    position: absolute;
    right: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    transition: .2s;
  
    &:hover {
        filter: brightness(0%);
    }
  
    & > img {
        width: 15px;
    }
`;