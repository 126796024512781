import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #ebebed;
`;

export const Layout = styled.div<{
    maxContentWidth?: number;
}>`
    width: ${props => props.maxContentWidth ? `${props.maxContentWidth}px` : 'fit-content'};
    max-width: ${props => props.maxContentWidth ? `${props.maxContentWidth}px` : 'fit-content'};
    padding: 20px 30px;
    border-width: 1px;
    border-style: solid;
    border-color: ${colorsPalettes.carbon[100]};
    background-color: ${colorsPalettes.carbon[0]};
    box-shadow: 0 2px 0 hsl(0deg 0% 69% / 12%);
`;

export const AppLogo = styled.div`
    & img {
        width: 80px;
    }
`;

export const Title = styled.h2`
    margin: 10px auto 20px;
    padding: 0 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: ${colorsPalettes.carbon[400]};
`;

export const AdditionalOptionsDiv = styled.div`
    margin-top: 20px;
`;