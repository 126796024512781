import { FunctionComponent } from 'react';
import pagesConfig from '../../common/config/PagesConfig';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import PageWrapper from '../PageWrapper/PageWrapper';
import { IRoute } from './types';

/**
 * @returns {Array<IRoute>} All available application routes.
 */
const buildRoutes = (): Array<IRoute> => {
    let routes: Array<IRoute> = [];
    Object.values(pagesConfig).forEach(page => {
        page.routes.forEach(route => routes.push({
            path: route,
            element: <PageWrapper page={page} />
        }))
    });

    routes.sort((a, b) => {
        let aRoute = a.path;
        let bRoute = b.path;
        return bRoute.localeCompare(aRoute);
    });

    return [
        ...routes,
        {
            path: '*',
            element: <PageWrapper page={pagesConfig['Error']} />
        }
    ];
}

const AppRouter: FunctionComponent = () => {
    const Routes = () => useRoutes(buildRoutes());

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
}

export default AppRouter;