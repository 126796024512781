import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Instructions = styled.p`
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 400;
    color: ${colorsPalettes.carbon[400]};
`;

export const PasswordField = styled.div`
    position: relative;
`;

export const TroubleshootContainer = styled.div`
    width: 70%;
    margin: 20px auto 0;
    font-size: .825rem;
    line-height: 1.5;
    font-weight: 400;
`;

export const Troubleshoot = styled.p`
    display: inline;
    color: ${colorsPalettes.carbon[400]};
`;

export const ContactSupport = styled.button`
    outline: none;
    border: none;
    background: none;
    color: ${colorsPalettes.sky[500]};
    cursor: pointer;
    transition: .2s;

    &:hover {
        ${colorsPalettes.blue[500]};
    }
`;