import styled from "styled-components";
import { colorsPalettes } from '@similarweb/styles';
import { OptionContainer } from '../Dropdown.style';

export const Container = styled(OptionContainer)`
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: ${colorsPalettes.navigation['ACTIVE_TILE_BACKGROUND']};
    }
`

export const Image = styled.img`
    height: 25px;
    margin: auto 10px auto 0;
`;