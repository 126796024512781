import { FunctionComponent } from 'react';
import { Subtitle } from '../InternalTwoFactorMethodPage.style';
import TextField from '../../../components/Fields/TextField/TextField';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import useIdentityForm from '../../../common/hooks/UseIdentityForm';
import I18n from '../../../components/I18n/I18n';
import useLocalization from '../../../common/hooks/UseLocalization';
import { asI18nKey } from '../../../common/utilities/Localization';
import useIdentityParams from "../../../common/hooks/Parameters/UseIdentityParams";
import { verifyTwoFactor } from '../InternalTwoFactorService';
import useAPI from '../../../common/hooks/UseAPI';
import { ServerError } from '../../../common/utilities/API/types';
import { AlertType } from '../../../components/AlertToast/types';
import {
    IInternalTwoFactorAuthenticator,
    IInternalTwoFactorMethod, TwoFactorAuthenticationVerifyResult
} from '../types';

const InternalTwoFactorAuthenticationTotpMethod: FunctionComponent<IInternalTwoFactorMethod> = props => {
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const { translate } = useLocalization();
    const { routerParams } = useIdentityParams<{ authenticator: IInternalTwoFactorAuthenticator; }>();
    const api = useAPI();

    /**
     * Authenticate using the code entered by the user.
     *
     * @param {string} transactionId - The issued transaction ID
     * @param {string} code - The code entered by the user
     * @returns {Promise<boolean>} True if the authentication is successful.
     */
    const verify = async ({ transactionId, code }: Record<string, string>): Promise<boolean> => {

        return await api(async () => await verifyTwoFactor(
            routerParams.authenticator.provider,
            routerParams.authenticator.type,
            routerParams.authenticator.vendor,
            transactionId,
            code,
            props.abortSignal
        ), {
            errorsMap: () => [
                {
                    error: ServerError.CodeVerificationRejected,
                    message: translate(asI18nKey('2fa.totp.error.invalid_code'))
                },
            ],
            onSuccess: ({ data }) => {
                let accepted = data?.result === TwoFactorAuthenticationVerifyResult.Accepted;
                if (accepted) props.onVerified();
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            },
        });
    }

    return (
        <>
            <Subtitle>
                <I18n>2fa.totp.subtitle</I18n>
            </Subtitle>
            <TextField
                name={'code'}
                label={translate(asI18nKey('2fa.totp.field.code.label'))}
                focusOnPageLoad
                required
                onChange={setInput}
                shouldVerify={inputsVerificationTrigger.active}
                onEnterPress={submissionTrigger.fire}
            />
            <SubmitButton
                onSuccess={verify}
                widthPercent={70}
                formInputs={formInputs}
                shouldSubmit={submissionTrigger.active}
                onFailedVerification={inputsVerificationTrigger.fire}
            >
                <I18n>2fa.totp.verify</I18n>
            </SubmitButton>
        </>
    );
}

export default InternalTwoFactorAuthenticationTotpMethod;