import { FunctionComponent, useLayoutEffect, useMemo } from 'react';
import { Container } from './FadeTransition.style';
import useTrigger from '../../common/hooks/UseTrigger';

interface IFadeTransition {
    msTime: number;
    dependencies?: Array<unknown>;
}

const FadeTransition: FunctionComponent<IFadeTransition> = props => {
    const changeTrigger = useTrigger(Math.max(0, props.msTime));
    const dependencies = useMemo<Array<unknown>>(() => {
        let arr = props.dependencies;
        return (arr?.length) ? arr : [ props.children ];
    }, [props.dependencies, props.children]);

    useLayoutEffect(() => {
        !changeTrigger.active && changeTrigger.fire();
    }, dependencies);

    return (
        <Container
            transitionTime={props.msTime}
            changing={changeTrigger.active}
        >
            {props.children}
        </Container>
    );
}

export default FadeTransition;