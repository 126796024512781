import { FunctionComponent, useEffect } from 'react';
import PushIllustration from '../images/push_illustration.svg';
import { PushAlert, PushIllustrationContainer } from '../InternalTwoFactorMethodPage.style';
import { issueTwoFactor, verifyTwoFactor } from '../InternalTwoFactorService';
import useAPI from '../../../common/hooks/UseAPI';
import I18n from '../../../components/I18n/I18n';
import { asI18nKey } from '../../../common/utilities/Localization';
import useLocalization from '../../../common/hooks/UseLocalization';
import useIdentityParams from "../../../common/hooks/Parameters/UseIdentityParams";
import useIdentityForm from '../../../common/hooks/UseIdentityForm';
import TimedResendButton from '../../../components/TimedResendButton/TimedResendButton';
import useTrigger from '../../../common/hooks/UseTrigger';
import { ServerError } from '../../../common/utilities/API/types';
import { AlertType } from '../../../components/AlertToast/types';
import {
    IInternalTwoFactorMethod,
    IInternalTwoFactorAuthenticator, TwoFactorAuthenticationVerifyResult
} from '../types';

const InternalTwoFactorAuthenticationPushMethod: FunctionComponent<IInternalTwoFactorMethod> = props => {
    const { routerParams } = useIdentityParams<{ authenticator: IInternalTwoFactorAuthenticator; }>();
    const { logSubmissionFailure } = useIdentityForm();
    const { translate } = useLocalization();
    const cancelResendCooldown = useTrigger();
    const api = useAPI();

    useEffect(() => { issueAndVerify(); }, []);

    /**
     * Issue the user a push to his phone.
     *
     * @returns {Promise<string>} The push's transaction ID (used for authentication).
     */
    const sendPush = async (): Promise<string> => {
        let transactionId = '';
        let result = await api(async () => await issueTwoFactor(
            routerParams.authenticator.provider,
            routerParams.authenticator.type,
            routerParams.authenticator.vendor,
            props.abortSignal
        ), {
            errorsMap: () => [
                {
                    error: ServerError.CodeIssueFailure,
                    message: translate(asI18nKey('2fa.push.error.issue_failed'))
                },
            ],
            onSuccess: ({ data }) => transactionId = data?.transactionId || '',
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);

                cancelResendCooldown.fire();
            },
            onAbort: cancelResendCooldown.fire
        });

        return result && transactionId ? transactionId : '';
    }

    /**
     * Verify the user's phone using the push sent to it.
     *
     * @param {string} transactionId - The issued transaction ID
     * @returns {Promise<boolean>} True if the authentication is successful.
     */
    const verify = async (transactionId: string): Promise<boolean> => {
        return await api(async () => await verifyTwoFactor(
            routerParams.authenticator.provider,
            routerParams.authenticator.type,
            routerParams.authenticator.vendor,
            transactionId,
            undefined,
            props.abortSignal
        ), {
            errorsMap: () => [
                {
                    error: ServerError.CodeVerificationRejected,
                    message: translate(asI18nKey('2fa.push.error.rejected'))
                },
            ],
            onSuccess: ({ data }) => {
                let accepted = data?.result === TwoFactorAuthenticationVerifyResult.Accepted;
                if (accepted) props.onVerified();
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            },
            onAbort: cancelResendCooldown.fire
        });
    }

    const issueAndVerify = async (): Promise<void> => {
        let transactionId = await sendPush();
        await verify(transactionId);
    }

    return (
        <>
            <PushIllustrationContainer>
                <img
                    src={PushIllustration}
                    alt={''}
                />
            </PushIllustrationContainer>
            <PushAlert>
                <I18n>2fa.push.subtitle</I18n>
            </PushAlert>
            <TimedResendButton
                onSendCode={issueAndVerify}
                confirmationMessage={translate(asI18nKey('2fa.no_code.confirmation'))}
                buttonLabel={translate(asI18nKey(`2fa.no_code.action`))}
                buttonPrefix={translate(asI18nKey('2fa.no_code'))}
                timerPrefix={translate(asI18nKey('2fa.no_code.cooldown.prefix'))}
                timerSuffix={translate(asI18nKey('2fa.no_code.cooldown.suffix'))}
                cancelCooldown={cancelResendCooldown.active}
                cooldownTime={30}
            />
        </>
    );
}

export default InternalTwoFactorAuthenticationPushMethod;