import { allTrackers } from '../utilities/Tracking/Tracking';
import useCookie from './UseCookie';
import { logClientMessage } from '../utilities/Logging';

interface ITrackingClient {
    trackEvent: (name: string, action: string, category?: string) => boolean;
    trackPageView: (name?: string) => boolean;
}

/**
 * @param {string | undefined} defaultCategory - The default name that will be attached to all events tracked by this hook.
 *                                               If null, the name will have to be defined as an argument to this hook's functions.
 * @returns {ITrackingClient} {
 *     {(name: string, action: string, category?: string) => boolean} trackEvent - Track a user event
 *     {(name?: string) => boolean} trackPageView - Track the event of a user viewing a page
 * }
 */
const useTracking = (defaultCategory?: string): ITrackingClient => {
    const [localeCookie] = useCookie('locale', 'en-us');

    /**
     * Track a user event.
     *
     * @param {string} name - The event's name
     * @param {string} action - The user's action that triggered the event
     * @param {string | undefined} category - The event's category (if null, using the hook's argument)
     * @returns {boolean} True if the tracking is successful.
     */
    const trackEvent = (name: string, action: string, category?: string): boolean => {
        let evCategory = category || defaultCategory;

        if (!evCategory || !name || !action) return false;

        try { allTrackers.trackEvent(evCategory, action, name); }
        catch (ex) {
            logFailure({
                eventCategory: evCategory,
                eventName: name,
                eventAction: action
            }, ex);

            return false;
        }

        return true;
    }

    /**
     * Track the event of a user viewing a page.
     *
     * @param {string | undefined} name - The page's name (if null, using the hook's argument)
     * @returns {boolean} True if the tracking is successful.
     */
    const trackPageView = (name?: string): boolean => {
        let pageName = name || defaultCategory;

        if (!pageName) return false;

        try {
            allTrackers.trackPageView({
                path: {
                    section: pageName,
                    sub_section: '',
                    sub_sub_section: '',
                    page_id: `identity.${pageName.toLowerCase()}`
                },
                lang: localeCookie,
                is_sw_user: false, //user is always anonymous
            });
        }
        catch (ex) {
            logFailure({ pageName }, ex);
            return false;
        }

        return true;
    }

    /**
     * Log a failed tracking attempt.
     *
     * @param {Record<string, string>} data - Any relevant tracking data
     * @param {unknown} exception - The thrown exception
     */
    const logFailure = (data: Record<string, string>, exception?: unknown): void =>
        logClientMessage('Page view tracking failed', 'Error', { ...data, exception }, true);

    return {
        trackEvent,
        trackPageView
    };
}

export default useTracking;
