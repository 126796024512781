import React, { FunctionComponent, useEffect, useRef, MouseEvent } from 'react';
import { ClearIcon, MagnifyingGlassIcon, SearchBar, SearchContainer } from './SearchField.style';
import MagnifyingGlassIco from '../../images/magnifying-glass.svg';
import ClearIco from '../../images/clear.svg';

interface ISearchField {
    isOpen: boolean;
    onFilter: (searchTerm: string) => void;
    onArrowUpPress?: () => void;
    onArrowDownPress?: () => void;
}

const SearchField: FunctionComponent<ISearchField> = props => {
    const searchRef = useRef<HTMLInputElement>(null);

    //focus
    useEffect(() => {
        if (props.isOpen) {
            onClear();
            searchRef?.current?.focus();
        }
    }, [searchRef, props.isOpen]);

    /**
     * Activate when the user type in a search term.
     *
     * @param {string} searchTerm - The typed term
     */
    const onSearch = (searchTerm: string): void => {
        if (searchRef.current) searchRef.current.value = searchTerm;
        props.onFilter(searchTerm);
    }

    /**
     * Activate when the user clicks the clear icon.
     *
     * @param {MouseEvent | undefined} ev - Default mouse event
     */
    const onClear = (ev?: MouseEvent<HTMLImageElement>): void => {
        ev?.preventDefault();
        onSearch('');
    }

    /**
     * Activate when the user presses either the up or down keys.
     *
     * @param {string} key - The pressed key
     */
    const onArrowKeyDown = (key: string): void => {
        switch (key) {
            case 'ArrowUp': return props.onArrowUpPress?.();
            case 'ArrowDown': return props.onArrowDownPress?.();
        }
    }

    return (
        <SearchContainer>
            <MagnifyingGlassIcon
                src={MagnifyingGlassIco}
                alt={''}
            />
            <SearchBar
                className={'search-bar'}
                tabIndex={0}
                ref={searchRef}
                onChange={ev => onSearch(ev.target.value)}
                onKeyDown={ev => onArrowKeyDown(ev.key)}
            />
            {!!searchRef?.current?.value && (
                <ClearIcon
                    src={ClearIco}
                    alt={''}
                    onMouseDown={onClear}
                />
            )}
        </SearchContainer>
    );
}

export default SearchField;