import { FunctionComponent, KeyboardEvent, useEffect, useRef } from 'react';
import { Container, Image } from './DropdownOption.style';
import { OptionText } from '../Dropdown.style';
import { IDropdownOptionConfig } from '../types';

interface IDropdownOption {
    onSelect: (option: IDropdownOptionConfig) => void;
    config: IDropdownOptionConfig;
    shouldFocus?: boolean;
    onArrowUpPress?: () => void;
    onArrowDownPress?: () => void;
    onOtherKeyPress?: (key: string) => void;
}

const DropdownOption: FunctionComponent<IDropdownOption> = props => {
    const ref = useRef<HTMLLIElement>(null);

    useEffect(() => {
        props.shouldFocus && ref?.current?.focus?.();
    }, [props.shouldFocus]);

    /**
     * Activate when a key is pressed while the options is focused.
     * This function either navigates to neighbour options (using the up/down arrows keys),
     * or selects this option (using the Enter key).
     *
     * @param {KeyboardEvent<HTMLLIElement>} ev - Default event object
     */
    const onKeyDown = (ev: KeyboardEvent<HTMLLIElement>): void => {
        let key = ev.key;
        if (key !== 'Tab') ev.preventDefault();

        switch (key) {
            case 'Enter':
                props.onSelect(props.config);
                break;

            case 'ArrowUp':
                props.onArrowUpPress?.();
                break;

            case 'ArrowDown':
                props.onArrowDownPress?.();
                break;

            case 'Tab':
                if (!ev.shiftKey) props.onArrowDownPress?.();
                break;
        }

        if (key.length === 1) props.onOtherKeyPress?.(key);
    }

    return (
        <Container
            ref={ref}
            className={'accessible-tight'}
            tabIndex={0}
            onMouseUp={() => props.onSelect(props.config)}
            onKeyDown={onKeyDown}
        >
            {!!props.config.image && (
                <Image
                    src={props.config.image}
                    alt={''}
                />
            )}
            <OptionText>{props.config.text}</OptionText>
        </Container>
    );
}

export default DropdownOption;