import { useMemo, useState } from 'react';
import { IInput, ValidBodyValue } from '../../components/Fields/types';
import useTrigger, { ITrigger } from './UseTrigger';
import { logClientMessage } from '../utilities/Logging';

interface IIdentityForm {
    setInput: (name: string, value: ValidBodyValue, verified?: boolean) => void;
    formInputs: Array<IInput>;
    inputsVerificationTrigger: ITrigger;
    submissionTrigger: ITrigger;
    logSubmissionFailure: (exceptionMsg?: string) => void;
}

/**
 * @returns {IIdentityForm} {
 *      {(name: string, value: ValidBodyValue, verified?: boolean) => void} setInput - A function that collects a field's new input
 *      {Array<IInput>} formInputs - A list of the form's collected inputs
 *      {ITrigger} inputsVerificationTrigger - A trigger that when fired, indicates that the form's inputs need to be verified
 *      {ITrigger} submissionTrigger - A trigger that when fired, indicates that a form submission should be attempted
 *      {(exceptionMsg?: string) => void} logSubmissionFailure - A function that logs the failed submission attempt to the server
 * }
 */
const useIdentityForm = (): IIdentityForm => {
    const inputsVerificationTrigger = useTrigger();
    const submissionTrigger = useTrigger();
    const [inputs, setInputs] = useState<Array<IInput>>([]);
    const insensitiveInputs = useMemo<Record<string, string>>(() => {
        let insensitive = inputs.filter(x => !x.name.includes('password'));
        return insensitive.reduce((res, curr) => ({ ...res, [curr.name]: curr.value?.toString() }), {});
    }, [inputs]);

    /**
     * @param {string} name - The input field's name (key)
     * @param {ValidBodyValue} value - The input field's new value
     * @param {boolean} verified - True if the input is verified according to the field's current regex pattern
     */
    const setInput = (name: string, value: ValidBodyValue, verified: boolean = true): void => {
        setInputs(prev => [
            ...prev.filter(x => x.name !== name),
            { name, value, verified }
        ]);
    }

    /**
     * Send an error log to the server.
     *
     * @param {string | undefined} exceptionMsg - The received error message
     */
    const logSubmissionFailure = (exceptionMsg?: string): void => {
        logClientMessage('Form submission failed', 'Information', {
            page: window.location.href,
            typedData: insensitiveInputs,
            exception: exceptionMsg
        });
    }

    return {
        setInput,
        formInputs: inputs,
        inputsVerificationTrigger,
        submissionTrigger,
        logSubmissionFailure
    };
}

export default useIdentityForm;
