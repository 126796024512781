import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';
import {IResendCodeToPhone, ISendCodeResponse} from './types';

/**
 * Send the device verification code to the user.
 *
 * @param {string | undefined } type - 2fa specific method
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const sendCode = async (type: string | undefined): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/device-verification/issue', { type });

/**
 * Verify the user's device verification code.
 *
 * @param {string} code - The user's device verification code
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const verifyCode = async (code: string): Promise<IServerResponse<ISendCodeResponse>> =>
    fetchService.post<ISendCodeResponse>('/account/device-verification/verify', { code });


/**
 * Replace the user's phone number.
 *
 * @param {string} phoneNumber - Customer phone number
 * @param {string} email - customer email address
 * @param {string} token - A uniquely generated token needed for this action
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const updatePhone = async (phoneNumber: string, email:string, token: string): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/device-verification-nt/update-phone-number', {
        phoneNumber,
        email,
        token
    });

/**
 * Sends email with an update phone link.
 *
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const requestUpdatePhoneEmail = async (): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/device-verification-nt/send-update-phone-email', {});

/**
 * Sends code for phone verification.
 *
 * @returns {Promise<IServerResponse<IResendCodeToPhone>>} The server's response.
 */
export const sendCodeNt = async (email: string, token: string): Promise<IServerResponse<IResendCodeToPhone>> =>
    fetchService.post<IResendCodeToPhone>('/account/device-verification-nt/issue', { email, token });

/**
 * Verify code and token.
 *
 * @param {string} code - Customer phone number
 * @param {string} email - Customer email
 * @param {string} token - A uniquely generated token needed for this action
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const verifyCodeNt = async (code: string, email: string, token: string): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/device-verification-nt/verify', { code, email, token });
