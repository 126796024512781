import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';
import { ILoginResponse } from './types';

/**
 * Log the user into the application.
 *
 * @param {string} email - The user's email
 * @param {string} password - The user's password
 * @returns {Promise<IServerResponse<ILoginResponse>>} The server's response.
 */
export const login = async (email: string, password: string): Promise<IServerResponse<ILoginResponse>> =>
    fetchService.post<ILoginResponse>('/account/login', { email, password });
