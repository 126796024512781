import { FunctionComponent, useMemo, useState } from 'react';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import { useParams } from 'react-router-dom';
import { Title, Info, SuggestionsContainer, SupportSuggestionContainer } from './ErrorPage.style';
import { IErrorConfig } from './types';
import SupportModal from '../../components/SupportModal/SupportModal';
import { IPage } from '../../components/PageWrapper/types';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { asI18nKey } from '../../common/utilities/Localization';
import config, { error404Config } from './config';
import usePageAccessGuard from '../../common/hooks/UsePageAccessGuard';
import I18n from '../../components/I18n/I18n';

const ErrorPage: FunctionComponent<IPage> = props => {
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
    const { navigate } = useIdentityNavigation();
    const { code } = useParams<string>();
    const errorConfig = useMemo<IErrorConfig>(() => {
        if (!code) return error404Config;

        let definedConfig = config.find(({ errorCode }) => {
            let regex = new RegExp(`^${errorCode}$`, 'i');
            return regex.test(code);
        });

        return definedConfig || error404Config;
    }, [code]);

    const pageNotFound = useMemo<boolean>(() => errorConfig === error404Config, [errorConfig]);
    usePageAccessGuard(!pageNotFound && !code);

    return (
        <>
            <Title>
                <I18n>{errorConfig?.message}</I18n>
            </Title>
            {errorConfig.info && (
                <Info>
                    <I18n>{errorConfig.info}</I18n>
                </Info>
            )}
            <SuggestionsContainer>
                <LinkParagraph
                    prefix={props.translate(asI18nKey('error_page.instructions'))}
                    linkLabel={props.translate(asI18nKey('error_page.back.action'))}
                    suffix={props.translate(asI18nKey('error_page.back.suffix'))}
                    callback={() => window.history.back()}
                />
            </SuggestionsContainer>
            <SubmitButton onSuccess={() => navigate('Login')}>
                {props.translate(asI18nKey('error_page.homepage.action'))}
            </SubmitButton>
            {!pageNotFound && (
                <SupportSuggestionContainer>
                    <LinkParagraph
                        prefix={props.translate(asI18nKey('error_page.support'))}
                        linkLabel={props.translate(asI18nKey('error_page.support.action'))}
                        callback={() => setSupportModalOpen(true)}
                    />
                    <SupportModal
                        isOpen={supportModalOpen}
                        onClose={() => setSupportModalOpen(false)}
                        predefinedMessage={code ?
                            props.translate(asI18nKey('support.field.issue.placeholder.error'), { code })
                            : undefined
                        }
                    />
                </SupportSuggestionContainer>
            )}
        </>
    );
}

export default ErrorPage;
