import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { IPage } from '../../components/PageWrapper/types';
import { asI18nKey } from '../../common/utilities/Localization';
import {IInternalTwoFactorAuthenticator, InternalTwoFactorType} from './types';
import NoOptionsIllustration from './images/no_options_illustration.svg';
import TwoFactorOption from '../../components/TwoFactorInternalOptions/TwoFactorInternalOptions';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import useIdentityParams from "../../common/hooks/Parameters/UseIdentityParams";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
import Checkbox from '../../components/Checkbox/Checkbox';
import I18n from '../../components/I18n/I18n';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import useLocalStorage from '../../common/hooks/UseLocalStorage';
import {
    NoOpContent,
    NoOpImg,
    NoOpSubtitle,
    NoOpTitle,
    RememberSelectionContainer,
    SettingsButtonContainer,
    Subtitle
} from './InternalTwoFactorPage.style';

const InternalTwoFactorPage: FunctionComponent<IPage> = props => {
    const supportedProvider = 'okta';
    const settingsLink = 'https://similarweb.okta.com/enduser/settings';
    const { navigate } = useIdentityNavigation();
    const [memorizedSelection, setMemorizedSelection, removeMemorizedSelection] = useLocalStorage('2fa-method');
    const { serverParams, routerParams, queryParams } = useIdentityParams<{
        authenticators: Array<IInternalTwoFactorAuthenticator>,
        skipautoselection: boolean;
        callback: string;
    }>();

    const authenticators = serverParams?.authenticators?.filter(authenticator =>
        authenticator?.provider?.toLowerCase?.() === supportedProvider);

    const [rememberSelectionFlag, setRememberSelectionFlag] = useState(!!memorizedSelection);
    const findMethod = (method: InternalTwoFactorType): IInternalTwoFactorAuthenticator | undefined => {
        return authenticators?.find(authenticator => {
            return authenticator?.type?.toLowerCase() === InternalTwoFactorType[method].toLowerCase();
        });
    }

    const supportedMethods: Record<keyof typeof InternalTwoFactorType, IInternalTwoFactorAuthenticator | undefined> = {
        'TOTP': findMethod(InternalTwoFactorType.TOTP),
        'Push': findMethod(InternalTwoFactorType.Push),
        'SMS': findMethod(InternalTwoFactorType.SMS),
    };

    const anyMethodSupported = useMemo<boolean>(() => Object.values(supportedMethods).some(val => !!val), [supportedMethods]);
    const options: Record<keyof typeof InternalTwoFactorType, { enabled: boolean; onClick: () => void; title: string; subtitle: string; }> = {
        'TOTP': {
            enabled: !!supportedMethods.TOTP,
            onClick: () => supportedMethods.TOTP ? selectOption('TOTP') : {},
            title: props.translate(asI18nKey('2fa.totp.option.title')),
            subtitle: supportedMethods.TOTP ? props.translate('2fa.totp.option.subtitle') : '',
        },
        'Push': {
            enabled: !!supportedMethods.Push,
            onClick: () => supportedMethods.Push ? selectOption('Push') : {},
            title: props.translate(asI18nKey('2fa.push.option.title')),
            subtitle: supportedMethods.Push ? props.translate('2fa.push.option.subtitle') : '',
        },
        'SMS': {
            enabled: !!supportedMethods.SMS,
            onClick: () => supportedMethods.SMS ? selectOption('SMS') : {},
            title: (() => {
                const phone = supportedMethods.SMS?.extras?.phoneNumber;
                const key = !!phone ? '2fa.sms.option.title' : '2fa.sms.option.title.disabled';
                return props.translate(asI18nKey(key), { phone });
            })(),
            subtitle: supportedMethods.SMS ? props.translate(asI18nKey('2fa.sms.option.subtitle'), { digits: 6 }) : '',
        }
    };

    usePageAccessGuard(!authenticators || !queryParams.callback);

    //automatically select the memorized option
    useEffect(() => {
        let parsedSelection = memorizedSelection as keyof typeof InternalTwoFactorType;
        let selectionValid = parsedSelection && options?.[parsedSelection]?.enabled;
        if(!routerParams.skipautoselection && selectionValid) selectOption(parsedSelection);
    }, [memorizedSelection, routerParams.skipautoselection]);

    //remove remembered selection
    useEffect(() => {
        if (!rememberSelectionFlag) removeMemorizedSelection();
    }, [rememberSelectionFlag]);

    /**
     * Activate when an option is selected.
     *
     * @param {string} method - The type of authentication method
     */
    const selectOption = (method: keyof typeof InternalTwoFactorType) => {
        if (rememberSelectionFlag) setMemorizedSelection(method);
        else removeMemorizedSelection();

        navigate(
            'InternalTwoFactorMethod',
            { callback: queryParams.callback },
            { authenticator: supportedMethods[method] },
            { method: method.toLowerCase() }
        );
    };

    return (
        <>
            {anyMethodSupported ? (
                <>
                    <Subtitle>
                        <I18n>2fa.subtitle</I18n>
                    </Subtitle>
                    {Object.entries(options).map(([key, val]) => (
                        <TwoFactorOption
                            key={key}
                            title={val.title}
                            text={val.subtitle}
                            disabled={!val.enabled}
                            onClick={val.onClick}
                            settingsLink={settingsLink}
                        />
                    ))}
                </>
            ) : (
                <NoOpContent>
                    <NoOpTitle>
                        <I18n>2fa.no_options.subtitle</I18n>
                    </NoOpTitle>
                    <NoOpImg src={NoOptionsIllustration} />
                    <NoOpSubtitle>
                        <LinkParagraph
                            linkLabel={props.translate(asI18nKey('2fa.no_options.action'))}
                            suffix={props.translate(asI18nKey('2fa.no_options.suffix'))}
                            href={settingsLink}
                        />
                    </NoOpSubtitle>
                </NoOpContent>
            )}
            {anyMethodSupported && (
                <>
                    <RememberSelectionContainer>
                        <Checkbox
                            label={props.translate(asI18nKey('2fa.remember_selection'))}
                            checked={rememberSelectionFlag}
                            onChange={checked => setRememberSelectionFlag(checked)}
                        />
                    </RememberSelectionContainer>
                    <SettingsButtonContainer>
                        <LinkParagraph
                            linkLabel={props.translate(asI18nKey('2fa.settings'))}
                            href={settingsLink}
                        />
                    </SettingsButtonContainer>
                </>
            )}
        </>
    );
}

export default InternalTwoFactorPage;
