/**
 * Manipulate a single key in the browser's local storage.
 *
 * @param {string} key - The key to manipulate
 * @returns {Array} [
 *      {string} The key's value,
 *      {(value: string) => void} Set a new value,
 *      {() => void} Remove the key from local storage
 * ]
 */
const useLocalStorage = (key: string): [string, (value: string) => void, () => void] => {
    return [
        (() => window.localStorage?.getItem(key) || '')(),
        (value: string) => window.localStorage?.setItem(key, value),
        () => window.localStorage?.removeItem(key)
    ]
}

export default useLocalStorage;
