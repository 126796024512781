import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../../../common/config/ThemeConfig';
import { OptionContainer, OptionText } from '../Dropdown.style';

export const Container = styled.div<{
    open: boolean;
    listWidth: number;
    fieldHeight: number;
}>`
    position: absolute;
    top: ${props => props.fieldHeight}px;
    width: calc(${props => props.listWidth}% + /*border*/2px);
    opacity: ${props => props.open ? 1 : 0};
    pointer-events: none;
    z-index: 110;
    transition: .2s;
  
    & * {
        pointer-events: ${props => props.open ? 'auto' : 'none'};
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        width: calc(100% + 2px);
    }
`;

export const ListContainer = styled.ul`
    max-height: calc(35px * 5);
    margin: 3px 0 0 0;
    padding: 0;
    border: 1px solid ${colorsPalettes.navigation['NAV_BACKGROUND']};
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${colorsPalettes.carbon[0]};
    list-style: none;
    overflow-y: auto;
`;

export const EmptyOptionContainer = styled(OptionContainer)`
    justify-content: center;
`;

export const EmptyOptionText = styled(OptionText)`
    color: ${colorsPalettes.carbon[200]};
`;