import { FunctionComponent, useState } from 'react';
import Modal from '../Modal/Modal';
import I18n from '../I18n/I18n';
import IllustrationImg from './images/illustration.svg';
import SupportModal from '../SupportModal/SupportModal';
import { getCookieValue } from '../../common/utilities/Cookies';
import {
    Title,
    IllustrationContainer,
    Subtitle,
    SubtitleContainer,
    SupportLink,
    ButtonsSection,
    DismissButton,
    RefreshButton
} from './ErrorModal.style';

interface IErrorModal {
    isOpen: boolean;
    onClose: () => void;
    persistent?: boolean;
}

const ErrorModal: FunctionComponent<IErrorModal> = props => {
    const [supportOpen, setSupportOpen] = useState<boolean>(false);
    const canUseSupport = !!getCookieValue(/^\.(AspNetCore)\.(Antiforgery)\..+$/i);

    /**
     * Activate when the user closes the error's support modal.
     * This function closes both the support's and the error's modals.
     */
    const onSupportClose = (): void => {
        setSupportOpen(false);
        props.onClose();
    }

    return (
        <>
            <Modal
                {...props}
                isOpen={props.isOpen && !supportOpen}
                width={400}
                height={315}
            >
                <Title>
                    <I18n>error_modal.title</I18n>
                </Title>
                <IllustrationContainer>
                    <img
                        src={IllustrationImg}
                        alt={''}
                    />
                </IllustrationContainer>
                    <SubtitleContainer>
                        {canUseSupport ? (
                            <>
                                <Subtitle>
                                    <I18n>error_modal.support_available.subtitle</I18n>
                                </Subtitle>
                                &nbsp;
                                <SupportLink onClick={() => setSupportOpen(true)}>
                                    <I18n>error_modal.support_available.link</I18n>
                                </SupportLink>
                            </>
                        ) : (
                            <Subtitle>
                                <I18n>error_modal.support_unavailable.subtitle</I18n>
                            </Subtitle>
                        )}
                    </SubtitleContainer>
                <ButtonsSection>
                    {!props.persistent && (
                        <DismissButton onClick={props.onClose}>
                            <I18n>error_modal.button.dismiss.action</I18n>
                        </DismissButton>
                    )}
                    <RefreshButton onClick={() => window.location.reload()}>
                        <I18n>error_modal.button.refresh.action</I18n>
                    </RefreshButton>
                </ButtonsSection>
            </Modal>
            <SupportModal
                isOpen={supportOpen}
                onClose={onSupportClose}
            />
        </>
    );
}

export default ErrorModal;