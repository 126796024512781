import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Info = styled.p`
    display: flex;
    justify-content: center;
    font-size: .875rem;
    color: ${colorsPalettes.carbon[400]};
    width: 60%;
    margin: auto;

    & .device-address {
        display: block;
        width: 350px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;

export const Instructions = styled.p`
    font-size: .875rem;
    color: ${colorsPalettes.carbon[400]};
    width: 60%;
    margin: 30px auto;
`;