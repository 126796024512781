import { FunctionComponent, useEffect } from 'react';
import { ITrackingTool } from '../types';
import Script from 'react-load-script';

const GATrackingTool: FunctionComponent<ITrackingTool> = props => {
    useEffect(() => {
        const gtag = (...args: any): void => w.dataLayer.push(args);
        const w = window as any;
        w.dataLayer ||= [];
        gtag('js', new Date());
        gtag('config', props.token, { link_attribution: true });
    }, [props.token]);

    return (
        <Script
            url={`https://www.googletagmanager.com/gtag/js?id=${props.token}`}
            attributes={{ async: true }}
        />
    );
}

export default GATrackingTool;