import { FunctionComponent, useState, FocusEvent, useEffect } from 'react';
import { IDropdownOptionConfig } from '../types';
import { Container, EmptyOptionContainer, EmptyOptionText, ListContainer } from './DropdownList.style';
import SearchField from '../SearchField/SearchField';
import DropdownOption from '../Option/DropdownOption';
import I18n from '../../../I18n/I18n';

interface IDropdownList {
    options: Array<IDropdownOptionConfig>;
    isOpen: boolean;
    onSelect: (option: IDropdownOptionConfig) => void;
    fieldHeight: number;
    listWidth?: number;
    onBlur?: (ev: FocusEvent) => void;
}

const DropdownList: FunctionComponent<IDropdownList> = props => {
    const [filteredOptions, setFilteredOptions] = useState<Array<IDropdownOptionConfig>>(props.options);
    const [focusedOption, setFocusedOption] = useState<number>(-1);

    useEffect(() => {
        !props.isOpen && setFocusedOption(-1);
    }, [props.isOpen]);

    /**
     * Filter all available dropdown options.
     *
     * @param {string} searchTerm - The typed search term
     */
    const filterOptions = (searchTerm?: string): void => {
        let term = searchTerm?.toLowerCase() || '';
        let filtered = props.options.filter(x => {
            let text = x.text.toLowerCase();
            let value = x.value?.toLowerCase() || '';
            return text.includes(term) || value.includes(term);
        }).sort((a, b) => {
            let optionA = a.text.toLowerCase();
            let optionB = b.text.toLowerCase();
            return optionA.indexOf(term) - optionB.indexOf(term);
        });

        setFocusedOption(-1);
        setFilteredOptions(filtered);
    }

    /**
     * Activate when the user enters an alphanumeric key while not focused on the search field.
     * This function focuses on the first option that starts with the given character.
     *
     * @param {string} input - Any input the user has entered while not focused on the search field.
     */
    const onSpontaneousSearch = (input: string): void => {
        let match = filteredOptions.find(x => {
            let lowerCaseInput = input.toLowerCase();
            let value = x.value?.toLowerCase() || '';
            let text = x.text?.toLowerCase() || '';
            let inValue = value.indexOf(lowerCaseInput) === 0;
            let inText = text.indexOf(lowerCaseInput) === 0;

            return inValue || inText;
        });

        if (match) setFocusedOption(filteredOptions.indexOf(match));
    }

    /**
     * Activate when the user presses the up arrow key.
     * This function focuses on the previous option.
     */
    const onArrowUpPress = (): void => {
        setFocusedOption(prev => Math.max(prev - 1, 0));
    }

    /**
     * Activate when the user presses the up arrow key.
     * This function focuses on the previous option.
     */
    const onArrowDownPress = (): void => {
        setFocusedOption(prev => Math.min(prev + 1, filteredOptions.length - 1));
    }

    return (
        <Container
            open={props.isOpen}
            listWidth={props.listWidth || 100}
            fieldHeight={props.fieldHeight}
        >
            <SearchField
                isOpen={props.isOpen}
                onFilter={filterOptions}
                onArrowUpPress={onArrowUpPress}
                onArrowDownPress={onArrowDownPress}
            />
            <ListContainer onMouseDown={ev => ev.preventDefault()}>
                {filteredOptions.length ? (
                    filteredOptions?.map((option, index) => (
                        <DropdownOption
                            key={option.text}
                            config={option}
                            onSelect={props.onSelect}
                            shouldFocus={focusedOption !== -1 && index === Math.min(Math.max(focusedOption, 0), filteredOptions.length - 1)}
                            onArrowUpPress={onArrowUpPress}
                            onArrowDownPress={onArrowDownPress}
                            onOtherKeyPress={input => onSpontaneousSearch(input)}
                        />
                    )
                )) : (
                    <EmptyOptionContainer>
                        <EmptyOptionText>
                            <I18n>dropdown.no_options</I18n>
                        </EmptyOptionText>
                    </EmptyOptionContainer>
                )}
            </ListContainer>
        </Container>
    );
}

export default DropdownList;