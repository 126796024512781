import useIdentityNavigation from './UseIdentityNavigation';
import useStickyQuery from './Parameters/UseStickyQuery';
import { deconstructUrl } from '../utilities/URL';
import {
    IAPIErrorResponse,
    IAPIResponseOptions,
    IServerResponse,
    refreshBindingErrors
} from '../utilities/API/types';

type ApiRequestHandler = <TResult extends unknown>(
    request: () => Promise<IServerResponse<TResult>>,
    options?: IAPIResponseOptions<TResult>
) => Promise<boolean>;

/**
 * @returns {ApiRequestHandler} A function that wraps javascript's default 'fetch' function.
 */
const useAPI = (): ApiRequestHandler => {
    const { navigate } = useIdentityNavigation();
    const { appendStickyParameters } = useStickyQuery();

    /**
     * Redirect to an internal or external URL.
     *
     * @param {string} url - The URL into which to redirect
     */
    const redirect = (url: string): void => {
        if (!url) return;

        let urlData = deconstructUrl(url);
        if (!urlData.isValid) return;

        if (urlData.isAbsolute) window.location.href = appendStickyParameters(url);
        else navigate(urlData.path);
    }

    /**
     * Send an API request.
     *
     * @template TResult
     * @param {Promise<IAPIResponse<TResult>>} request - A request to the server
     * @param {IAPIResponseOptions<TResult> | undefined} options - Additional options regarding the server's response
     * @returns {Promise<boolean>} True if the server call was successful.
     */
    const sendAPIRequest = async <TResult extends unknown>(
        request: () => Promise<IServerResponse<TResult>>,
        options?: IAPIResponseOptions<TResult>
    ): Promise<boolean> => {
        let response = await request();
        let identityResult = response.result;
        let { success, targetUrl } = identityResult;

        if (success) {
            if (targetUrl) {
                options?.onRedirection?.({
                    targetUrl,
                    data: identityResult.data as TResult
                });

                redirect(targetUrl);
            }
            else options?.onSuccess?.({
                status: response.status,
                data: identityResult.data as TResult
            });
        }
        else {
            let mappedError = options?.errorsMap?.(identityResult.data)?.find(x => x.error === identityResult.error);
            let errorMessage = identityResult.errorMsg || mappedError?.message || identityResult.error || '';
            let forceRefresh = refreshBindingErrors.some(x => x.toLowerCase() === errorMessage.toLowerCase());

            options?.onError?.({
                message: errorMessage,
                forceRefresh,
                mappedHandler: mappedError,
                data: identityResult.data as TResult
            } as IAPIErrorResponse<TResult>);
        }

        return success;
    }

    return sendAPIRequest;
}

export default useAPI;
