import { UIRouterReact, UrlService } from '@uirouter/react';
import { TrackingTool } from './types';
import {
    ITrack,
    AggregatedTracker,
    GoogleTracker,
    MixpanelTracker,
    BaseTracker,
    MatomoTracker, TrackModel
} from '@similarweb/sw-track';

const router = new UIRouterReact();
const locationService = new UrlService(router);

class CustomMatomoTracker extends MatomoTracker {
    protected paq(): any[] { return (window as any)._matomoPaq; }
    protected shouldBuffer(): boolean { return true; }
    protected searchParams() { return locationService.search(); }
    protected isEnabled(): boolean { return true; }
}

class CustomGoogleAnalyticsTracker extends GoogleTracker {
    protected ga(): (...params: any) => void { return (window as any).ga; }
    protected isEnabled(): boolean { return true; }
}

class CustomGoogleTagManagerTracker extends BaseTracker {
    protected dataLayer() { return (window as any).dataLayer; }
    protected isEnabled() { return true; }
    protected healthCheckInternal() { return null; }
    protected trackPageViewInternal() { return null; }
    protected trackEventInternal(trackModel:TrackModel) {
        const dataLayer = this.dataLayer();
        dataLayer && dataLayer.push({event: [trackModel.category, trackModel.action].join("/")});
    }
}

class CustomMixpanelTracker extends MixpanelTracker {
    protected mixpanel() { return (window as any).mixpanel; }
    protected isEnabled(): boolean { return true; }
}

const instances: Record<keyof typeof TrackingTool, ITrack> = {
    'Matomo': new CustomMatomoTracker() as ITrack,
    'GoogleAnalytics': new CustomGoogleAnalyticsTracker() as ITrack,
    'GoogleTagManager': new CustomGoogleTagManagerTracker() as ITrack,
    'Mixpanel': new CustomMixpanelTracker() as ITrack
};

export const initTrackers = (trackers: Array<keyof typeof TrackingTool>): void => {
    allTrackers = new AggregatedTracker(Object.keys(instances)
        .filter(x => trackers.includes(x as keyof typeof TrackingTool))
        .map(x => instances[x as keyof typeof TrackingTool])
    );
}

export let allTrackers = new AggregatedTracker([]);
