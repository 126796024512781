import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    fullscreen: boolean;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${colorsPalettes.bluegrey[100]};
  
    ${props => props.fullscreen && `
        position: fixed;
    `}
  
    & > img {
        max-width: 100%;
    }
`;