import { IDropdownOptionConfig } from '../Fields/Dropdown/types';

export enum SupportDropdowns {
    Employees,
    Industry,
    Department,
    Role
}

const options: Record<keyof typeof SupportDropdowns, Array<IDropdownOptionConfig>> = {
    'Employees': [
        { text: '1-10' },
        { text: '11-50' },
        { text: '51-200' },
        { text: '201-500' },
        { text: '501-1000' },
        { text: '1001-5000' },
        { text: '5000+' }
    ],
    'Industry': [
        { text: 'Automotive' },
        { text: 'AdTech and AdNetwork' },
        { text: 'Agency' },
        { text: 'B2B Software' },
        { text: 'B2C Internet Service' },
        { text: 'Classifieds' },
        { text: 'Consulting & Market Research' },
        { text: 'CPG' },
        { text: 'Education' },
        { text: 'Equity Research' },
        { text: 'Financial Services' },
        { text: 'Gambling & Gaming' },
        { text: 'Hedge Funds and Asset Management' },
        { text: 'Investment Banking' },
        { text: 'Logistics & Shipping' },
        { text: 'Payments' },
        { text: 'Pharma & Healthcare' },
        { text: 'Private Equity' },
        { text: 'Publishers & Media' },
        { text: 'Public Sector, Non Profit, Fraud and Compliance' },
        { text: 'Retails & eCommerce' },
        { text: 'Telecom' },
        { text: 'Travel' },
        { text: 'Venture Capital' },
        { text: 'Other' }
    ],
    'Department': [
        { text: 'BI / Analytics' },
        { text: 'Corporate Development / Mergers and Acquisitions' },
        { text: 'Marketing: Affiliate / Media Buying' },
        { text: 'Marketing: Management / General' },
        { text: 'Marketing: SEO / Content' },
        { text: 'Marketing: PPC / Display' },
        { text: 'Marketing: PR / Comms / Social Media' },
        { text: 'Product Management' },
        { text: 'Sales / Business Development' },
        { text: 'Account Management / Customer Success' },
        { text: 'Sales Operations' },
        { text: 'Strategy / Market Research' },
        { text: 'Investment' },
        { text: 'Management (C-Level)' },
        { text: 'ECommerce' },
        { text: 'Other' }
    ],
    'Role': [
        { text: 'Analyst' },
        { text: 'Consultant' },
        { text: 'Manager' },
        { text: 'Director' },
        { text: 'Vice President (VP)' },
        { text: 'C-Level' },
        { text: 'CEO/President' },
        { text: 'Professor/Student' },
        { text: 'Other' }
    ]
}

export default options;