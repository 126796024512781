import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';

/**
 * Replace the user's password.
 *
 * @param {string} email - The user's email
 * @param {string} password - The user's new password
 * @param {string} token - A uniquely generated token needed for this action
 * @returns {Promise<IServerResponse<void>>} The server's response.
 */
export const setUpPassword = async (email: string, password: string, token: string): Promise<IServerResponse<void>> =>
    fetchService.post<void>('/account/password/reset', {
        email,
        password,
        token
    });
