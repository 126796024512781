import { FieldType } from '../../components/Fields/types';
import { asI18nKey, I18nKey } from './Localization';

export type VerificationPattern = Array<RegExp>;

export enum PatternType {
    Any,
    NotEmpty,
    Email,
    Url,
    Phone
}

export interface IVerificationConfig {
    pattern: VerificationPattern,
    customError?: I18nKey;
}

const AZ09 = 'a-zA-Z0-9';
const AZ09spcl = `${AZ09}_\\-`;

/**
 * @returns {RegExp} A valid email address regular expression.
 */
const buildEmailRegex = (): RegExp => {
    let nameChars = `${AZ09spcl}\\.'`;
    let name = `[${nameChars}]+(\\+[${nameChars}]+)*`;
    let domain = `[${AZ09spcl}]+\\.[${AZ09spcl}]+([${AZ09spcl}]*\\.[${AZ09spcl}]+)*`;
    return new RegExp(`^${name}@${domain}$`);
}

/**
 * @returns {RegExp} A valid URL regular expression.
 */
const buildUrlRegex = (): RegExp => {
    let AZ09dash = `([${AZ09}]+(-[${AZ09}])?)`;
    let AZ09perc = `[${AZ09}%]`; //for URL encoded characters
    let prefix = '(https?://)?(www\\.)?';
    let domain = `${AZ09dash}(${AZ09dash}*\\.${AZ09dash}+)+`;
    let path = `(/${AZ09dash}+)*`;
    let query = `(\\?${AZ09perc}+=${AZ09perc}+(&${AZ09perc}+=${AZ09perc}+)*)?`;
    return new RegExp(`^${prefix}${domain}${path}${query}/?$`);
}

export const predefinedConfigs: Record<keyof typeof PatternType, IVerificationConfig> = {
    'Any': {
        pattern: [ /^[\s\S]*$/ ]
    },
    'NotEmpty': {
        pattern: [ /^[\s\S]*\w+[\s\S]*$/ ],
        customError: asI18nKey('general.field.not_empty.warning')
    },
    'Email': {
        pattern: [ buildEmailRegex() ],
        customError: asI18nKey('general.field.email.warning')
    },
    'Url': {
        pattern: [ buildUrlRegex() ],
        customError: asI18nKey('general.field.url.warning')
    },
    'Phone': {
        pattern: [ /^([0-9]+(-?[0-9]+)*){6,20}$/ ],
        customError: asI18nKey('general.field.phone.warning')
    }
}

/**
 * Convert a field type to its default corresponding regex pattern.
 *
 * @param {keyof typeof FieldType} fieldType - The field's type
 * @returns {IVerificationConfig} The field's default corresponding verification config.
 */
export const getPredefinedConfigByField = (fieldType: keyof typeof FieldType): IVerificationConfig => {
    const converter: Record<keyof typeof FieldType, keyof typeof PatternType> = {
        'Text': 'NotEmpty',
        'Email': 'Email',
        'Url': 'Url',
        'Password': 'NotEmpty',
        'Phone': 'Phone'
    };

    return predefinedConfigs[converter[fieldType]];
}

/**
 * @param {number} min - The minimum amount of required characters
 * @param {number | undefined} max - The maximum amount of allowed characters (infinity if undefined)
 * @returns {IVerificationConfig} A verification config that requires the given minimum amount
 *                                and allows up to the given maximum amount of characters.
 */
export const minmaxCharactersConfig = (min: number, max?: number): IVerificationConfig => {
    min = Math.max(Math.round(min), 0);
    let validMax = max !== undefined && max > min;

    return {
        pattern: [ new RegExp(`^(.+){${min},${validMax ? max : ''}}$`) ],
        customError: validMax ? asI18nKey(`Must contain ${min}-${max} characters`) : asI18nKey(`Must contain at least ${min} characters`)
    };
}
