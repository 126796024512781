import { FunctionComponent } from 'react';
import ErrorPage from '../../pages/Error/ErrorPage';
import LoginPage from '../../pages/Login/LoginPage';
import SsoPage from '../../pages/Sso/SsoPage';
import ForgotPasswordPage from '../../pages/ForgotPassword/ForgotPasswordPage';
import ForgotPasswordConfirmationPage from '../../pages/ForgotPasswordConfirmation/ForgotPasswordConfirmationPage';
import ResetPasswordPage from '../../pages/ResetPassword/ResetPasswordPage';
import DeviceVerificationPage from '../../pages/DeviceVerification/DeviceVerificationPage';
import TwoFactorPage from '../../pages/TwoFactor/TwoFactorPage';
import BlockedPage from '../../pages/Blocked/BlockedPage';
import LockoutPage from '../../pages/Lockout/LockoutPage';
import InternalTwoFactorPage from '../../pages/TwoFactorInternal/InternalTwoFactorPage';
import InternalTwoFactorMethodPage from '../../pages/TwoFactorInternal/InternalTwoFactorMethodPage';
import ErrorHeader from '../../pages/Error/images/header.svg';
import ForgotPasswordHeaderImg from '../../pages/ForgotPassword/images/header.svg';
import ForgotPasswordConfirmHeaderImg from '../../pages/ForgotPasswordConfirmation/images/header.svg';
import ResetPasswordHeaderImg from '../../pages/ResetPassword/images/header.svg';
import DeviceVerificationHeaderImg from '../../pages/DeviceVerification/images/header.svg';
import TwoFactorHeaderImg from '../../pages/TwoFactor/images/header.svg';
import BlockedHeaderImg from '../../pages/Blocked/images/header.svg';
import LockoutHeaderImg from '../../pages/Lockout/images/header.svg';
import DeviceVerificationNtHeaderImg from '../../pages/DeviceVerification/images/new-header.svg';
import { IPage } from '../../components/PageWrapper/types';
import { asI18nKey, I18nKey } from '../utilities/Localization';
import { PageTemplate } from '../../components/PageTemplates/types';
import PhonePromptPage from "../../pages/DeviceVerification/PhonePromptPage";
import {Footers} from "../../components/Footers/types";
import EmailSentPage from "../../pages/DeviceVerification/EmailSentPage";
import VerifyCodePage from "../../pages/DeviceVerification/VerifyCodePage";
import UpdatePhonePage from "../../pages/DeviceVerification/UpdatePhonePage";

export interface IPageConfig {
    title: I18nKey;
    routes: Array<string>;
    component: FunctionComponent<IPage>;
    template: keyof typeof PageTemplate;
    footer: Footers;
    authenticationState?: keyof typeof AuthenticationState;
    trackingPageName?: string;
    trackingCategory?: string;
    headerImageHeight?: string;
    headerImage?: string;
    showFooter?: boolean;
    maxContentWidth?: number;
}

export enum IdentityPage {
    Error,
    Login,
    Sso,
    ForgotPassword,
    ForgotPasswordConfirmation,
    ResetPassword,
    DeviceVerification,
    DeviceVerificationPrompt,
    DeviceVerificationEmailSent,
    DeviceVerificationVerify,
    DeviceVerificationUpdate,
    Blocked,
    Lockout,
    InternalTwoFactor,
    InternalTwoFactorMethod,
    TwoFactor
}

export enum AuthenticationState {
    Irrelevant,
    Authenticated,
    Unauthenticated
}

export const homepage: keyof typeof IdentityPage = 'Login';

const pagesConfig: Record<keyof typeof IdentityPage, IPageConfig> = {
    'Error': {
        title: asI18nKey('error_page.page_title'),
        routes: [ '/error/:code' ],
        component: ErrorPage,
        template: 'Identity',
        footer: 'Registration',
        trackingPageName: 'Error',
        headerImage: ErrorHeader
    },
    'Login': {
        title: asI18nKey('login.page_title'),
        routes: [ '/account/login' ],
        component: LoginPage,
        template: 'Identity',
        footer: 'Registration',
        trackingPageName: 'Login',
        trackingCategory: 'Login',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'Sso': {
        title: asI18nKey('login_sso.page_title'),
        routes: [ '/account/login/sso' ],
        component: SsoPage,
        template: 'Identity',
        trackingPageName: 'LoginSSO',
        trackingCategory: 'Login',
        footer: 'Registration',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'ForgotPassword': {
        title: asI18nKey('forgot_password.page_title'),
        routes: [ '/account/password/forgot' ],
        component: ForgotPasswordPage,
        template: 'Identity',
        trackingPageName: 'ForgotPassword',
        trackingCategory: 'Password reset',
        footer: 'Registration',
        headerImage: ForgotPasswordHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'ForgotPasswordConfirmation': {
        title: asI18nKey('forgot_password.confirm.page_title'),
        routes: [ '/account/password/forgot/confirm' ],
        component: ForgotPasswordConfirmationPage,
        template: 'Identity',
        trackingPageName: 'ForgotPasswordConfirmation',
        headerImage: ForgotPasswordConfirmHeaderImg,
        footer: 'Registration',
        authenticationState: 'Unauthenticated'
    },
    'ResetPassword': {
        title: asI18nKey('reset_password.page_title'),
        routes: [ '/account/password/reset' ],
        component: ResetPasswordPage,
        template: 'Identity',
        trackingPageName: 'ResetPassword',
        trackingCategory: 'Set up password',
        headerImage: ResetPasswordHeaderImg,
        footer: 'Registration',
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerification': {
        title: asI18nKey('device_verification.page_title'),
        routes: [ '/account/device-verification' ],
        component: DeviceVerificationPage,
        template: 'Identity',
        trackingPageName: 'DeviceAuthentication',
        trackingCategory: 'New device detected',
        headerImage: DeviceVerificationHeaderImg,
        footer: 'Registration',
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerificationVerify': {
        title: asI18nKey('device_verification.verify.page_title'),
        routes: [ '/account/device-verification/verify' ],
        component: VerifyCodePage,
        template: 'Identity',
        trackingPageName: 'DeviceVerificationVerify',
        trackingCategory: 'Verify code',
        headerImageHeight: 'auto',
        headerImage: DeviceVerificationNtHeaderImg,
        footer: 'TipFooter',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerificationEmailSent': {
        title: asI18nKey('device_verification.email.page_title'),
        routes: [ '/account/device-verification/email-sent' ],
        component: EmailSentPage,
        template: 'Identity',
        trackingPageName: 'DeviceVerificationEmailSent',
        trackingCategory: 'Update Phone Email Sent',
        headerImageHeight: 'auto',
        headerImage: DeviceVerificationNtHeaderImg,
        footer: 'TipFooter',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerificationPrompt': {
        title: asI18nKey('device_verification.prompt.page_title'),
        routes: [ '/account/device-verification/prompt' ],
        component: PhonePromptPage,
        template: 'Identity',
        trackingPageName: 'DeviceVerificationPrompt',
        trackingCategory: 'New device detected',
        headerImageHeight: 'auto',
        headerImage: DeviceVerificationNtHeaderImg,
        footer: 'TipFooter',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerificationUpdate': {
        title: asI18nKey('device_verification.update.page_title'),
        routes: [ '/account/device-verification/update' ],
        component: UpdatePhonePage,
        template: 'Identity',
        trackingPageName: 'DeviceVerificationUpdate',
        trackingCategory: 'Update Phone Number',
        headerImageHeight: 'auto',
        headerImage: DeviceVerificationNtHeaderImg,
        footer: 'TipFooter',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'TwoFactor': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa' ],
        component: TwoFactorPage,
        template: 'Identity',
        trackingPageName: 'TwoFactorAuthentication',
        trackingCategory: 'Two factor authentication',
        headerImage: TwoFactorHeaderImg,
        footer: 'Registration',
        authenticationState: 'Unauthenticated'
    },
    'InternalTwoFactor': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa/internal' ],
        component: InternalTwoFactorPage,
        template: 'Card',
        maxContentWidth: 340,
        footer: 'Registration',
        showFooter: true,
        authenticationState: 'Authenticated'
    },
    'InternalTwoFactorMethod': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa/internal/:method' ],
        component: InternalTwoFactorMethodPage,
        template: 'Card',
        footer: 'Registration',
        maxContentWidth: 340,
        showFooter: true,
        authenticationState: 'Authenticated'
    },
    'Blocked': {
        title: asI18nKey('blocked.page_title'),
        routes: [ '/account/blocked' ],
        component: BlockedPage,
        template: 'Identity',
        footer: 'Registration',
        trackingPageName: 'Blocked',
        headerImage: BlockedHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'Lockout': {
        title: asI18nKey('lockout.page_title'),
        routes: [ '/account/lockout' ],
        component: LockoutPage,
        template: 'Identity',
        footer: 'Registration',
        trackingPageName: 'Lockout',
        headerImage: LockoutHeaderImg,
        authenticationState: 'Unauthenticated'
    }
};

export default pagesConfig;
