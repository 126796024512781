import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { IPolicyHintRow, SatisfactionState } from './types';
import I18nContext from '../../common/context/I18nContext';
import { Container, HintRow, HintText, List, SatisfiedIconContainer, Title } from './PasswordPolicyHintContent.style';
import CheckedIco from './images/checked.svg';
import LoaderIco from './images/loader.svg';
import UncheckedIco from './images/unchecked.svg';
import { asI18nKey } from '../../common/utilities/Localization';
import I18n from '../I18n/I18n';
import useAPI from '../../common/hooks/UseAPI';
import {
    existsInPastPasswords,
    IPasswordPolicy,
    parsePolicyToRegex,
    PasswordPolicyClause
} from '../../common/utilities/PasswordPolicy';

interface IPasswordPolicyHintContent {
    policy: IPasswordPolicy;
    passwordValue: string;
    userEmail?: string;
    resetPasswordToken?: string;
    onSatisfactionStateChange?: (newState: SatisfactionState) => void;
}

const PasswordPolicyHintContent: FunctionComponent<IPasswordPolicyHintContent> = props => {
    const { translate } = useContext(I18nContext);
    const api = useAPI();
    const username = useMemo<string | undefined>(() => props.userEmail?.split('@')[0], [props.userEmail]);
    const [lastSatisfactionState, setLastSatisfactionState] = useState<SatisfactionState>(SatisfactionState.InProgress);
    const [debounceState, setDebounceState] = useState<boolean>(false);
    const [debounceTimeout, setDebounceTimeout] = useState<number>();
    const [satisfactionIcons, setSatisfactionIcons] = useState<Record<PasswordPolicyClause, string>>({
        [PasswordPolicyClause.Length]: LoaderIco,
        [PasswordPolicyClause.Digit]: LoaderIco,
        [PasswordPolicyClause.Lowercase]: LoaderIco,
        [PasswordPolicyClause.Uppercase]: LoaderIco,
        [PasswordPolicyClause.SpecialCharacter]: LoaderIco,
        [PasswordPolicyClause.DistinctCharacters]: LoaderIco,
        [PasswordPolicyClause.UsernameForbidden]: LoaderIco,
        [PasswordPolicyClause.PastPasswords]: LoaderIco
    });

    const regex = useMemo<Record<PasswordPolicyClause, RegExp>>(() => {
        return parsePolicyToRegex(props.policy, username);
    }, [props.policy, username]);

    const rows: Record<PasswordPolicyClause, IPolicyHintRow> = ({
        [PasswordPolicyClause.Length]: {
            hidden: props.policy.requiredLength <= 1,
            text: translate(asI18nKey('reset_password.field.password.hint.length'), {length: props.policy.requiredLength}),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.Length].test(value))
        },
        [PasswordPolicyClause.Digit]: {
            hidden: !props.policy.requireDigit,
            text: translate(asI18nKey('reset_password.field.password.hint.digit')),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.Digit].test(value))
        },
        [PasswordPolicyClause.Lowercase]: {
            hidden: !props.policy.requireLowercase,
            text: translate(asI18nKey('reset_password.field.password.hint.lowercase')),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.Lowercase].test(value))
        },
        [PasswordPolicyClause.Uppercase]: {
            hidden: !props.policy.requireUppercase,
            text: translate(asI18nKey('reset_password.field.password.hint.uppercase')),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.Uppercase].test(value))
        },
        [PasswordPolicyClause.SpecialCharacter]: {
            hidden: !props.policy.requireNonAlphanumeric,
            text: translate(asI18nKey('reset_password.field.password.hint.special')),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.SpecialCharacter].test(value))
        },
        [PasswordPolicyClause.DistinctCharacters]: {
            hidden: props.policy.requiredUniqueChars <= 1,
            text: translate(asI18nKey('reset_password.field.password.hint.unique'), {distinct: props.policy.requiredUniqueChars}),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.DistinctCharacters].test(value))
        },
        [PasswordPolicyClause.UsernameForbidden]: {
            hidden: !props.policy.rejectPartsOfUsername,
            text: translate(asI18nKey('reset_password.field.password.hint.username')),
            checkSatisfaction: async value => Promise.resolve(regex[PasswordPolicyClause.UsernameForbidden].test(value))
        },
        [PasswordPolicyClause.PastPasswords]: {
            hidden: props.policy.requiredDifferentOldPasswords < 1,
            text: translate(asI18nKey('reset_password.field.password.hint.last_x_passwords'), {amount: props.policy.requiredDifferentOldPasswords}),
            checkSatisfaction: async value => {
                let fullySatisfied = Object.values(regex).every(x => x.test(value));
                if (!fullySatisfied) return false;

                return new Promise<boolean>(resolve => {
                    api(() => existsInPastPasswords(
                        props.userEmail ?? '',
                        value,
                        props.policy.requiredDifferentOldPasswords,
                        props.resetPasswordToken ?? ''
                    ), {
                        onSuccess: ({ data }) => resolve(!data),
                        onError: () => resolve(false)
                    });
                })
            },
            debounce: true
        }
    });

    //manage debounce
    useEffect(() => {
        setDebounceState(true);
        debounceTimeout && clearTimeout(debounceTimeout);
        setDebounceTimeout(undefined);
        let timeout = setTimeout(() => setDebounceState(false), 1000);
        setDebounceTimeout(timeout);

        validateSatisfaction(true);
    }, [props.passwordValue]);

    //check satisfaction
    useEffect(() => { validateSatisfaction(debounceState); }, [debounceState]);

    //invoke satisfaction events to parent
    useEffect(() => {
        let inProgress = Object.values(PasswordPolicyClause).some(x => !rows[x].hidden && satisfactionIcons[x] === LoaderIco);
        let nowSatisfied = !inProgress && Object.values(PasswordPolicyClause).every(x => rows[x].hidden || satisfactionIcons[x] === CheckedIco);
        let currentSatisfaction;

        if (inProgress) currentSatisfaction = SatisfactionState.InProgress;
        else if (nowSatisfied) currentSatisfaction = SatisfactionState.Satisfied;
        else currentSatisfaction = SatisfactionState.Dissatisfied;

        if (currentSatisfaction !== lastSatisfactionState) {
            setLastSatisfactionState(currentSatisfaction);
            props.onSatisfactionStateChange?.(currentSatisfaction);
        }
    }, [props.policy, satisfactionIcons]);

    /**
     * Validate the satisfaction of all relevant conditions.
     *
     * @param {boolean} isDebounceActive - True if the debounce state is currently active
     */
    const validateSatisfaction = async (isDebounceActive: boolean = false): Promise<void> => {
        for (let clause of Object.values(PasswordPolicyClause)) {
            let row = rows[clause as PasswordPolicyClause];

            if (rows[clause].debounce && isDebounceActive) {
                setSatisfactionIcons(prev => ({
                    ...prev,
                    [clause]: LoaderIco
                }));
            }
            else {
                row.checkSatisfaction(props.passwordValue).then(res => {
                    setSatisfactionIcons(prev => ({
                        ...prev,
                        [clause]: res ? CheckedIco : UncheckedIco
                    }));
                });
            }
        }
    }

    return (
        <Container>
            <Title>
                <I18n>reset_password.field.password.hint.title</I18n>
            </Title>
            <List>
                {Object.values(PasswordPolicyClause).map(clause => {
                    if (rows[clause].hidden) return null;
                    let icon = satisfactionIcons[clause];

                    return (
                        <HintRow key={clause}>
                            <SatisfiedIconContainer
                                src={icon}
                                spin={icon === LoaderIco}
                                alt={''}
                            />
                            <HintText>{rows[clause].text}</HintText>
                        </HintRow>
                    )
                })}
            </List>
        </Container>
    );
}

export default PasswordPolicyHintContent;