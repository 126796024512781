import { minmaxCharactersConfig, predefinedConfigs } from './InputVerification';
import { negate, escape } from './Regex';
import { IServerResponse } from '../../common/utilities/API/types';
import fetchService from '../../common/utilities/API/FetchService';

export interface IPasswordPolicy {
    requiredLength: number;
    requireDigit: boolean;
    requireLowercase: boolean;
    requireUppercase: boolean;
    requireNonAlphanumeric: boolean;
    requiredUniqueChars: number;
    rejectPartsOfUsername: boolean;
    requiredDifferentOldPasswords: number;
}

export enum PasswordPolicyClause {
    Length = 'length',
    Digit = 'digit',
    Lowercase = 'lowercase',
    Uppercase = 'uppercase',
    SpecialCharacter = 'specialCharacter',
    DistinctCharacters = 'distinctCharacters',
    UsernameForbidden = 'usernameForbidden',
    PastPasswords = 'pastPasswords'
}

const anyRegex = predefinedConfigs.Any.pattern[0];

/**
 * Build a complex regular expression that determines a value
 * must include a minimum amount of distinct characters.
 *
 * @param {number} amount - The minimum amount of distinct characters
 * @returns {RegExp} The corresponding regular expression.
 */
const buildDistinctCharsRegex = (amount: number): RegExp => {
    if (amount <= 1) return anyRegex;

    let backtrack = '';
    let requiredDistinct = '^(.)';

    for (let i = 2; i <= amount; i++) {
        let backtrackPrefix = (i > 2) ? '|' : '';
        backtrack += `${backtrackPrefix}\\{i - 1}`;
        requiredDistinct += `.*(?!${backtrack})(.)`;
    }

    requiredDistinct += '$';
    return new RegExp(requiredDistinct);
}

/**
 * @param {IPasswordPolicy} policy - A password policy to parse
 * @param {string | undefined} username - The user's username
 * @param {boolean} onlyNonEmptyConditions - True to only return the conditions that are not empty (empty: /^.*$/)
 * @returns {Array<RegExp>} A list of regular expressions that must all be met according to the given policy.
 */
export const parsePolicyToRegex = (
    policy: IPasswordPolicy,
    username?: string,
    onlyNonEmptyConditions: boolean = false
): Record<PasswordPolicyClause, RegExp> => {
    let parsed = {
        [PasswordPolicyClause.Length]: policy.requiredLength ? minmaxCharactersConfig(policy.requiredLength).pattern[0] : anyRegex,
        [PasswordPolicyClause.Digit]: policy.requireDigit ? /^.*\d.*$/ : anyRegex,
        [PasswordPolicyClause.Lowercase]: policy.requireLowercase ? /^.*[a-z].*$/ : anyRegex,
        [PasswordPolicyClause.Uppercase]: policy.requireUppercase ? /^.*[A-Z].*$/ : anyRegex,
        [PasswordPolicyClause.SpecialCharacter]: policy.requireNonAlphanumeric ? /^.*[^a-zA-Z\d].*$/ : anyRegex,
        [PasswordPolicyClause.DistinctCharacters]: policy.requiredUniqueChars ? buildDistinctCharsRegex(policy.requiredUniqueChars) : anyRegex,
        [PasswordPolicyClause.UsernameForbidden]: policy.rejectPartsOfUsername && !!username ? negate(escape(username), 'i') : anyRegex,
        [PasswordPolicyClause.PastPasswords]: anyRegex
    };

    if (!onlyNonEmptyConditions) return parsed;
    else {
        let relevantEntries = Object.entries(parsed).filter(([, value]) => value !== anyRegex);
        return Object.fromEntries(relevantEntries) as Record<PasswordPolicyClause, RegExp>;
    }
}

/**
 * Check if a password has already been used by the user in the past.
 *
 * @param {string} email - The user's email address
 * @param {string} password - The password value
 * @param {number} n - The amount of past passwords against which to check
 * @param {string} token - The password reset token received from the server
 * @returns {Promise<IServerResponse<boolean>>} True if the passwords has already been used within the user's last n passwords.
 */
export const existsInPastPasswords = async (
    email: string,
    password: string,
    n: number,
    token: string
): Promise<IServerResponse<boolean>> =>
    fetchService.post<boolean>('/account/password/password-is-one-of-last-passwords', {
        email,
        password,
        lastPasswords: n,
        token
    });