import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../../common/config/ThemeConfig';
import LeftBackgroundImage from './images/background-image-left.svg';
import RightBackgroundImage from './images/background-image-right.svg';

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-position: top left, bottom right;
    background-repeat: no-repeat;
    background-image: url(${LeftBackgroundImage}),
    url(${RightBackgroundImage}),
    radial-gradient(265.34% 118.59% at 13.13% 70.6%, #ff7a1a33 0%, #fff 100%),
    radial-gradient(68.19% 98.23% at 85.31% 93.52%, #0073ff66 40%, #00000000 100%),
    radial-gradient(1898.59% 1872.82% at 68.48% 1.39%, #ecf1fb 0%, #f8f8f8 100%);
`;

export const Layout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 500px;
    max-width: 90%;
    margin: 75px auto 50px;
    border-radius: 8px;
    background-color: ${colorsPalettes.carbon[0]};
    box-shadow: 0 5px 24px rgb(9 37 64 / 6%), 0 1px 8px rgb(9 37 64 / 3%)
`;

export const ContentContainer = styled.div`
    display: table-row;
    width: 100%;
`;

export const Content = styled.div`
    position: relative;
    padding: 95px 40px 40px 40px;
    min-height: 400px;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        padding: 100px 0 50px 0;
    }
`;

export const LanguagesDropdownContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 10;
`;

export const AppLogo = styled.a`
    position: absolute;
    top: 26px;
    left: 4%;

    & > img {
        width: 140px;
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
    }
`;

export const HeaderImg = styled.img<{
    headerImageHeight?: string;
}>`
    height: ${props => props.headerImageHeight || '170px'};
`;

export const Title = styled.h2`
    padding: 0 20px 0 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.125rem;
    font-weight: 700;
    text-align: center;
    color: ${colorsPalettes.carbon[400]};
`;
