/**
 * @returns {TState} An object consisting of values passed from the server.
 */
const useServerData = <TState extends Record<string, any>>(): TState => {
    const w = window as any;
    const swData = w.swData ?? {};

    return { ...(swData as TState) };
}

export default useServerData;