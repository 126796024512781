import { FunctionComponent } from 'react';
import { Container, LegalLink } from './LegalSection.style';
import { Alignment } from './types';
import I18n from '../I18n/I18n';

interface ILegalSection {
    prefix?: string;
    align?: keyof typeof Alignment;
}

const LegalSection: FunctionComponent<ILegalSection> = props => {
    const legalPage = 'https://www.similarweb.com/corp/legal';

    return (
        <Container align={props.align || 'center'}>
            {!!props.prefix && (
                <p>{props.prefix}</p>
            )}
            <LegalLink
                className={'accessible'}
                href={`${legalPage}/terms`}
                target={'_blank'}
            >
                <I18n>legal.terms_of_use</I18n>
            </LegalLink>
            {!!props.prefix && (
                <span>
                    {' '}
                    <I18n>legal.and</I18n>
                    {' '}
                </span>
            )}
            <LegalLink
                className={'accessible'}
                href={`${legalPage}/privacy-policy`}
                target={'_blank'}
            >
                <I18n>legal.privacy_policy</I18n>
            </LegalLink>
        </Container>
    );
}

export default LegalSection;