import { FocusEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import LanguageOption from './Option/LanguageOption';
import useCookie from '../../common/hooks/UseCookie';
import languages, { defaultLanguage, ILanguage } from '../../common/config/LanguageConfig';
import { fallbackLocale } from '../../common/config/LocalizationConfig';
import {
    Container,
    SelectedLangContainer,
    DelimiterContainer,
    Delimiter,
    DropdownContainer
} from './LanguagesDropdown.style';

interface ILanguagesDropdown {
    onChange?: (language: string) => void;
}

const LanguagesDropdown: FunctionComponent<ILanguagesDropdown> = props => {
    const [localeCookie, setLocaleCookie] = useCookie('locale', fallbackLocale);
    const [isOpen, setOpen] = useState<boolean>(false);
    const currentOption = useMemo<ILanguage>(() => languages.find(x => x.cookieCode === localeCookie) ?? defaultLanguage, [localeCookie]);
    const altOptions = useMemo<Array<ILanguage>>(() => languages.filter(x => x.name !== currentOption.name), [currentOption]);
    const [focusedOption, setFocusedOption] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => { !isOpen && setFocusedOption(0); }, [isOpen]);

    /**
     * Activate when a different language is selected.
     * This function changes the application's locale cookie.
     *
     * @param {string} langCode - The selected locale code
     */
    const onLanguageSelection = (langCode: string): void => {
        setLocaleCookie(langCode);
        setOpen(false);
        props.onChange?.(langCode.split('-')?.[0] || langCode);
    }

    /**
     * Activate when the dropdown element tries to lose focus.
     * This function checks whether it lost its focus to a child element,
     * and if so, it does nothing.
     */
    const onBlur = (ev: FocusEvent<HTMLDivElement>): void => {
        let container = containerRef?.current;
        let nextEl = ev.relatedTarget;
        let elContained = container?.contains?.(nextEl);

        if (!elContained) setOpen(false);
    }

    return (
        <Container
            ref={containerRef}
            className={'accessible-tight'}
            tabIndex={0}
            open={isOpen}
            onBlur={onBlur}
            onKeyPress={ev => ev.key === 'Enter' && setOpen(prevState => !prevState)}
        >
            <SelectedLangContainer onClick={() => setOpen(prevState => !prevState)}>
                <LanguageOption
                    name={currentOption.name}
                    flag={currentOption.base64Flag}
                    language={currentOption.cookieCode}
                    isSelected={true}
                    dropdownOpen={isOpen}
                />
            </SelectedLangContainer>
            <DelimiterContainer>
                <Delimiter />
            </DelimiterContainer>
            <DropdownContainer
                open={isOpen}
                amount={altOptions?.length || 0}
                displayedAmount={6}
            >
                {altOptions?.map((option, index) => (
                    <LanguageOption
                        key={option.cookieCode}
                        name={option.name}
                        flag={option.base64Flag}
                        language={option.cookieCode}
                        isSelected={false}
                        dropdownOpen={isOpen}
                        onSelect={onLanguageSelection}
                        shouldFocus={index === focusedOption}
                        onKeyUpPress={() => setFocusedOption(prev => Math.max(prev - 1, 0))}
                        onKeyDownPress={() => setFocusedOption(prev => Math.min(prev + 1, altOptions.length - 1))}
                    />
                ))}
            </DropdownContainer>
        </Container>
    );
}

export default LanguagesDropdown;