import { FunctionComponent, KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { Container, Flag, LabelContainer, Label, Arrow } from './LanguageOption.style';
import ArrowIco from '../images/arrow.svg';

interface ILanguageOption {
    name: string;
    flag: string;
    language: string;
    isSelected: boolean;
    dropdownOpen: boolean;
    onSelect?: (langCode: string) => void;
    shouldFocus?: boolean;
    onKeyUpPress?: () => void;
    onKeyDownPress?: () => void;
}

const LanguageOption: FunctionComponent<ILanguageOption> = props => {
    const ref = useRef<HTMLLIElement>(null);
    const focusable = useMemo<boolean>(() => props.dropdownOpen && !props.isSelected, [props.dropdownOpen, props.isSelected]);

    useEffect(() => { props.shouldFocus && ref?.current?.focus?.(); }, [props.shouldFocus]);

    /**
     * Activate when a key is pressed while the options is focused.
     * This function either navigates to neighbour options (using the up/down arrows keys),
     * or selects this option (using the Enter key).
     *
     * @param {KeyboardEvent<HTMLLIElement>} ev - Default event object
     */
    const onKeyDown = (ev: KeyboardEvent<HTMLLIElement>): void => {
        if (ev.key !== 'Tab') ev.preventDefault();

        switch (ev.key) {
            case 'Enter': return props.onSelect?.(props.language);
            case 'ArrowUp': return props.onKeyUpPress?.();
            case 'ArrowDown':
            case 'Tab': return props.onKeyDownPress?.();
        }
    }

    return (
        <Container
            ref={ref}
            className={'accessible-tight'}
            selected={props.isSelected}
            onClick={() => props.onSelect?.(props.language)}
            onKeyDown={onKeyDown}
            {...(focusable ? { tabIndex: 0 } : {})}
        >
            <Flag
                src={props.flag}
                alt={props.name}
            />
            <LabelContainer>
                <Label>{props.name}</Label>
            </LabelContainer>
            {props.isSelected && (
                <Arrow
                    src={ArrowIco}
                    alt={''}
                    visible={!props.dropdownOpen}
                />
            )}
        </Container>
    );
}

export default LanguageOption;