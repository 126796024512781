import { FunctionComponent, useLayoutEffect, useMemo, useState } from 'react';
import TextField from '../../components/Fields/TextField/TextField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import { IPage } from '../../components/PageWrapper/types';
import I18n from '../../components/I18n/I18n';
import { setUpPassword } from './ResetPasswordService';
import useIdentityParams from '../../common/hooks/Parameters/UseIdentityParams';
import { IPasswordPolicy } from '../../common/utilities/PasswordPolicy';
import PasswordPolicyHintContent from '../../components/PasswordPolicyHintContent/PasswordPolicyHintContent';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { asI18nKey } from '../../common/utilities/Localization';
import useAPI from '../../common/hooks/UseAPI';
import usePageAccessGuard from '../../common/hooks/UsePageAccessGuard';
import { ServerError } from '../../common/utilities/API/types';
import { AlertType } from '../../components/AlertToast/types';
import { ResetType } from './types';
import {
    ContactSupport,
    Instructions,
    PasswordField,
    Troubleshoot,
    TroubleshootContainer
} from './ResetPasswordPage.style';
import { SatisfactionState } from '../../components/PasswordPolicyHintContent/types';
import SupportModal from "../../components/SupportModal/SupportModal";

const ResetPasswordPage: FunctionComponent<IPage> = props => {
    const api = useAPI();
    const { navigate, redirectToReturnUrl } = useIdentityNavigation();
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false);
    const { routerParams, serverParams, allParams } = useIdentityParams<{
        passwordpolicy?: IPasswordPolicy,
        email: string;
        token: string;
        resettype?: ResetType;
        tokenvalid?: boolean;
    }>();

    const [policySatisfactionState, setPolicySatisfactionState] = useState<SatisfactionState>(
        allParams.passwordpolicy
            ? SatisfactionState.InProgress
            : SatisfactionState.Satisfied
    );

    const currentPasswordVal = useMemo<string>(() => {
        return (formInputs?.find(x => x.name === 'password')?.value || '').toString();
    }, [formInputs]);

    const repeatPatten = useMemo<RegExp>(() => {
        let passwordPattern = currentPasswordVal.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        return new RegExp(`^${passwordPattern}$`);
    }, [currentPasswordVal]);

    usePageAccessGuard(!allParams.email || !allParams.token || (!serverParams.tokenvalid && !routerParams.tokenvalid));

    //set the appropriate page title
    useLayoutEffect(() => {
        if (allParams.resettype !== ResetType.ResetRequest) {
            let title = props.translate(asI18nKey('reset_password.page_title.forced'));
            props.setTitle(title);
        }
    }, []);

    /**
     * Activate when the page's form is submitted.
     * This function replaces the user's old password with a new one of his choice.
     *
     * @param {string} password - The user's password
     * @returns {Promise<boolean>} True if the submission is successful.
     */
    const onSubmit = async ({ password }: Record<string, string>): Promise<boolean> => {
        let email = allParams.email;
        let token = allParams.token;

        if (!email || !token) {
            props.onServerAlert(props.translate(asI18nKey('reset_password.error.invalid_request')));
            return false;
        }

        return await api(() => setUpPassword(email!, password, token!), {
            errorsMap: () => [
                {
                    error: ServerError.InvalidToken,
                    message: props.translate(asI18nKey('reset_password.error.invalid_token'))
                },
                {
                    error: ServerError.NewPasswordNotDifferent,
                    message: props.translate(asI18nKey('reset_password.error.force_same_password'))
                }
            ],
            onSuccess: async ({ status }) => {
                switch (status) {
                    case 200:
                        props.disablePage();
                        await redirectToReturnUrl();
                        break;

                    case 211:
                        navigate('Login');
                        break;
                }
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            }
        });
    }

    return (
        <>
            <Instructions>
                <I18n>reset_password.subtitle.{allParams.resettype?.toLowerCase()}</I18n>
            </Instructions>
            <PasswordField>
                <TextField
                    name={'password'}
                    type={'Password'}
                    label={props.translate(asI18nKey('reset_password.field.password.label'))}
                    required
                    focusOnPageLoad
                    forcedErrorMessage={currentPasswordVal.length && policySatisfactionState === SatisfactionState.Dissatisfied
                        ? props.translate(asI18nKey('reset_password.field.password.warning'))
                        : ''
                    }
                    shouldVerify={inputsVerificationTrigger.active}
                    trackingCategory={props.trackingCategory}
                    onChange={setInput}
                    onEnterPress={submissionTrigger.fire}
                    {...(allParams.passwordpolicy ? {
                        hint: (
                            <PasswordPolicyHintContent
                                policy={allParams.passwordpolicy}
                                passwordValue={currentPasswordVal}
                                userEmail={allParams.email}
                                resetPasswordToken={allParams.token}
                                onSatisfactionStateChange={state => setPolicySatisfactionState(state)}
                            />
                        )
                    } : {})}
                />
            </PasswordField>
            <TextField
                name={'repeatPassword'}
                type={'Password'}
                label={props.translate(asI18nKey('reset_password.field.confirm_password.label'))}
                required
                verificationConfig={{
                    pattern: [ repeatPatten ],
                    customError: props.translate(asI18nKey('reset_password.field.confirm_password.warning'))
                }}
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={props.trackingCategory}
                onChange={setInput}
                onEnterPress={submissionTrigger.fire}
            />
            <SubmitButton
                onSuccess={onSubmit}
                formInputs={formInputs}
                onFailedVerification={inputsVerificationTrigger.fire}
                trackingCategory={props.trackingCategory}
                trackingComponentName={'set up password'}
                shouldSubmit={submissionTrigger.active}
                disabled={policySatisfactionState !== SatisfactionState.Satisfied}
            >
                <I18n>reset_password.button</I18n>
            </SubmitButton>
            <LinkParagraph
                prefix={props.translate(asI18nKey('reset_password.login'))}
                linkLabel={props.translate(asI18nKey('reset_password.login.action'))}
                targetPage={'Login'}
            />
            <TroubleshootContainer>
                <Troubleshoot>
                    <I18n>reset_password.support.{allParams.resettype?.toLowerCase()}</I18n>
                </Troubleshoot>
                <ContactSupport onClick={() => setSupportModalOpen(true)}>
                    <I18n>reset_password.support.action</I18n>
                </ContactSupport>
            </TroubleshootContainer>
            <SupportModal
                isOpen={supportModalOpen}
                onClose={() => setSupportModalOpen(false)}
            />
        </>
    );
}

export default ResetPasswordPage;
