import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const IllustrationContainer = styled.div`
    margin-bottom: 30px;
`;

export const Title = styled.h1`
    margin: 0 0 15px -2px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.12;
`;

export const Subtitle = styled.h2`
    margin-top: -10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${colorsPalettes.carbon[400]};
`;