import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../common/config/ThemeConfig';

export const Title = styled.h1`
    text-align: left;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0 0 15px -2px;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        text-align: center;
        font-size: 1.5rem;
    }
`;

export const IllustrationContainer = styled.div`
    margin: 20px auto;
`;

export const SubtitleContainer = styled.div`
    text-align: left;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        text-align: center;
    }
`;

export const Subtitle = styled.h2`
    display: inline;
    font-size: 1rem;
    font-weight: 400;
    color: ${colorsPalettes.carbon[400]};
`;

export const SupportLink = styled.p`
    display: inline;
    color: ${colorsPalettes.sky[500]};
    cursor: pointer;
    transition: .2s;
    
    &:hover {
        color: ${colorsPalettes.blue[500]};
    }
`;

export const ButtonsSection = styled.div`
    --right: 10px;
  
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    margin: auto 10px;
    bottom: 15px;
    right: 0;
    left: 0;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        justify-content: center;
    }
`;

export const Button = styled.button`
    margin: auto 5px;
    border: none;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
    font-size: .875rem;
    cursor: pointer;
`;

export const DismissButton = styled(Button)`
    background: none;
    color: ${colorsPalettes.blue[400]};
    transition: .2s;
  
    &:hover:not(:active) {
        color: ${colorsPalettes.blue[500]};
    }
`;

export const RefreshButton = styled(Button)`
    width: 120px;
    height: 36px;
    border-radius: 18px;
    color: ${colorsPalettes.carbon[0]};
    background-color: ${colorsPalettes.blue[400]};
    transition: .2s;
  
    &:hover:not(:active) {
        background-color: ${colorsPalettes.blue[500]};
    }
`;