import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Button = styled.button<{
    isLoading: boolean;
    width?: number;
}>`
    --cursor: pointer;
    --background-color: ${colorsPalettes.orange[400]};
  
    position: relative;
    width: ${props => props.width ? `${props.width}` : 70}%;
    height: 35px;
    border-radius: 50px !important;
    padding: 0 24px;
    margin: 30px auto 20px;
    cursor: var(--cursor);
    outline: none;
    border: none;
    font-size: .875rem;
    color: ${colorsPalettes.bluegrey[100]};
    background-color: var(--background-color);

    ${props => props.isLoading ? `
        --cursor: progress;
    ` : `
        &:disabled {
            --cursor: not-allowed;
            --background-color: ${colorsPalettes.carbon[50]};
        }
    `}  
  
    &:not(:disabled) {
        &:hover:not(:active) {
            --background-color: ${colorsPalettes.orange[300]};
        }
    }
`;

export const Loader = styled.div<{
    isLoading: boolean;
}>`
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    border-radius: 50px;
    background-color: ${colorsPalettes.orange[300]};

    @keyframes button-animation {
        0% {
            opacity: 0;
            clip-path: inset(0 0 0 0);
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            clip-path: inset(0 0 0 100%);
        }
    }

    ${props => props.isLoading && `
        visibility: visible;
        animation-name: button-animation;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    `}
`;

export const Label = styled.p`
    position: relative;
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    letter-spacing: .03em;
`;