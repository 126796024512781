import { HttpMethod, IOptions, RequestPayload, IIdentityResult, IServerResponse } from './types';
import { appendQueryParams, composeQueryString, constructUrl, deconstructUrl } from '../URL';

export class FetchService {
    /**
     * Send a GET request to the server.
     *
     * @template TResponse
     * @param {string} url - The request's path
     * @param {Record<string, string | number | boolean> | undefined} params - The request's query parameters
     * @param {IOptions} options - Any additional options to send with the request
     * @returns {Promise<IServerResponse<TResponse>>} The server's response.
     */
    public async get<TResponse>(
        url: string,
        params?: Record<string, string | number | boolean>,
        options?: IOptions
    ): Promise<IServerResponse<TResponse>> {
        let requestUrl = FetchService.composeRequestURL(url, params);
        let request = FetchService.composeRequest('GET', undefined, options);
        return FetchService.sendRequest<TResponse>(requestUrl, request);
    }

    /**
     * Send a POST request to the server.
     *
     * @template TResponse
     * @param {string} url - The request's path
     * @param {RequestPayload} payload - The request's body
     * @param {IOptions} options - Any additional options to send with the request
     * @returns {Promise<IServerResponse<TResponse>>} The server's response.
     */
    public async post<TResponse>(
        url: string,
        payload?: RequestPayload,
        options?: IOptions
    ): Promise<IServerResponse<TResponse>> {
        let requestUrl = FetchService.composeRequestURL(url);
        let request = FetchService.composeRequest('POST', payload, options);
        return FetchService.sendRequest<TResponse>(requestUrl, request);
    }

    /**
     * Send a PUT request to the server.
     *
     * @template TResponse
     * @param {string} url - The request's path
     * @param {RequestPayload} payload - The request's body
     * @param {IOptions} options - Any additional options to send with the request
     * @returns {Promise<IServerResponse<TResponse>>} The server's response.
     */
    public async put<TResponse>(
        url: string,
        payload: RequestPayload,
        options?: IOptions
    ): Promise<IServerResponse<TResponse>> {
        let requestUrl = FetchService.composeRequestURL(url);
        let request = FetchService.composeRequest('PUT', payload, options);
        return FetchService.sendRequest<TResponse>(requestUrl, request);
    }

    /**
     * Send a DELETE request to the server.
     *
     * @template TResponse
     * @param {string} url - The request's path
     * @param {Record<string, string | number | boolean> | undefined} params - The request's query parameters
     * @param {RequestPayload} payload - The request's body
     * @param {IOptions} options - Any additional options to send with the request
     * @returns {Promise<IServerResponse<TResponse>>} The server's response.
     */
    public async delete<TResponse>(
        url: string,
        params?: Record<string, string | number | boolean>,
        payload?: RequestPayload,
        options?: IOptions
    ): Promise<IServerResponse<TResponse>> {
        let requestUrl = FetchService.composeRequestURL(url, params);
        let request = FetchService.composeRequest('DELETE', payload, options);
        return FetchService.sendRequest<TResponse>(requestUrl, request);
    }

    /**
     * @param {string} url - The request's path
     * @param {Record<string, string | number | boolean> | undefined} params - Additional query parameters
     * @returns {string} A valid URL that contains all relevant query parameters
     */
    private static composeRequestURL(url: string, params?: Record<string, string | number | boolean>): string {
        let urlData = deconstructUrl(url);
        let hasValidQuery = urlData.isValid && !!urlData.query;
        let baseQueryParams = appendQueryParams([
            new URLSearchParams(window.location.search),
            ...(hasValidQuery ? [ new URLSearchParams(urlData.query) ] : [])
        ]);

        let query = composeQueryString(params, baseQueryParams);
        return constructUrl({ ...urlData, query });
    }

    /**
     * @param {keyof typeof HttpMethod} method - The HTTP request's method
     * @param {RequestPayload} payload - The request's body
     * @param {IOptions} options - Any additional options to send with the request
     * @returns {RequestInit} A request object, compatible with HTTP requests.
     */
    private static composeRequest(
        method: keyof typeof HttpMethod,
        payload?: RequestPayload,
        options?: IOptions
    ): RequestInit {
        return {
            ...options,
            method: method.toString(),
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'X-Requested-With': 'XMLHttpRequest',
                ...(options?.headers ?? {}),
                ...(window.antiforgery ?? {})
            },
            ...(payload ? { body: JSON.stringify(payload) } : {})
        };
    }

    /**
     * Parse a server response body into an object.
     *
     * @param {Response} response - A server's response
     * @returns {Promise<IIdentityResult>} A response object, or if it couldn't be parsed, a raw string.
     */
    private static async parseResponseBody<TResponse>(response: Response): Promise<IIdentityResult<TResponse>> {
        try {
            let resText = await response.text() || '';
            return await JSON.parse(resText) as IIdentityResult<TResponse>;
        }
        catch (ex) {
            return {
                success: false,
                errorMsg: ex as string,
            };
        }
    }

    /**
     * Send an HTTP request.
     *
     * @template TResponse
     * @param {string} url - The request's path
     * @param {RequestInit} request - The request's object
     * @returns {Promise<IServerResponse<TResponse>>} - The server's response
     */
    private static async sendRequest<TResponse>(url: string, request: RequestInit): Promise<IServerResponse<TResponse>> {
        let serverRes: Response = await fetch(url, request);
        let identityResult = await this.parseResponseBody<TResponse>(serverRes);
        return Promise.resolve({
            status: serverRes.status,
            result: identityResult
        });
    }
}

export default new FetchService();