import { useEffect, useMemo, useState } from 'react';

export interface ITrigger {
    active: boolean;
    fire: () => void;
}

/**
 * @param {number | undefined} offDelay - Amount of time [ms] to wait before
 *                                        turning the trigger off (defaults to 0)
 * @returns {ITrigger} {
 *      {boolean} active - True if the trigger is fired
 *      {() => void} fire - A function that fires the trigger once
 * }
 */
const useTrigger = (offDelay: number = 0): ITrigger => {
    const [state, setState] = useState<boolean>(false);
    const validDelay = useMemo<number>(() => Math.max(0, offDelay), [offDelay]);

    useEffect(() => {
        state && setTimeout(() => setState(false), validDelay);
    }, [state]);

    return {
        active: state,
        fire: () => setState(true)
    }
}

export default useTrigger;