import {FunctionComponent, useState} from "react";
import {IPage} from "../../components/PageWrapper/types";
import {Link, DeviceVerificationPageContainer, Phrase, SubTitle, InlinedText} from "./DeviceVerificationPage.style";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import I18n from "../../components/I18n/I18n";
import useIdentityNavigation from "../../common/hooks/UseIdentityNavigation";
import useIdentityParams from "../../common/hooks/Parameters/UseIdentityParams";
import useAPI from "../../common/hooks/UseAPI";
import {requestUpdatePhoneEmail} from "./DeviceVerificationService";
import {ServerError} from "../../common/utilities/API/types";
import {asI18nKey} from "../../common/utilities/Localization";
import {AlertType} from "../../components/AlertToast/types";
import useIdentityForm from "../../common/hooks/UseIdentityForm";
import usePageAccessGuard from "../../common/hooks/UsePageAccessGuard";
import FadeTransition from "../../components/FadeTransition/FadeTransition";

const PhonePromptPage: FunctionComponent<IPage> = (
    { trackingCategory, translate, onServerAlert, onUnexpectedError }
) => {
    const api = useAPI();
    const { logSubmissionFailure } = useIdentityForm();
    const { navigate } = useIdentityNavigation();
    const [isSendingMail, setIsSendingMail] = useState<boolean>(false);
    const { routerParams, allParams } = useIdentityParams<{
        maskedphonenumber: string;
        email: string;
    }>();

    usePageAccessGuard(!routerParams.maskedphonenumber || !routerParams.email );

    const onSubmit = () => navigate('DeviceVerificationVerify', undefined, allParams);

    const onSendEmailHandler = async () => {
        setIsSendingMail(true);
        await api(() => requestUpdatePhoneEmail(), {
            errorsMap: () => [
                {
                    error: ServerError.PhoneNumberUpdateNotAllowed,
                    message: translate(asI18nKey('device_verification.error.update_phone_limit_lock'))
                }
            ],
            onSuccess: async ({ status }) => {
                switch (status) {
                    case 200:
                        navigate('DeviceVerificationEmailSent', undefined, allParams);
                        break;
                }
            },
            onError: ({ message, forceRefresh, mappedHandler }) => {
                setIsSendingMail(false);
                if (message === ServerError.IncorrectCredentials) {
                    logSubmissionFailure(message);
                    return navigate('Login');
                }
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else onUnexpectedError(forceRefresh);
            }
        });
    }

    const renderSendEmailLink = () => {
        if (isSendingMail) {
            return <>Sending . . .</>
        }
        return <Link onMouseDown={onSendEmailHandler}><I18n>device_verification.prompt.update_phone_link_text</I18n></Link>;
    }

    return <DeviceVerificationPageContainer>
        <SubTitle>
            <I18n params={{ phone_number: allParams.maskedphonenumber}}>device_verification.prompt.sub_title</I18n>
            <InlinedText><FadeTransition msTime={500}>{renderSendEmailLink()}</FadeTransition></InlinedText>
        </SubTitle>
        <SubmitButton
            disabled={isSendingMail}
            automationName={'submit-button'}
            onSuccess={onSubmit}
            trackingCategory={trackingCategory}
            trackingComponentName={'sms prompt'}
        >
            <I18n>device_verification.prompt.button_text</I18n>
        </SubmitButton>
        <Phrase><I18n>device_verification.general.help_text</I18n> <Link href={translate(asI18nKey('device_verification.general.help_link'))}><I18n>device_verification.general.help_link_text</I18n></Link></Phrase>
    </DeviceVerificationPageContainer>
}

export default PhonePromptPage;
