import { getCookieValue, ICookieOptions, setCookieValue } from '../utilities/Cookies';
import { useEffect, useState } from 'react';
import useTrigger from './UseTrigger';

type CookieValue = string;
type SetCookie = (value?: string, options?: ICookieOptions) => void;

/**
 * @param {string} cookieName - The name of the cookie to use
 * @param {string} defValue - A default value with which to initialize the cookie if it doesn't exist
 * @returns {Array<unknown>} [
 *      {string} The current value of the cookie,
 *      {(value?: string, options?: ICookieOptions) => void} Set the cookie's value (if argument is undefined, set it as defValue)
 * ]
 */
const useCookie = (cookieName: string, defValue?: string): [CookieValue, SetCookie] => {
    const [value, setValue] = useState<string>('');
    const changeTrigger = useTrigger();

    /**
     * @returns {string} The current value of the cookie.
     */
    const extractCookie = (): string => {
        let regex = new RegExp(`^${cookieName}$`);
        return getCookieValue(regex) || defValue || '';
    }

    useEffect(() => {
        setValue(extractCookie());
    }, [cookieName, defValue, changeTrigger.active]);

    /**
     * @param {string | undefined} value - The new value of the cookie (if undefined, set is as defValue)
     * @param {ICookieOptions | undefined} options - Optional cookie sections
     */
    const setCookie = (value?: string, options?: ICookieOptions): void => {
        if (!value && !defValue) return;

        let finalValue = value || defValue || '';
        setCookieValue(cookieName, finalValue, options);
        changeTrigger.fire();
    }

    return [
        value || extractCookie(),
        setCookie
    ];
}

export default useCookie;