import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    marginTop: number;
    marginBottom: number;
    errorDisplayed: boolean;
    width?: number;
}>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width || 70}%;
    min-width: ${props => !!props.width ? `${props.width}%` : '100px'};
    margin: ${props => props.marginTop}px auto ${props => props.marginBottom + (props.errorDisplayed ? 12 : 0)}px;
    transition: .3s;
  
    ${props => props.errorDisplayed && `
        & input:first-child, & textarea:first-child {
            background-color: ${colorsPalettes.red[100]} !important;        
        }
    `}
`;

export const LabelContainer = styled.div`
    display: flex;
    font-size: .85rem;
    margin-bottom: 5px;
    overflow: hidden;
`;

export const Label = styled.p`
    display: inline-block;
    margin: 0;
    color: ${colorsPalettes.carbon[250]};
`;

export const RequiredAsterisk = styled.p`
    display: inline-block;
    margin: 0 5px;
    font-size: .8rem;
  
    &::after {
        content: '*';
        color: ${colorsPalettes.red.s100};
        font-weight: 300;
    }
`;

export const FieldContainer = styled.div`
    position: relative;
`;

export const ErrorContainer = styled.div<{
    displayed: boolean;
    fieldHeight: number;
}>`
    position: absolute;
    top: ${props => props.displayed ? props.fieldHeight + 3 : props.fieldHeight / 2}px;
    opacity: ${props => props.displayed ? 1 : 0};
    pointer-events: ${props => props.displayed ? 'auto' : 'none'} !important;
    transition: .3s;
  
    & * {
        pointer-events: ${props => props.displayed ? 'auto' : 'none'} !important;
    }
`;

export const ErrorLabel = styled.p`
    margin: 5px 0;
    text-align: left;
    font-size: .75rem;
    color: ${colorsPalettes.red.s100};
`;

export const ChildContainer = styled.div`
    position: relative;
`;