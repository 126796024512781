import { FunctionComponent, useContext } from 'react';
import I18nContext from '../../common/context/I18nContext';
import { asI18nKey, I18nKey } from '../../common/utilities/Localization';

interface II18n {
    params?: Record<string, string | number | boolean>;
}

const I18n: FunctionComponent<II18n> = props => {
    const { translate } = useContext(I18nContext);

    /**
     * Extract this element's inner HTML and parse it into an i18n key.
     *
     * @returns {string} A ready to use i18n key.
     */
    const buildKey = (): I18nKey => {
        if (!props.children) return asI18nKey('');

        let key = Object.values(props.children).reduce((prev, curr) => {
            let currElement = (typeof curr === 'string') ? curr : '';
            return prev + currElement;
        }, '');

        return asI18nKey(`${key.replace(/[({ ['"\]})]/g, '')}`);
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: translate(buildKey(), props.params)
            }}
        />
    );
}

export default I18n;