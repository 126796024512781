import { ComponentType, PropsWithChildren } from 'react';

export type AutomationTarget = PropsWithChildren<IAutomationTesting>;
interface IAutomationTesting {
    automationName?: string;
    automationElementAttr?: {
        'data-automation-element': ''
    }
}

const withAutomationTesting = <P extends AutomationTarget>(Component: ComponentType<P>) => {
    return (hocProps: P): JSX.Element => {
        return (
            <Component
                { ...hocProps }
                automationElementAttr={{ 'data-automation-name': hocProps.automationName || '' }}
            />
        );
    }
}

export default withAutomationTesting;
