import { useEffect } from 'react';
import useIdentityNavigation from './UseIdentityNavigation';
import { homepage, IdentityPage } from '../config/PagesConfig';
import { getCurrentPage } from '../utilities/Navigation';

type Guard = (overrideOptions?: IPageAccessGuardOptions) => void;
interface IPageAccessGuardOptions {
    callback?: () => void;
    fallbackTarget?: keyof typeof IdentityPage | string | null;
    useManually?: boolean;
}

/**
 * Automatically navigate a user back to a fallback page
 * if he isn't authorized to visit the current page.
 *
 * @param {boolean} isUnauthorized - True if the user is NOT authorized to visit the current page
 * @param {IPageAccessGuardOptions | undefined} options - Additional guard options
 */
const usePageAccessGuard = (isUnauthorized: boolean, options?: IPageAccessGuardOptions): Guard => {
    const { navigate } = useIdentityNavigation();

    useEffect(() => {
        if (isUnauthorized) {
            warn();
            options?.callback?.();
            if (options?.fallbackTarget !== null) navigate(options?.fallbackTarget);
        }
        if (isUnauthorized && !options?.useManually) guard();
    }, [isUnauthorized]);

    /**
     * Write a warning to the console.
     */
    const warn = (): void => {
        let pageName = getCurrentPage();
        let fallbackPage = options?.fallbackTarget || homepage;

        if (pageName !== fallbackPage)
            console.log(`You are not authorized to access the "${pageName}" page. Redirecting to "${fallbackPage}" page.`);
    }

    /**
     * Activate the relevant sanctions on the users.
     *
     * @param {IPageAccessGuardOptions} overrideOptions - Additional guard options
     */
    const guard = (overrideOptions?: IPageAccessGuardOptions): void => {
        let currentOptions = {
            ...(options ? options : {}),
            ...(overrideOptions ? overrideOptions : {})
        };

        warn();
        currentOptions.callback?.();
        if (currentOptions.fallbackTarget !== null) navigate(currentOptions.fallbackTarget);
    }

    return guard;
}

export default usePageAccessGuard;
