import countries from '../utilities/Countries';
import { fallbackLocale } from './LocalizationConfig';

interface ICountryLanguage {
    countryISO3316: string;
    langISO3316: string;
    langI18nCode: string;
}

export interface ILanguage {
    name: string;
    cookieCode: string;
    base64Flag: string;
}

const availableLanguages: Array<ICountryLanguage> = [
    {
        countryISO3316: 'US',
        langISO3316: 'EN',
        langI18nCode: 'en-us'
    },
    {
        countryISO3316: 'FR',
        langISO3316: 'FR',
        langI18nCode: 'fr-fr'
    },
    {
        countryISO3316: 'CN',
        langISO3316: 'ZH',
        langI18nCode: 'zh-cn'
    },
    {
        countryISO3316: 'TW',
        langISO3316: 'TW',
        langI18nCode: 'zh-tw'
    },
    {
        countryISO3316: 'PT',
        langISO3316: 'PT',
        langI18nCode: 'pt-br'
    },
    {
        countryISO3316: 'JP',
        langISO3316: 'JA',
        langI18nCode: 'ja-jp'
    },
    {
        countryISO3316: 'DE',
        langISO3316: 'DE',
        langI18nCode: 'de-de'
    },
    {
        countryISO3316: 'IT',
        langISO3316: 'IT',
        langI18nCode: 'it-it'
    },
    {
        countryISO3316: 'TR',
        langISO3316: 'TR',
        langI18nCode: 'tr-tr'
    },
    {
        countryISO3316: 'ES',
        langISO3316: 'ES',
        langI18nCode: 'es-es'
    }
];

const flaggedLanguages: Array<ILanguage> = availableLanguages
    .map(x => ({
        ...x,
        ...countries.find(x1 => x1.ISO3316Code === x.countryISO3316)
    }))
    .sort((a, b) => {
        let aArea = a.area ?? 0;
        let bArea = b.area ?? 0;
        return aArea - bArea;
    })
    .map(x => ({
        name: x.langISO3316,
        cookieCode: x.langI18nCode,
        base64Flag: x.flag ?? ''
    }));

export const defaultLanguage = flaggedLanguages.find(x => x.name === fallbackLocale.split('-')[0].toUpperCase())!;

export default flaggedLanguages;