import { FunctionComponent } from 'react';
import TextField from '../../components/Fields/TextField/TextField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import { IPage } from '../../components/PageWrapper/types';
import { sendEmail } from './ForgotPasswordService';
import useIdentityNavigation from '../../common/hooks/UseIdentityNavigation';
import { asI18nKey } from '../../common/utilities/Localization';
import useAPI from '../../common/hooks/UseAPI';
import { AlertType } from '../../components/AlertToast/types';

const ForgotPasswordPage: FunctionComponent<IPage> = props => {
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const { navigate } = useIdentityNavigation();
    const api = useAPI();

    /**
     * Activate when the page's form is submitted.
     * This function sends the user an email with instructions to reset his password.
     *
     * @param {string} email - The user's email address
     * @returns {Promise<boolean>} True if the submission is successful.
     */
    const onSubmit = async ({ email }: Record<string, string>): Promise<boolean> => {
        return await api(() => sendEmail(email), {
            onSuccess: ({ data }) => navigate('ForgotPasswordConfirmation', undefined, data),
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            }
        });
    }

    return (
        <>
            <TextField
                name={'email'}
                type={'Email'}
                label={props.translate(asI18nKey('forgot_password.field.email.label'))}
                required
                focusOnPageLoad
                verticalMargin={{ top: 70, bottom: 30 }}
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={props.trackingCategory}
                onChange={setInput}
                onEnterPress={submissionTrigger.fire}
            />
            <SubmitButton
                onSuccess={onSubmit}
                formInputs={formInputs}
                onFailedVerification={inputsVerificationTrigger.fire}
                trackingCategory={props.trackingCategory}
                trackingComponentName={'send email'}
                shouldSubmit={submissionTrigger.active}
            >
                {props.translate(asI18nKey('forgot_password.button'))}
            </SubmitButton>
            <LinkParagraph
                prefix={props.translate(asI18nKey('forgot_password.login'))}
                linkLabel={props.translate(asI18nKey('forgot_password.login.action'))}
                targetPage={'Login'}
            />
        </>
    );
}

export default ForgotPasswordPage;
