import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type ParamsDict = Record<string, string | boolean | number>;

/**
 * @param {string | undefined} url - The URL from which to extract the query parameters.
 *                                   If undefined, the current URL is taken as default.
 * @param skipEmptyString
 * @returns {ParamsDict} An object containing all query parameters from the given URL.
 */
const useQueryParams = (url?: string, skipEmptyString: boolean = false): ParamsDict => {
    const location = useLocation();
    const queryString = (!!url ? url.substring?.(url.indexOf('?')) : location.search) || '';
    return useMemo<ParamsDict>(() => {
        const resObj: ParamsDict = {};
        if (!queryString)
            return resObj;

        // Separate out each key/value pair
        const parts = queryString.substring(1).split('&');

        for (let i = 0; i < parts.length; i++) {
            const p = parts[i];
            // Split Key/Value pair
            const keyValuePair = p.split('=');

            // Add Key/Value pair to Dictionary object
            const key = keyValuePair[0];
            const lowCaseKey = key?.toString()?.toLowerCase();
            if (!lowCaseKey)
                continue;

            let value = keyValuePair[1];
            if (skipEmptyString && !value)
                continue;

            // Decode URI encoded string
            value = decodeURIComponent(value);

            let typedValue: string | boolean | number = value;
            if (value.toLowerCase() === 'true' || value.toLowerCase() === 'false') {
                typedValue = value.toLowerCase() === 'true';
            }
            else if (!isNaN(Number(value))) {
                typedValue = Number(value);
            }

            resObj[lowCaseKey] = typedValue;
        }

        return resObj;
    }, [queryString]);
}

export default useQueryParams;
