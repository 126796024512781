import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const ResendCooldownInfo = styled.div`
    align-items: center;
    margin: 10px auto;
    font-size: .825rem;
    font-weight: 400;
    color: ${colorsPalettes.carbon[400]};
  
    & > p {
        margin: 0;
    }
`;

export const ResendCooldownTimer = styled.span`
    color: ${colorsPalettes.sky[500]};
`;