import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../../common/config/ThemeConfig';

export const InputField = styled.input<{
    filled: boolean;
    canHide: boolean;
    disabled: boolean;
    height?: number;
}>`
    width: calc(100% - (${props => props.canHide ? 40 : 15}px + 15px));
    height: ${props => props.height || 35}px;
    border: .2px ${colorsPalettes.navigation['NAV_BACKGROUND']} solid;
    border-radius: 4px;
    font-size: .875rem;
    padding-left: 15px;
    padding-right: ${props => props.canHide ? 40 : 15}px;
    background-color: ${props => props.filled ? colorsPalettes.sky[100] : colorsPalettes.carbon[0]};
    outline: none;
    transition: .2s;
  
    ${props => props.disabled && `
        color: ${colorsPalettes.carbon[250]};
        background-color: ${colorsPalettes.navigation['NAV_BACKGROUND']};
        cursor: not-allowed; 
    `}

    &:hover {
        border-color: ${colorsPalettes.carbon[100]};
    }

    &:focus {
        border-color: ${colorsPalettes.sky[500]};
    }
  
    &::placeholder {
        color: ${colorsPalettes.carbon[200]};
    }

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        height: calc(${props => props.height || 35}px * 1.5);
    }
`;

export const HideIcon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
  
    & > img {
        position: absolute;
        width: 17px;
        right: 10px;
        top: 40%;
        transform: translateY(-20%);
    }
`;