import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div`
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${colorsPalettes.sky[100]}60;
    cursor: pointer;
    text-decoration: none;
    transition: .3s;

    &:hover {
        background-color: ${colorsPalettes.navigation['ACTIVE_TILE_HOVER']}60;
    }

    & span {
        font-size: 1rem;
        font-weight: 300;
    }
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const SimilarwebIcon = styled.img`
    width: 16px;
    margin-right: 20px;
`;

export const Label = styled.span`
    color: ${colorsPalettes.carbon[400]};
`;

export const ActionLabel = styled.span`
    padding-left: 5px;
    color: ${colorsPalettes.sky[500]};
`;
