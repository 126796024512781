import fetchService from '../../common/utilities/API/FetchService';
import { IServerResponse } from '../../common/utilities/API/types';
import { ISendEmailResponse } from './types';

/**
 * Send the user an email with instructions to reset his password.
 *
 * @param {string} email - The user's email
 * @returns {Promise<IServerResponse<ISendEmailResponse>>} The server's response.
 */
export const sendEmail = async (email: string): Promise<IServerResponse<ISendEmailResponse>> =>
    fetchService.post<ISendEmailResponse>('/account/password/forgot', { email });
