import { FunctionComponent } from 'react';
import TextField from '../../components/Fields/TextField/TextField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import LinkParagraph from '../../components/LinkParagraph/LinkParagraph';
import useIdentityForm from '../../common/hooks/UseIdentityForm';
import { IPage } from '../../components/PageWrapper/types';
import { login } from './SsoService';
import { asI18nKey } from '../../common/utilities/Localization';
import { getBrowserId } from '../../common/utilities/Identification';
import useAPI from '../../common/hooks/UseAPI';
import { ServerError } from '../../common/utilities/API/types';
import { AlertType } from '../../components/AlertToast/types';

const SsoPage: FunctionComponent<IPage> = props => {
    const { setInput, formInputs, inputsVerificationTrigger, submissionTrigger, logSubmissionFailure } = useIdentityForm();
    const api = useAPI();

    /**
     * Activate when the page's form is submitted.
     * This function checks if the given email is related to an SSO account
     * and continues with the login flow if it does.
     *
     * @param {string} email - The user's email
     * @returns {Promise<boolean>} True if the submission is successful.
     */
    const onSubmit = async ({ email }: Record<string, string>): Promise<boolean> => {
        let browserId = await getBrowserId();

        return await api(() => login(browserId, email, window.location.hash?.substring(1)), {
            errorsMap: () => [
                {
                    error: ServerError.IncorrectEmail,
                    message: props.translate(asI18nKey('login_sso.error.invalid_email'))
                },
                {
                    error: ServerError.SsoLoginNotAllowed,
                    message: props.translate(asI18nKey('login_sso.error.sso_not_allowed'))
                },
                {
                    error: ServerError.LoginNotAllowed,
                    message: props.translate(asI18nKey('login_sso.error.login_not_allowed')),
                    alertType: AlertType.Warning
                }
            ],
            onError: ({ message, forceRefresh, mappedHandler }) => {
                if (!!message && mappedHandler) {
                    let alertType = mappedHandler?.alertType || AlertType.Error;
                    props.onServerAlert(message, alertType);
                    logSubmissionFailure(message);
                }
                else props.onUnexpectedError(forceRefresh);
            }
        });
    }

    return (
        <>
            <TextField
                name={'email'}
                automationName={'email'}
                type={'Email'}
                label={props.translate(asI18nKey('login_sso.field.email.label'))}
                required
                focusOnPageLoad
                verticalMargin={{ top: 70, bottom: 70 }}
                shouldVerify={inputsVerificationTrigger.active}
                trackingCategory={props.trackingCategory}
                onChange={setInput}
                onEnterPress={submissionTrigger.fire}
            />
            <SubmitButton
                automationName={'submit-button'}
                onSuccess={onSubmit}
                formInputs={formInputs}
                onFailedVerification={inputsVerificationTrigger.fire}
                trackingCategory={props.trackingCategory}
                trackingComponentName={'sso log in'}
                shouldSubmit={submissionTrigger.active}
            >
                {props.translate(asI18nKey('login_sso.button'))}
            </SubmitButton>
            <LinkParagraph
                linkLabel={props.translate(asI18nKey('login_sso.credential_login.action'))}
                targetPage={'Login'}
            />
        </>
    );
}

export default SsoPage;
