import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../../common/config/ThemeConfig';

export const Container = styled.button<{
    isLoading: boolean;
}>`
    --icon-width: 40px;
  
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 190px;
    height: 40px;
    margin: 0 7px;
    padding: 0;
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: ${colorsPalettes.sky[500]};
    cursor: pointer;
    transition: .3s;

    &:disabled {
        cursor: progress;
    }
  
    ${props => !props.isLoading && `
        &:hover:not(:active) {
            background-color: ${colorsPalettes.blue[500]};
        }
    `}

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        width: calc(var(--icon-width) + 4px);
    }
`;

export const Loader = styled.div<{
    isLoading: boolean;
}>`
    visibility: hidden;
    position: absolute;
    width: calc(100% - var(--icon-width));
    height: 100%;
    top: 0;
    right: 0;
    opacity: 1;
    border-radius: 3px;
    background-color: ${colorsPalettes.sky[400]};

    @keyframes button-animation {
        0% {
            opacity: 0;
            clip-path: inset(0 0 0 0);
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            clip-path: inset(0 0 0 100%);
        }
    }

    ${props => props.isLoading && `
        visibility: visible;
        animation-name: button-animation;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    `}
`;

export const IconContainer = styled.div`
    display: flex;
    margin-left: 2px;
    width: var(--icon-width);
    height: calc(100% - 4px);
    border-radius: 3px;
    background-color: ${colorsPalettes.carbon[0]};
    z-index: 1;
`;

export const Icon = styled.img`
    margin: auto;
`;

export const LabelContainer = styled.div`
    margin: auto;
    z-index: 1;

    @media screen and (max-width: ${mobileMaxScreenWidth}px) {
        display: none;
    }
`;

export const Label = styled.p`
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: .85rem;
    font-weight: 500;
    color: ${colorsPalettes.carbon[0]};
`;