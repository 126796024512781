import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';
import { mobileMaxScreenWidth } from '../../common/config/ThemeConfig';

export const Title = styled.p`
    margin: 0 0 15px -2px;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.12;
`;

export const Subtitle = styled.p`
    width: 90%;
    margin-top: -10px;
    text-align: left;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    color: ${colorsPalettes.carbon[400]};
`;

export const Instructions = styled.p`
    text-align: left;
    font-weight: 400;
    font-size: .875rem;
    margin-top: 30px;
    color: ${colorsPalettes.carbon[400]};

    @media screen and (max-width: ${mobileMaxScreenWidth / 2}px) {
        margin-bottom: 20px;
    }
`;

export const FieldsRow = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;

    @media screen and (max-width: ${mobileMaxScreenWidth / 2}px) {
        flex-direction: column;
      
        & > * {
            margin-top: 0 !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            width: 100% !important;
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 12px;
  
    & > * {
        margin: 0 10px 0 0;
    }
  
    & button {
        margin: 10px 10px auto auto;  
    }

    @media screen and (max-width: ${mobileMaxScreenWidth / 2}px) {
        flex-direction: column;

        & > * {
            margin-top: 10px;
        }

        & button {
            margin: 20px 0 0;
        }
    }
`;