import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Container = styled.div<{
    disabled: boolean
}>`
    align-items: center;
    justify-content: center;
    text-align: left;
    min-height: 70px;
    margin: 10px auto;
    border: 1px solid ${colorsPalettes.carbon[50]};
    border-radius: 8px;
    background-color: ${props => (props.disabled ? colorsPalettes.carbon[25] : "#fffff00")};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: box-shadow 0.25s;

    &:hover {
        box-shadow: ${props => props.disabled ? 'none' : `0px 3px 6px ${colorsPalettes.midnight[600]}14`};
    }
`;

export const TextContainer = styled.div`
    margin: auto;
    width: 90%;
    height: 90%;
`;

export const Title = styled.h1<{disabled: boolean}>`
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  color: ${props => (props.disabled ? colorsPalettes.carbon[300] : colorsPalettes.carbon[500])};
`;

export const Subtitle = styled.span`
  font-size: 12px;
  line-height: 10px !important;
  color: ${colorsPalettes.carbon[300]};
`;

export const SubtitleContainer = styled.h2`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

export const SettingsLinkContainer = styled.div`
    & > * {
        justify-content: left;
        margin: 3px 0;
    }
`;