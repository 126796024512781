import {FunctionComponent} from "react";
import {AutomationTarget} from "../../common/HOC/WithAutomationTesting";
import RegistrationFooter from "./RegistrationFooter/RegistrationFooter";
import TipFooter from "./TipFooter/TipFooter";

export type Footers = 'Registration' | 'TipFooter';

export const footers: Record<Footers, FunctionComponent<AutomationTarget>> = {
    'Registration': RegistrationFooter,
    'TipFooter': TipFooter,
}
