import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Instructions = styled.p`
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 400;
    padding: 0 5%;
    color: ${colorsPalettes.carbon[400]};
`;

export const DeviceAddress = styled.span`
    font-weight: 600;
`;

export const OtherOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
        margin: 0;
    }
`;

export const DeviceVerificationPageContainer = styled.div`
    display: grid;
    row-gap: 0px;
`

export const SubTitle = styled.div`
    font-family: 'Roboto';
    row-gap: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #2A3E52CC;
`

export const Phrase = styled.p`
    margin-block: 0px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #2A3E52CC;
    white-space: pre-line;
`

export const Link = styled.a`
    cursor: pointer;
    font-size: 14px;
    color: ${colorsPalettes.blue[400]};
`

export const InlinedText = styled.div`
    display: inline-block;
`

export const MailButtonContainer = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 40px;
`

export const MailButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${colorsPalettes.carbon[300]};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
`

export const UpdatePhoneFieldContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-top: 50px;
    margin-bottom: 70px;
    div {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`

export const MailButton = styled.a`
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: ${colorsPalettes.carbon[0]};
    border: 1px solid #0925401A;
    border-radius: 4px;
    padding: 4px 8px;
`
