import useUtmParams, { UtmParameters } from './UseUtmParams';
import { composeQueryString, constructUrl, deconstructUrl } from '../../utilities/URL';

interface IStickyQueryParameters extends UtmParameters {}

interface IStickyQuery {
    stickyParameters: IStickyQueryParameters;
    appendStickyParameters: (url: string) => string;
}

/**
 * This hook handles all parameters that exist in the current URL
 * and should be copied forward to the next page, upon navigation.
 *
 * @returns {IStickyQuery} {
 *      {IStickyQueryParameters} An object that consists of all sticky parameters;
 *      {(url: string) => string} Append all existing sticky parameters to a given URL;
 */
const useStickyQuery = (): IStickyQuery => {
    const utmParams = useUtmParams();
    const stickyParameters = { ...utmParams };

    /**
     * Append all existing sticky parameters to a given URL.
     *
     * @param {string} url - The URL into which to append the sticky parameters
     * @returns {string} A complete URL that contains the currently existing sticky parameters.
     */
    const appendStickyParameters = (url: string): string => {
        let urlData = deconstructUrl(url);
        let hasValidQuery = urlData.isValid && !!urlData.query;
        let query = composeQueryString(stickyParameters, hasValidQuery ? new URLSearchParams(urlData.query) : undefined);

        return constructUrl({ ...urlData, query });
    }

    return {
        stickyParameters,
        appendStickyParameters
    };
}

export default useStickyQuery;