import { FunctionComponent, useRef, useState } from 'react';
import { IFormField } from '../types';
import { InputArea } from './TextArea.style';
import FormField from '../FormField';

const TextArea: FunctionComponent<IFormField> = props => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState<string>(props.initialValue ?? '');

    return (
        <FormField
            { ...props }
            input={value}
            size={{ width: props.size?.width, height: props.size?.height || 100 }}
            inputRef={inputRef}
        >
            <InputArea
                id={`input-${props.name}`}
                ref={inputRef}
                placeholder={props.placeholder}
                value={value}
                disabled={!!props.readonly}
                filled={!!value}
                height={props.size?.height || 100}
                onChange={ev => setValue(ev.target.value)}
                { ...props.automationElementAttr }
            />
        </FormField>
    );
}

export default TextArea;