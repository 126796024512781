import { IAlert } from '../AlertToast/types';
import { FunctionComponent } from 'react';
import IdentityPageTemplate from './IdentityPageTemplate/IdentityPageTemplate';
import CardPageTemplate from './CardPageTemplate/CardPageTemplate';
import {Footers} from "../Footers/types";

export interface IPageTemplate {
    title: string;
    showFooter: boolean;
    headerImg?: string;
    footer: Footers;
    headerImageHeight?: string;
    pageDisabled?: boolean;
    serverAlert?: IAlert;
    maxContentWidth?: number;
}

export enum PageTemplate {
    Identity,
    Card
}

export const pageTemplates: Record<keyof typeof PageTemplate, FunctionComponent<IPageTemplate>> = {
    'Identity': IdentityPageTemplate,
    'Card': CardPageTemplate
}
